import React from 'react'
import '../Styles/detalleVista.css'

function DetalleVista({ detalles }) {

    let obtenerUnidad = (value) => {
        let unidades = [
            { unidad: '1000 kWh', valor: 6 },
            { unidad: 'anulación/devolución', valor: 95 },
            { unidad: 'centímetros', valor: 20 },
            { unidad: 'cm cúbicos', valor: 27 },
            { unidad: 'curie', valor: 48 },
            { unidad: 'dam cúbicos', valor: 30 },
            { unidad: 'docenas', valor: 9 },
            { unidad: 'gramo activo', valor: 67 },
            { unidad: 'gramo base', valor: 68 },
            { unidad: 'gramos', valor: 14 },
            { unidad: 'gruesa', valor: 54 },
            { unidad: 'hectolitros', valor: 18 },
            { unidad: 'hm cúbicos', valor: 31 },
            { unidad: 'jgo. pqt. mazo naipes', valor: 25 },
            { unidad: 'kg activo', valor: 66 },
            { unidad: 'kg base', valor: 53 },
            { unidad: ' kg bruto', valor: 61 },
            { unidad: 'kilogramos', valor: 1 },
            { unidad: 'kilómetros', valor: 17 },
            { unidad: 'km cúbicos', valor: 32 },
            { unidad: 'litros', valor: 5 },
            { unidad: 'metros', valor: 2 },
            { unidad: 'metros cuadrados', valor: 3 },
            { unidad: 'metros cúbicos', valor: 4 },
            { unidad: 'microcurie', valor: 50 },
            { unidad: 'microgramos', valor: 33 },
            { unidad: 'milicurie', valor: 49 },
            { unidad: 'miligramos', valor: 41 },
            { unidad: 'mililitros', valor: 47 },
            { unidad: 'milimetros', valor: 15 },
            { unidad: 'millares', valor: 11 },
            { unidad: 'mm cúbicos', valor: 16 },
            { unidad: 'muiactant', valor: 63 },
            { unidad: 'muiacthor', valor: 52 },
            { unidad: 'muiactig', valor: 65 },
            { unidad: 'nanogramos', valor: 34 },
            { unidad: 'otras unidades', valor: 98 },
            { unidad: 'packs', valor: 96 },
            { unidad: 'pares', valor: 8 },
            { unidad: 'picogramos', valor: 35 },
            { unidad: 'quilates', valor: 10 },
            { unidad: 'seña/anticipo', valor: 97 },
            { unidad: 'toneladas', valor: 29 },
            { unidad: 'uiactant', valor: 62 },
            { unidad: 'uiacthor', valor: 51 },
            { unidad: 'uiactig', valor: 64 },
            { unidad: ' unidades', valor: 7 }
        ]
        const condicion = unidades.find(U => U.valor == value);
        return condicion ? condicion.unidad : 'Unidad no seleccionada';
    }

    let calcularPorcentaje = (numero, porcentaje) => {
        return +numero * (+porcentaje / 100);
    }
    let calcularSubtotal = (numero, porcentaje) => {
        const valorPorcentaje = (+numero * +porcentaje) / 100;
        const resultado = +numero + valorPorcentaje;
        return resultado;
    }

    return (
        <div className='Detalle-Vista'>
            <section>
                <table role="table" className='table-block'>
                    <thead>
                        <tr role="row">
                            <th role="columnheader">Cantidad</th>
                            <th role="columnheader">Producto / Servicio</th>
                            <th role="columnheader" className='Precio-unit'>Precio unit</th>
                            <th role="columnheader">%Bonif</th>
                            <th role="columnheader">$Bonif</th>
                            <th role="columnheader">Subtotal</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr role="row">
                            <td>{detalles.unidad_bulto} {obtenerUnidad(detalles.unidad_medida)}</td>
                            <td>{detalles.descripcion} - {detalles.leyenda}</td>
                            <td>${detalles.precio_unitario_sin_iva}</td>
                            <td>{detalles.bonificacion_porcentaje}%</td>
                            <td>${calcularPorcentaje(detalles.precio_unitario_sin_iva, detalles.bonificacion_porcentaje)}</td>
                            <td>${calcularSubtotal(detalles.precio_unitario_sin_iva, detalles.alicuota)}</td>
                        </tr>
                    </tbody>
                </table>
                <h3>Informacion del Cliente o Leyenda</h3>
            </section>
        </div>
    )
}

export default DetalleVista