import React from 'react'
import loading from '../../assets/Spin-1s-200px.gif'

function Loading({texto = "Cargando ..."}) {
    return (
        <main className='CuentaCorriente'>
          <h2>{texto}</h2>
          <img src={loading} alt="loading gif..." />
        </main>
    )
}

export default Loading