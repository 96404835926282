import React, { useEffect, useState } from 'react'
import './estadistica.css'
import { fechaMes, parsearFecha } from '../../../utils/funciones'
import consulta from '../../../utils/consulta'
import ExcelButton from '../../../components/partials/Excel/ExcelButton'
import Periodo from '../../../components/periodo/Periodo'

function Estadisticas() {

    const [loading, setLoading] = useState(false)
    const [periodo, setPeriodo] = useState(fechaMes())
    const [datosPagos, setDatosPagos] = useState({
        totalPagosPeriodo: 0,
        sumaTotalPagos: 0,
        sumaPagosPeriodo: 0,
        sumaPagosOtrosPeriodos: 0,
        totalSaldosPeriodo: 0,
        totalSaldosSinPagar: 0,
        saldosSinPagar: 0
    })
    const [pagos, setPagos] = useState([])
    const [reporteFacturas, setReporteFacturas] = useState({
        saldos: 0,
        facturas: 0,
        montoTotal: 0,
        montoSinFacturar: 0,
        montoFacturado: 0,
    })
    const [facturas, setFacturas] = useState([])

    const consultarEstadisticasPagos = async () => {
        try {
            setLoading(true)
            const urlEstadisticas = `/informacion/api/pagos/periodo?periodo=${periodo}`
            const response = await consulta.get(urlEstadisticas)
            setDatosPagos(response.meta)
            setPagos(response.data)
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }
    const consultarEstadisticasFacturas = async () => {
        try {
            setLoading(true)
            const urlEstadisticas = `/reportes/saldos/pagados/factura?periodo=${periodo}`
            const response = await consulta.post(urlEstadisticas)
            setReporteFacturas(response.meta)
            setFacturas(response.data)
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        consultarEstadisticasPagos()
        consultarEstadisticasFacturas()
    }, [periodo])

    return (
        <section className='DatosEstadisticos'>
            <h2>Periodo {parsearFecha(periodo)}</h2>
            <article className='pagos-periodo'>
                <ul className='titulos pagos-periodo-titulos'>
                    <li>
                        <span className='title'>Cantidad Pagos:</span>
                        <span className='text-number'>{datosPagos.totalPagosPeriodo}</span>
                    </li>
                    <li>
                        <span className='title'>Suma Total Pagos:</span>
                        <span className='text-number'>{datosPagos.sumaTotalPagos}</span>
                    </li>
                    <li>
                        <span className='title'>Pagos Totales periodo:</span>
                        <span className='text-number'>{datosPagos.sumaPagosPeriodo}</span>
                    </li>
                    <li>
                        <span className='title'>Pagos periodos anteriores:</span>
                        <span className='text-number'>{datosPagos.sumaPagosOtrosPeriodos}</span>
                    </li>
                    <li>
                        <span className='title'>Total Saldos Periodo:</span>
                        <span className='text-number'>{datosPagos.totalSaldosPeriodo}</span>
                    </li>
                    <li>
                        <span className='title'>Saldos sin Pagar:</span>
                        <span className='text-number'>{datosPagos.totalSaldosSinPagar}</span>
                    </li>
                    <li>
                        <span className='title'>Monto Saldos sin Pagar:</span>
                        <span className='text-number'>{datosPagos.saldosSinPagar}</span>
                    </li>
                    <li>
                        <ExcelButton data={pagos} nombreHoja={`Pagos ${periodo}`} nombreArchivo={`Pagos ${parsearFecha(periodo)}`} />
                    </li>
                </ul>
                <div className='titulos seleccion-periodo'>
                    <form className='selector-periodo'>
                        <Periodo  periodo={periodo} setPeriodo={setPeriodo} />
                    </form>
                </div>
                <ul className='titulos facturacion-periodo'>
                    <li>
                        <span className='title'>Saldos pagados mayores a 10:</span>
                        <span className='text-number'>{reporteFacturas.saldos}</span>
                    </li>
                    <li>
                        <span className='title'>Total Facturas:</span>
                        <span className='text-number'>{reporteFacturas.facturas}</span>
                    </li>
                    <li>
                        <span className='title'>Total de saldos:</span>
                        <span className='text-number'>{reporteFacturas.montoTotal}</span>
                    </li>
                    <li>
                        <span className='title'>Total sin Facturar:</span>
                        <span className='text-number'>{reporteFacturas.montoSinFacturar}</span>
                    </li>
                    <li>
                        <span className='title'>Total Facturado:</span>
                        <span className='text-number'>{reporteFacturas.montoFacturado}</span>
                    </li>
                    <li>
                        <ExcelButton data={pagos} nombreHoja={`Pagos ${periodo}`} nombreArchivo={`Pagos ${parsearFecha(periodo)}`} />
                    </li>
                </ul>
            </article>
        </section>
    )
}

export default Estadisticas