import React, { useState } from 'react'
import Swal from 'sweetalert2';
import axios from 'axios';


function Monotributos({datos,setDatos,open,setOpen}) {

    const [editar, setEditar] = useState(false)
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        let urlMonotributosEditar = 'https://cotizador.createch.com.ar/actualizacion/monotributos'
        await Swal.fire({
            title: '¿Esta seguro de editar estos datos?',
            showDenyButton: true,
            confirmButtonText: 'Editar',
            denyButtonText: `Cancelar`,
          }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              let response = await axios.put(urlMonotributosEditar, datos)
              if (response.status === 200) {
                Swal.fire('Se edito la lista utilizable', '', 'success')
              } else {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: "Error al editar" + response.status,
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            } else if (result.isDenied) {
              Swal.fire('No se edito el elemento', '', 'info')
            }
          })
    }
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        
        const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
        const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
        
        return `${formattedDate} a las ${formattedTime}`;
    }
    const handleEdit = (e) => {
        e.preventDefault()
        if (editar) {
            setEditar(false)
        }else{
            setEditar(true)
        }
    }
    const handleClick = (e) => {
        e.preventDefault()
        if (open) {
            setOpen(false)
        }else{
            setOpen(true)
        }
    }
    let handleChange = (evt) => {
        const { target } = evt;
        const { name, value } = target;

        if ((isNaN(value) || value < 0)) {
            return
        }

        const newValues = {
            ...datos,
            [name]: value,
        };
        setDatos(newValues);
    }
    
  return (
    <div className='Background-Change'>
        <form onSubmit={handleSubmit}>
            <h4 onClick={handleClick}><i className="fas fa-times"></i></h4>
            <h3>Editar Monotributos</h3>
            <ul>  
                <li>
                    <label htmlFor="categoriaA">Categoria A,B y C</label>
                    {editar ? 
                    <input type="text" name="categoriaA" id="categoriaA" onChange={handleChange} value={datos.categoriaA}/>
                    :
                    <h5>${datos.categoriaA}</h5>}
                </li>
                <li>
                    <label htmlFor="categoriaD">Categoria D</label>
                    {editar ? 
                    <input type="text" name="categoriaD" id="categoriaD" onChange={handleChange} value={datos.categoriaD}/>
                    :
                    <h5>${datos.categoriaD}</h5>}
                </li>
                <li>
                    <label htmlFor="categoriaE">Categoria E</label>
                    {editar ? 
                    <input type="text" name="categoriaE" id="categoriaE" onChange={handleChange} value={datos.categoriaE}/>
                    :
                    <h5>${datos.categoriaE}</h5>}
                </li>
                <li>
                    <label htmlFor="categoriaF">Categoria F</label>
                    {editar ? 
                    <input type="text" name="categoriaF" id="categoriaF" onChange={handleChange} value={datos.categoriaF}/>
                    :
                    <h5>${datos.categoriaF}</h5>}
                </li>
                <li>
                    <label htmlFor="categoriaG">Categoria G</label>
                    {editar ? 
                    <input type="text" name="categoriaG" id="categoriaG" onChange={handleChange} value={datos.categoriaG}/>
                    :
                    <h5>${datos.categoriaG}</h5>}
                </li>
                <li>
                    <label htmlFor="categoriaH">Categoria H</label>
                    {editar ? 
                    <input type="text" name="categoriaH" id="categoriaH" onChange={handleChange} value={datos.categoriaH}/>
                    :
                    <h5>${datos.categoriaH}</h5>}
                </li>
                <li>
                    <label htmlFor="categoriaI">Categoria I</label>
                    {editar ? 
                    <input type="text" name="categoriaI" id="categoriaI" onChange={handleChange} value={datos.categoriaI}/>
                    :
                    <h5>${datos.categoriaI}</h5>}
                </li>
                <li>
                    <label htmlFor="categoriaJ">Categoria J</label>
                    {editar ? 
                    <input type="text" name="categoriaJ" id="categoriaJ" onChange={handleChange} value={datos.categoriaJ}/>
                    :
                    <h5>${datos.categoriaJ}</h5>}
                </li>
                <li>
                    <label htmlFor="categoriaK">Categoria K</label>
                    {editar ? 
                    <input type="text" name="categoriaK" id="categoriaK" onChange={handleChange} value={datos.categoriaK}/>
                    :
                    <h5>${datos.categoriaK}</h5>}
                </li>
            </ul>
            
            {editar ? 
            <div>
                <button onClick={handleEdit}>Cancelar</button>
                <button type="submit">Enviar Formulario</button>
            </div>
            :
            <div>
                <button onClick={handleEdit}>Editar</button>
            </div>}
            <small>Ultima actualizacion {formatDate(datos.ultimaActualizacion)}</small>
        </form>
    </div>
  )
}

export default Monotributos