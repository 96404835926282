import React, { useState } from 'react'
import laFamosaX from '../../../../assets/xmark-solid.svg'
import Swal from 'sweetalert2';
import consulta from '../../../../utils/consulta';
import { fechaMes, parsearFecha } from '../../../../utils/funciones';

function ModalAportes({saldoEditando, cerrarModal}) {

    const [obraError, setObraError] = useState(false);
    const [periodo, setPeriodo] = useState(fechaMes());
    const [formData, setFormData] = useState({
        obra: '',
        cuil: '',
        cuit: '',
        persona: '',
        empresa: '',
        remDecla: 0,
        remContrib: 0,
        totalTransferidos: 0,
        apTransf: 0,
        contrTransf: 0,
        totalDeclarados: 0,
        apDecla: 0,
        contrDecla: 0,
    });

    const cambiarMes = (e) => {
        let mes = e.target.value;
        let nuevoPeriodo = String(periodo)
        let year = nuevoPeriodo.substring(0, 4)
        setPeriodo(`${year}${mes}`)
    }
    const cambiaryear = (e) => {
        console.log(e.target.value);
        let year = e.target.value
        let nuevoPeriodo = String(periodo)
        let mes = nuevoPeriodo.substring(4, 6)
        setPeriodo(`${year}${mes}`)
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'obra') {
            setObraError(value === ''); // Establecer el error si no se selecciona una obra
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.obra === '') {
            setObraError(true);
            return;
        }
        let data = formData
        data.periodo = periodo

        const urlAporteNeto = '/modificaciones/aporte/neto'
        let result = await Swal.fire({
            title: `Esta seguro que desea generar un aporte neto para: ${data.cuil} `,
            showCancelButton: true,
            confirmButtonText: 'Generar',
        })

        if (result.isConfirmed) {
            let response = await consulta.post(urlAporteNeto, data)
            if (response.status === 200) {
                console.log(response);
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `${response.meta.msg}`,
                    showConfirmButton: false,
                    timer: 1500
                })
                Swal.fire(`${response.meta.msg}`, '', 'success')
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Error al generar \n" + response.meta.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
        console.log(data);
    };

  return (
    <div className='ModalAportes'>
            <div className="cerrar">
                <button className='cerrar-modal' onClick={cerrarModal}>
                    <img src={laFamosaX} alt="Cerrar Modal" />
                </button>
            </div>
        <section className='AportesNetos'>
            <h2>Creando Aportes Netos</h2>
            <form onSubmit={handleSubmit} className="construccion-formulario">
                <div className='periodo-seleccionado'>
                    <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                </div>
                <div className="row">
                    <div className="formData">
                        <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                        <select name="year" id="year" className='select-field' onChange={cambiaryear}>
                            <option defaultValue="" hidden>Puede modificar el año</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                        </select>
                    </div>

                    <div className="formData">
                        <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                        <select name="mes" id="mes" className='select-field' onChange={cambiarMes}>
                            <option defaultValue="" hidden>Puede modificar el mes</option>
                            <option value="01">Enero</option>
                            <option value="02">Febrero</option>
                            <option value="03">Marzo</option>
                            <option value="04">Abril</option>
                            <option value="05">Mayo</option>
                            <option value="06">Junio</option>
                            <option value="07">Julio</option>
                            <option value="08">Agosto</option>
                            <option value="09">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                        </select>
                    </div>

                    <div className="formData">
                        <label htmlFor="cuil">CUIL:</label>
                        <input
                            className="input-field"
                            type="text"
                            name="cuil"
                            value={formData.cuil}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="formData">
                        <label htmlFor="persona">Persona:</label>
                        <input
                            className="input-field"
                            type="text"
                            name="persona"
                            value={formData.persona}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formData">
                        <label htmlFor="empresa">Empresa:</label>
                        <input
                            className="input-field"
                            type="text"
                            name="empresa"
                            value={formData.empresa}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formData">
                        <label htmlFor="cuit">CUIT:</label>
                        <input
                            className="input-field"
                            type="text"
                            name="cuit"
                            value={formData.cuit}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="formData">
                        <label htmlFor="obra">Obra:</label>
                        <select className="select-field" name="obra" value={formData.obra} onChange={handleChange}>
                            <option value="" hidden>Seleccionar obra</option>
                            <option value="OSCEARA">OSCEARA</option>
                            <option value="OSCICA">OSCICA</option>
                            <option value="OSPOCE">OSPOCE</option>
                            <option value="OSMEDICA">OSMEDICA</option>
                        </select>
                        {obraError && <small className='formError'>Debe seleccionar una opcion</small>}
                    </div>

                    <div className="formData">
                        <label htmlFor="remDecla">Remuneración Declarada:</label>
                        <input
                            className="input-field"
                            type="number"
                            name="remDecla"
                            value={formData.remDecla}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formData">
                        <label htmlFor="remContrib">Remuneración Contributiva:</label>
                        <input
                            className="input-field"
                            type="number"
                            name="remContrib"
                            value={formData.remContrib}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="formData">
                        <label htmlFor="totalTransferidos">Total Transferidos:</label>
                        <input
                            className="input-field"
                            type="number"
                            name="totalTransferidos"
                            value={formData.totalTransferidos}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formData">
                        <label htmlFor="apTransf">Aportes Transferidos:</label>
                        <input
                            className="input-field"
                            type="number"
                            name="apTransf"
                            value={formData.apTransf}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formData">
                        <label htmlFor="contrTransf">Contribución Transferida:</label>
                        <input
                            className="input-field"
                            type="number"
                            name="contrTransf"
                            value={formData.contrTransf}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="formData">
                        <label htmlFor="totalDeclarados">Total Declarados:</label>
                        <input
                            className="input-field"
                            type="number"
                            name="totalDeclarados"
                            value={formData.totalDeclarados}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formData">
                        <label htmlFor="apDecla">Aportes Declarados:</label>
                        <input
                            className="input-field"
                            type="number"
                            name="apDecla"
                            value={formData.apDecla}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="formData">
                        <label htmlFor="contrDecla">Contribución Declarada:</label>
                        <input
                            className="input-field"
                            type="number"
                            name="contrDecla"
                            value={formData.contrDecla}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <button className="submit-button" type="submit">Enviar</button>
            </form>
        </section>
    </div>
  )
}

export default ModalAportes