import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CargasAportes from './CargaDeAportes/CargaDeAportes'
import Funciones from './Funciones/Funciones'
import InfoAportes from './Informacion/InfoAportes';
import ModificacionAportes from './ModificarAportes/ModificacionAportes';
import CreacionAportes from './CreacionAportes/CreacionAportes';

function Aportes() {

	const state = useSelector(state => state);
	const [rol, setRol] = useState(state.rol)

	useEffect(() => {
		setRol(state.rol)
	}, [state]);

	return (
		<main className='Ingresos'>
			<section>
				<h1>Aportes</h1>
				<Tabs>
					<TabList>
					{(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
							<Tab>Funciones</Tab>
						}
						<Tab>Ver Aportes</Tab>
						{(rol === "Administrador" || rol === "Aportes") &&
							<Tab>Crear Aportes</Tab>
						}
						{(rol === "Administrador" || rol === "Aportes") &&
							<Tab>Modificación Aportes</Tab>
						}
						{(rol === "Administrador" || rol === "Aportes") &&
							<Tab>Cargas de Aportes</Tab>
						}
					</TabList>

					{(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
					<TabPanel>
						<Funciones />
					</TabPanel>}

					<TabPanel>
						<InfoAportes />
					</TabPanel>

					{(rol === "Administrador" || rol === "Aportes") &&
					<TabPanel>
						<CreacionAportes />
					</TabPanel>}

					{(rol === "Administrador" || rol === "Aportes") &&
					<TabPanel>
						<ModificacionAportes />
					</TabPanel>}

					{(rol === "Administrador" || rol === "Aportes") &&
					<TabPanel>
						<CargasAportes />
					</TabPanel>}

				</Tabs>
			</section>
		</main>
	)
}

export default Aportes