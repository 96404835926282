import React from 'react'
import '../Styles/detalle.css'

function Detalle({ detalles, setDetalles }) {

    let unidades = [
        { unidad: '1000 kWh', valor: 6 },
        { unidad: 'anulación/devolución', valor: 95 },
        { unidad: 'centímetros', valor: 20 },
        { unidad: 'cm cúbicos', valor: 27 },
        { unidad: 'curie', valor: 48 },
        { unidad: 'dam cúbicos', valor: 30 },
        { unidad: 'docenas', valor: 9 },
        { unidad: 'gramo activo', valor: 67 },
        { unidad: 'gramo base', valor: 68 },
        { unidad: 'gramos', valor: 14 },
        { unidad: 'gruesa', valor: 54 },
        { unidad: 'hectolitros', valor: 18 },
        { unidad: 'hm cúbicos', valor: 31 },
        { unidad: 'jgo. pqt. mazo naipes', valor: 25 },
        { unidad: 'kg activo', valor: 66 },
        { unidad: 'kg base', valor: 53 },
        { unidad: ' kg bruto', valor: 61 },
        { unidad: 'kilogramos', valor: 1 },
        { unidad: 'kilómetros', valor: 17 },
        { unidad: 'km cúbicos', valor: 32 },
        { unidad: 'litros', valor: 5 },
        { unidad: 'metros', valor: 2 },
        { unidad: 'metros cuadrados', valor: 3 },
        { unidad: 'metros cúbicos', valor: 4 },
        { unidad: 'microcurie', valor: 50 },
        { unidad: 'microgramos', valor: 33 },
        { unidad: 'milicurie', valor: 49 },
        { unidad: 'miligramos', valor: 41 },
        { unidad: 'mililitros', valor: 47 },
        { unidad: 'milimetros', valor: 15 },
        { unidad: 'millares', valor: 11 },
        { unidad: 'mm cúbicos', valor: 16 },
        { unidad: 'muiactant', valor: 63 },
        { unidad: 'muiacthor', valor: 52 },
        { unidad: 'muiactig', valor: 65 },
        { unidad: 'nanogramos', valor: 34 },
        { unidad: 'otras unidades', valor: 98 },
        { unidad: 'packs', valor: 96 },
        { unidad: 'pares', valor: 8 },
        { unidad: 'picogramos', valor: 35 },
        { unidad: 'quilates', valor: 10 },
        { unidad: 'seña/anticipo', valor: 97 },
        { unidad: 'toneladas', valor: 29 },
        { unidad: 'uiactant', valor: 62 },
        { unidad: 'uiacthor', valor: 51 },
        { unidad: 'uiactig', valor: 64 },
        { unidad: ' unidades', valor: 7 }
    ]
    let obtenerUnidad = (value) => {
        let unidades = [
            { unidad: '1000 kWh', valor: 6 },
            { unidad: 'anulación/devolución', valor: 95 },
            { unidad: 'centímetros', valor: 20 },
            { unidad: 'cm cúbicos', valor: 27 },
            { unidad: 'curie', valor: 48 },
            { unidad: 'dam cúbicos', valor: 30 },
            { unidad: 'docenas', valor: 9 },
            { unidad: 'gramo activo', valor: 67 },
            { unidad: 'gramo base', valor: 68 },
            { unidad: 'gramos', valor: 14 },
            { unidad: 'gruesa', valor: 54 },
            { unidad: 'hectolitros', valor: 18 },
            { unidad: 'hm cúbicos', valor: 31 },
            { unidad: 'jgo. pqt. mazo naipes', valor: 25 },
            { unidad: 'kg activo', valor: 66 },
            { unidad: 'kg base', valor: 53 },
            { unidad: ' kg bruto', valor: 61 },
            { unidad: 'kilogramos', valor: 1 },
            { unidad: 'kilómetros', valor: 17 },
            { unidad: 'km cúbicos', valor: 32 },
            { unidad: 'litros', valor: 5 },
            { unidad: 'metros', valor: 2 },
            { unidad: 'metros cuadrados', valor: 3 },
            { unidad: 'metros cúbicos', valor: 4 },
            { unidad: 'microcurie', valor: 50 },
            { unidad: 'microgramos', valor: 33 },
            { unidad: 'milicurie', valor: 49 },
            { unidad: 'miligramos', valor: 41 },
            { unidad: 'mililitros', valor: 47 },
            { unidad: 'milimetros', valor: 15 },
            { unidad: 'millares', valor: 11 },
            { unidad: 'mm cúbicos', valor: 16 },
            { unidad: 'muiactant', valor: 63 },
            { unidad: 'muiacthor', valor: 52 },
            { unidad: 'muiactig', valor: 65 },
            { unidad: 'nanogramos', valor: 34 },
            { unidad: 'otras unidades', valor: 98 },
            { unidad: 'packs', valor: 96 },
            { unidad: 'pares', valor: 8 },
            { unidad: 'picogramos', valor: 35 },
            { unidad: 'quilates', valor: 10 },
            { unidad: 'seña/anticipo', valor: 97 },
            { unidad: 'toneladas', valor: 29 },
            { unidad: 'uiactant', valor: 62 },
            { unidad: 'uiacthor', valor: 51 },
            { unidad: 'uiactig', valor: 64 },
            { unidad: ' unidades', valor: 7 }
        ]
        const condicion = unidades.find(U => U.valor == value);
        return condicion ? condicion.unidad : 'Unidad no seleccionada';
    }
    const handleDetallesChange = event => {
        setDetalles({
            ...detalles,
            [event.target.name]: event.target.value
        });
    };

    return (
        <article className='detalle-factura-manual'>
            <div className='form-manual'>
                <label htmlFor="cantidad">Cantidad</label>
                <input
                    type="text"
                    name="cantidad"
                    id="cantidad"
                    onChange={handleDetallesChange}
                    placeholder="Cantidad elementos por defecto 1"
                    value={detalles.cantidad}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="descripcion">Descripcion</label>
                <input
                    type="text"
                    name="descripcion"
                    id="descripcion"
                    onChange={handleDetallesChange}
                    placeholder="Descripcion del producto"
                    value={detalles.descripcion}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="unidad_bulto">Unidad bulto</label>
                <input
                    type="text"
                    name="unidad_bulto"
                    id="unidad_bulto"
                    onChange={handleDetallesChange}
                    placeholder="Cantidad de unidades"
                    value={detalles.unidad_bulto}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="unidad_medida">Unidades</label>
                <select name="unidad_medida" id="unidad_medida" onChange={handleDetallesChange}>
                    {
                        detalles.unidad_medida !== "" ?
                            <option defaultValue={detalles.unidad_medida} hidden>{obtenerUnidad(detalles.unidad_medida)}</option>
                            :
                            <option defaultValue={""} hidden>Seleccione una opcion</option>
                    }
                    {unidades.map((element, index) =>
                        <option key={index} value={element.valor}>{element.unidad}</option>
                    )}
                </select>
            </div>
            <div className='form-manual'>
                <label htmlFor="leyenda">Leyenda</label>
                <input
                    type="text"
                    name="leyenda"
                    id="leyenda"
                    onChange={handleDetallesChange}
                    placeholder="Leyenda del elemento"
                    value={detalles.leyenda}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="lista_precios">Lista precios</label>
                <input
                    type="text"
                    name="lista_precios"
                    id="lista_precios"
                    onChange={handleDetallesChange}
                    placeholder="Lista de precios utilizada"
                    value={detalles.lista_precios}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="impuestos_internos_alicuota">Impuestos internos alicuota</label>
                <input
                    type="text"
                    name="impuestos_internos_alicuota"
                    id="impuestos_internos_alicuota"
                    onChange={handleDetallesChange}
                    placeholder="Cantidad en numeros"
                    value={detalles.impuestos_internos_alicuota}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="bonificacion_porcentaje">Bonificacion</label>
                <input
                    type="text"
                    name="bonificacion_porcentaje"
                    id="bonificacion_porcentaje"
                    onChange={handleDetallesChange}
                    placeholder="Bonificacion del producto"
                    value={detalles.bonificacion_porcentaje}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="alicuota">Alicuota</label>
                <input
                    type="text"
                    name="alicuota"
                    id="alicuota"
                    onChange={handleDetallesChange}
                    placeholder="Alicuota del elemento"
                    value={detalles.alicuota}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="precio_unitario_sin_iva">Precio Unitario Sin IVA</label>
                <input
                    type="text"
                    name="precio_unitario_sin_iva"
                    id="precio_unitario_sin_iva"
                    onChange={handleDetallesChange}
                    placeholder="Precio unitario sin Alicuota"
                    value={detalles.precio_unitario_sin_iva}
                />
            </div>
        </article>
    )
}

export default Detalle