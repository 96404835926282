import React, { useState } from 'react'
import TableGenerator from '../../../components/TableGenerator/TableGenerator'
import Loading from '../../../components/partials/Loading'
import ExcelButton from '../../../components/partials/Excel/ExcelButton'
import consulta from '../../../utils/consulta'

import './Styles/Informacion.css'

function InfoValores() {

	const [cuil, setCuil] = useState("0")
	const [isLoading, setIsLoading] = useState(false)
	const [ValorPlan, setValorPlan] = useState([])
	const [columnas, setcolumnas] = useState("")
	const [existe, setExiste] = useState(false)

	const valorPlanIndividual = async (e) => {
		try {
			e.preventDefault()
			if (cuil.length === 11) {
				setIsLoading(true)
				let urlValorPlanIndividual = `/informacion/api/valorPlan/individual?afiliado=${cuil}`
				let response = await consulta.get(urlValorPlanIndividual)
				SettearColumnas("VPI");
				setValorPlan(response.data)
				setIsLoading(false)
			} else {
				setExiste("Error con los datos")
			}
		} catch (error) {
			console.error(error)
		}
	}
	const valorPlanContrato = async (e) => {
		try {
			e.preventDefault()
			if (cuil.length === 11) {
				setIsLoading(true)
				let urlValorPlanContrato = `/informacion/api/valorPlan/contrato?titular=${cuil}`
				let response = await consulta.get(urlValorPlanContrato)
				SettearColumnas("VPC")
				setValorPlan(response.data)
				setIsLoading(false)
			} else {
				setExiste("Error con los datos")
			}
		} catch (error) {
			console.error(error)
		}
	}
	const volverAtras = (e) => {
		e.preventDefault()
		setCuil('')
		setValorPlan([])
		setcolumnas("")
		setExiste(false)
	}
	const SettearColumnas = (funcion) => {
		let columnaValorPlanPadron
		if (funcion === 'VPI') {
			columnaValorPlanPadron = [
				{ field: 'id', headerName: 'ID', width: 70 },
				{ field: 'cuil', headerName: 'CUIL', width: 110 },
				{ field: 'periodo', headerName: 'Periodo', width: 90 },
				{ field: 'edad', headerName: 'Edad', width: 90 },
				{ field: 'lista', headerName: 'Lista', width: 90 },
				{ field: 'categoria', headerName: 'Categoría', width: 100 },
				{ field: 'plan', headerName: 'Plan Afiliado', width: 140 },
				{ field: 'valor', headerName: 'Valor plan', width: 140 }
			];
		} else {
			columnaValorPlanPadron = [
				{ field: 'contratoId', headerName: 'contratoId', width: 90 },
				{ field: 'titular', headerName: 'Titular', width: 110 },
				{ field: 'periodo', headerName: 'Periodo', width: 90 },
				{ field: 'gf', headerName: 'GF', width: 70 },
				{ field: 'valorContrato', headerName: 'Valor Contrato', width: 140 },
				{ field: 'descuentoComercialMonto', headerName: 'Descuento Comercial', width: 140 },
				{ field: 'descuentoMonto', headerName: 'Descuentos Vp', width: 140 },
				{ field: 'adicionalMonto', headerName: 'Adicionales', width: 140 },
				{ field: 'valorFinal', headerName: 'Valor Final', width: 140 },
				{ field: 'tipoDescuentoAplicado', headerName: 'Tipo Descuento Aplicado', width: 140 },
				{ field: 'tipoValorPlan', headerName: 'Tipo VP', width: 140 },
			];
		}
		setcolumnas(columnaValorPlanPadron)
	}

	if (isLoading) {
		return (
			<Loading texto={"Cargando Valores Plan"} />
		)
	}

	if (ValorPlan.length > 0) {
		return (
			<div className='ValorPlan'>
				<h2>Datos del usuario {cuil}</h2>
				{ValorPlan.length > 0 &&
					<TableGenerator columns={columnas} data={ValorPlan} />
				}
				<div className='botones'>
					<button className='button-back' onClick={volverAtras}>Atras</button>
					<ExcelButton data={ValorPlan} nombreHoja={"Valores Plan"} nombreArchivo={`ValoresPlan ${cuil}`} />
				</div>
			</div>
		)
	}

	return (
		<div className='ValorPlan'>
			<section className='seccion-busqueda'>
				<h2>Busqueda de los Valores Plan de un CUIL en especifico</h2>
				<div className='Busqueda'>
					<input
						id='search'
						name='search'
						className='form-control inputBuscar'
						onChange={(e) => setCuil(e.target.value)}
						placeholder="Ingresá un Cuil"
						type="text" />
					{existe &&
						<h4 className='ValorPlans-inexistentes'>{existe}</h4>
					}
					<div className='botones'>
						<button className='valor-Plan-Individual-button' onClick={valorPlanIndividual}>Buscar Valor Plan Individual</button>
						<button className='valor-Plan-Contrato-button' onClick={valorPlanContrato}>Buscar Valor Plan Contrato</button>
					</div>
				</div>
			</section>
		</div>
	);
}


export default InfoValores