import React from 'react'

/* Utilidades */
import { parsearFecha, pasarNumeroAMoneda, unsecuredCopyToClipboard } from '../../../utils/funciones'

function PlanDePago({saldo, pago}) {

    console.log("Saldoo -->",saldo);
    console.log("Pago -->",pago);

    return (
        <li className={`cuenta-saldos plan-de-pago ${pago ? "pagado" : ""}`}>
            <div className='cuenta-periodo'>
                <h5>Plan De Pago</h5>
            </div>
            <div className='cuenta-plan-categoria'>
                <h5>{parsearFecha(saldo.periodo)}</h5>
            </div>
            <div className='cuenta-mediopago'>
                <h5>{saldo.medioPago}</h5>
            </div>
            <div className='cuenta-saldo'>
                <h5>CUOTA</h5>
                <h5>{saldo.cuota}</h5>
            </div>
            <div className='cuenta-saldo'>
                <h5>Saldo</h5>
                <h5>{pasarNumeroAMoneda(saldo.saldo)}</h5>
            </div>
            {(saldo.pagos.total === 0 && pago || (!pago)) &&
                <div className='bloque-mes'>
                <h6 className='linkDePago'>
                    <div
                        className='link'
                        onClick={() => unsecuredCopyToClipboard(saldo.linkDePago)}
                    >
                        Link de pago
                    </div>
                </h6>
            </div>}
        </li>
    )
}

export default PlanDePago