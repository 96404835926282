import React, { useState } from 'react'
import consulta from '../../../../utils/consulta'
import FileUploader from '../../../../components/Uploader/FileUploader';
import Loading from '../../../../components/partials/Loading';
import ExcelButton from '../../../../components/partials/Excel/ExcelButton';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator'

function Resumen() {
    const [selectedResumen, setSelectedResumen] = useState(null);
    const [Errores, setErrores] = useState("")
    const [loading, setLoading] = useState(false)
    const [Aportes, setAportes] = useState([]);
	const [columnas, setColumnas] = useState([])

    const handleResumenSelect = (files) => {
        setSelectedResumen(files[0]);
    };

    const volverAtras = () => {
		setLoading(true)
		setColumnas([])
		setAportes([])
		setSelectedResumen(null)
		setLoading(false)
	}

    const handleUploadClick = async () => {
        try {
            setLoading(true)
            const formData = new FormData();
            if (selectedResumen) {
                formData.append('manuales', selectedResumen);
            }
            // Realiza la solicitud POST con axios (o fetch)
            settearColumnas()
            const response = await consulta.post('/aportes/manuales', formData);
            setAportes(response.data)
            setLoading(false)
        } catch (error) {
            // Maneja los errores si es necesario
            console.error('Error:', error);
            setErrores(error.message)
            setLoading(false)
        }
    };

    const settearColumnas = () => {
		let columnaAportes = [
			{ field: 'cuil', headerName: 'CUIL', width: 110 },
			{ field: 'cuit', headerName: 'CUIT', width: 110 },
			{ field: 'obra', headerName: 'Obra Social', width: 90 },
			{ field: 'periodo', headerName: 'Periodo', width: 90 },
		];
		setColumnas(columnaAportes)
	}

    if (loading) {
        return (
            <Loading texto={"Cargando Aportes"} />
        )
    }

    if (Aportes.length > 0) {
		return (
			<div className='ValorPlan'>
				<h2 className='columnas'>¡Aportes Cargados!</h2>
				{Aportes.length > 0 &&
					<TableGenerator columns={columnas} data={Aportes} />
				}
				<div className='botones'>
					<button className='button-back' onClick={volverAtras}>Atras</button>
					<ExcelButton data={Aportes} nombreHoja={"Osceara"} nombreArchivo={`Aportes`} />
				</div>
			</div>
		)
	}

    return (
        <div className="Resumen Obra-Social">
            <h2 className='titulo-seccion'>Carga del excel Aportes Manuales</h2>
            <article className='formularios-aportes'>
                <FileUploader label="Manuales.xlsx" title={"Aportes Manuales"} onFileSelect={handleResumenSelect} />
            </article>
            {Errores !== "" &&
                <p>{Errores}</p>}
            <div className='boton-de-carga'>
                <button className='submit-button' onClick={handleUploadClick}>Cargar Aportes</button>
            </div>
        </div>
    );
}

export default Resumen