import React, { useState } from "react";

import TablaFacturas from "./components/TablaFacturas";
import FacturaManual from "./components/FacturaManual";
import NotasDeCredito from "./components/NotasDeCredito";
import Loading from "../../../components/partials/Loading";
import consulta from '../../../utils/consulta';

import "./Styles/facturas.css";
import VerFacturas from "./components/VerFacturas";

function Facturas() {
    const [cuil, setCuil] = useState("0");
    const [titular, setTitular] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [facturas, setFacturas] = useState([]);
    const [columnas, setColumnas] = useState("");
    const [existe, setExiste] = useState(false);
    const [ruta, setRuta] = useState("facturas");

    console.log("facturas -->", facturas);

    const cambiarRuta = (e, valor) => {
        e.preventDefault();
        setRuta(valor);
    };

    const volverAtras = (e) => {
        e.preventDefault();
        setIsLoading(true);
        setCuil("");
        setFacturas([]);
        setColumnas("");
        setExiste(false);
        setIsLoading(false);
    };
    let settearheaders = () => {
        setColumnas([
            { field: "periodo", headerName: "Periodo", width: 110 },
            { field: "cuil", headerName: "Cuil Titular", width: 110 },
            { field: "saldo", headerName: "Saldo", width: 110 },
            { field: "pagado", headerName: "Pagado", width: 110 },
            { field: "UrlLink", headerName: "UrlLink", width: 110 },
        ]);
    };
    const buscarDatos = async (e) => {
        try {
            e.preventDefault();
            if (cuil.length === 11) {
                setIsLoading(true);
                let urlFacturas = `/facturacion/api/total?titular=${cuil}`;
                let response = await consulta.get(urlFacturas);
                settearheaders();
                setTitular(response.meta.nombreTitular);
                setFacturas(response.data);
                setIsLoading(false);
            } else {
                setExiste("Error con los datos");
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    if (isLoading) {
        return <Loading texto={"Cargando Facturas"} />;
    }

    if (facturas.length > 0) {
        return (
            <div className="Facturas">
                <div className="botones">
                    <button className="button-back" onClick={volverAtras}>
                        Atras
                    </button>

                    {ruta === "Facturas-dulce-de-leche" ?
                        <button className="button-saldos-facturas" onClick={(e) => cambiarRuta(e, 'facturas')}>
                            Saldos
                        </button>
                        :
                        <button className="button-todas-facturas" onClick={(e) => cambiarRuta(e, 'Facturas-dulce-de-leche')}> Ver Todas las Facturas </button>
                    }
                    {ruta === "notasDeCredito" ?
                        <button className="button-saldos-facturas" onClick={(e) => cambiarRuta(e, 'facturas')}>
                            Saldos
                        </button>
                        :
                        <button className="button-nota-credito" onClick={(e) => cambiarRuta(e, 'notasDeCredito')}>
                            Nota de Credito
                        </button>
                    }
                    {ruta === "facturasManual" ?
                        <button className="button-saldos-facturas" onClick={(e) => cambiarRuta(e, 'facturas')}>
                            Saldos
                        </button>
                        :
                        <button className="button-factura-manual" onClick={(e) => cambiarRuta(e, 'facturasManual')}>
                            Factura Manual
                        </button>
                    }
                </div>
                <h2 className="titulo">Usuario: {titular}</h2>
                <h2>Cuil: {cuil}</h2>

                {ruta === "notasDeCredito" ?
                    <NotasDeCredito facturas={facturas} columnas={columnas}/>
                    : ruta === "facturasManual" ?
                        <FacturaManual facturas={facturas} cuil={cuil} />
                        : ruta === "Facturas-dulce-de-leche" ?
                            <VerFacturas cuil={cuil} />
                            :
                            <TablaFacturas facturas={facturas} columnas={columnas} />
                }
            </div>
        );
    }

    return (
        <div className="ValorPlan">
            <section className="seccion-busqueda">
                <h2>Facturas de un contrato en especifico</h2>
                <form className="Busqueda" onSubmit={(e) => buscarDatos(e)}>
                    <input
                        id="search"
                        name="search"
                        className="form-control inputBuscar"
                        onChange={(e) => setCuil(e.target.value)}
                        placeholder="Ingresá un Cuil"
                        type="text"
                    />
                    {existe && <h4 className="ValorPlans-inexistentes">{existe}</h4>}
                    <div className="botones">
                        <button
                            type="submit"
                            className="valor-Plan-Individual-button"
                        >
                            Buscar Facturas
                        </button>
                    </div>
                </form>
            </section>
        </div>
    );
}

export default Facturas;
