import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import Loading from '../../../components/partials/Loading';
import consulta from '../../../utils/consulta';
import { parsearFecha, unsecuredCopyToClipboard } from '../../../utils/funciones';
import Swal from 'sweetalert2';

/* Styles */
import '../Styles/modalCuentaCorriente.css'

function ModalCuentaCorriente({ onClose, cuil }) {

    const state = useSelector(state => state);
    const [User, setUser] = useState(state.userData[0])

    useEffect(() => {
        setUser(state.userData[0])
    }, [state]);


    const [nombre, setNombre] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [monto, setMonto] = useState(0)
    const [saldos, setSaldos] = useState([])
    const [saldoSeleccionado, setSaldoSeleccionado] = useState([]);
    const [seleccionarTodo, setSeleccionarTodo] = useState(false);
    const [deuda, setDeuda] = useState(0)
    const [total, setTotal] = useState(0)
    const [errores, setErrores] = useState("")
    const [linkMpTotal, setlinkMpTotal] = useState("")
    const [MontoMpTotal, setMontoMpTotal] = useState(0)

    const BuscarSaldoContrato = async () => {
        try {
            setIsLoading(true)
            let urlAporteUtilizado = `/informacion/api/saldos/impagos?titular=${cuil}`
            let response = await consulta.get(urlAporteUtilizado)
            setSaldos(response.data)
            response.data.forEach(saldo => {
                let suma = 0
                if (saldo.linkMpTotal !== "") {
                    suma = suma + saldo.valor
                    setlinkMpTotal(saldo.linkMpTotal)
                }
                setMontoMpTotal(suma)
            })
            setNombre(response.meta.nombreAfiliado)
            setDeuda(calcularTotalDeuda(response.data))
            setErrores("")
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setErrores(error.message)
        }
    }
    useEffect(() => {
        BuscarSaldoContrato()
    }, [])

    const calcularTotalDeuda = (saldos) => {
        const totalSaldo = saldos.reduce((acumulador, objeto) => {
            // Comprueba si el objeto tiene la clave "valor"
            if (objeto.hasOwnProperty("valor")) {
                acumulador += objeto.valor;
            }
            return acumulador;
        }, 0);
        return totalSaldo
    }

    const handleSaldoSeleccionadoChange = (e, saldo) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            let datos = [...saldoSeleccionado, saldo]
            setTotal(calcularTotalDeuda(datos))
            setSaldoSeleccionado(datos);
        } else {
            let datos = saldoSeleccionado.filter((s) => s !== saldo)
            setTotal(calcularTotalDeuda(datos))
            setSaldoSeleccionado(datos);
        }
    };

    const handleSeleccionarTodoChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setTotal(calcularTotalDeuda([...saldos]))
            setSaldoSeleccionado([...saldos]);
        } else {
            setTotal(0)
            setSaldoSeleccionado([]);
        }
        setSeleccionarTodo(isChecked);
    };

    const handlePagar = async (e) => {
        e.preventDefault()
        if (saldoSeleccionado.length > 0) {
            let obj = {
                saldos: saldoSeleccionado,
                monto: monto,
                cuil: cuil,
                User: User,
            }
            const urlMisticalinks = `/modificaciones/saldos/crearLinkTotal?afiliado=${cuil}`

            await Swal.fire({
                title: `Esta seguro que desea generar un link por un valor total de ${monto > 0 ? monto : calcularTotalDeuda(saldoSeleccionado)}`,
                showCancelButton: true,
                confirmButtonText: 'Generar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await consulta.post(urlMisticalinks, obj)
                    if (response.status === 200) {
                        Swal.fire(`¡Link Generado! \n ${response.meta.linkFinal}`, '', 'success')
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Error al generar " + response.status,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }
            })

        }
    }

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
                <button className="modal-close-button" onClick={onClose}>
                    &times;
                </button>
                {isLoading ?
                    <Loading />
                    :
                    <section className='Modal-CtaCte'>
                        <h2>Link Multiple: {cuil}<br />{nombre}</h2>
                        <ul className='datos'>
                            <li className='header-saldo-individual'>
                                <div className='datavalue'>
                                    <input type="checkbox"
                                        name="all"
                                        id="all"
                                        checked={seleccionarTodo}
                                        onChange={handleSeleccionarTodoChange}
                                    />
                                    <label htmlFor="all">Seleccionar</label>
                                </div>
                                <h4>Periodo</h4>
                                <h4>Tipo Cobranza</h4>
                                <h4>Valor</h4>
                                <h4>Link</h4>
                            </li>
                            {saldos.map((saldo, index) =>
                                <label htmlFor={saldo.id} key={index + 1}>
                                    <li className='saldo-individual'>
                                        <div className='datavalue'>
                                            <input
                                                type="checkbox"
                                                name="saldo"
                                                id={saldo.id}
                                                onChange={(e) => handleSaldoSeleccionadoChange(e, saldo)}
                                                checked={saldoSeleccionado.includes(saldo)}
                                            />
                                        </div>
                                        <div className='datavalue'>
                                            <h4>{parsearFecha(saldo.periodo)}</h4>
                                        </div>
                                        <div className='datavalue'>
                                            <h4>{saldo.tipoDeCobranza}</h4>
                                        </div>
                                        <div className='datavalue'>
                                            <h4>${saldo.valor}</h4>
                                        </div>
                                        <div className='datavalue'>
                                            {saldo.linkMpTotal === "" ?
                                                <h4>Sin Link Total</h4>
                                                :
                                                <button onClick={() => unsecuredCopyToClipboard(saldo.linkMpTotal)}>Copiar Link Total</button>
                                            }
                                        </div>
                                    </li>
                                </label>
                            )}
                        </ul>
                        <article className='calculadora'>

                            <div></div>
                            <div></div>

                            <div className='calculo'>
                                <h3>Deuda Total</h3>
                                <h3>${deuda}</h3>
                            </div>

                            <div className='calculo'>
                                <h3>Seleccionado</h3>
                                <h3>${total}</h3>
                            </div>
                        </article>
                        <div className='bottom-page'>
                            <button className='pago' onClick={handlePagar}>Crear Link Pago Multiple</button>
                            <div className='formData'>
                                <label htmlFor="monto">Monto a Pagar: </label>
                                <input type="text" name="monto" id="monto" onChange={({ target }) => setMonto(target.value)} />
                            </div>
                        </div>
                    </section>}
            </div>
        </div>
    );
}

export default ModalCuentaCorriente

