import React, { useState } from 'react'
import consulta from '../../../../utils/consulta'
import FileUploader from '../../../../components/Uploader/FileUploader';
import Loading from '../../../../components/partials/Loading';
import ExcelButton from '../../../../components/partials/Excel/ExcelButton';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator'
import { fechaMesDia, pasarNumeroAMoneda } from '../../../../utils/funciones';

function Naranja() {
    const [loteNaranja, setloteNaranja] = useState(null);
    const [loading, setLoading] = useState(false)
    const [saldos, setSaldos] = useState([]);
    const [Errores, setErrores] = useState("");
    const [columnas, setColumnas] = useState([]);
    const [cantidad, setCantidad] = useState(0)
    const [cantidadFiltrada, setCantidadFiltrada] = useState(0)
    const [sumaValor, setSumaValor] = useState(0)

    const handleNaranja = (files) => {
        setloteNaranja(files[0]);
    };

    const handleUploadClick = async () => {
        try {

            setLoading(true)
            const formData = new FormData();
            let urlFinal = ""
            if (loteNaranja) {
                urlFinal = '/lotes/api/naranja/recibir'
                formData.append('naranja', loteNaranja);
            }

            settearColumnas()
            const response = await consulta.post(urlFinal, formData);
            setSaldos(response.data)
            setCantidad(response.meta.total)
            setCantidadFiltrada(response.meta.cantidadPagada)
            setSumaValor(response.meta.montoTotal)
            setLoading(false)

        } catch (error) {
            setErrores(error.message)
            console.error('Error:', error);
            setLoading(false)
        }
    };

    const volverAtras = () => {
        setLoading(true)
        setColumnas([])
        setSaldos([])
        setloteNaranja(null)
        setLoading(false)
    };
    const settearColumnas = () => {
        let columnaAportes = [
            { field: 'idContrato', headerName: 'Contrato', width: 90 },
            { field: 'titular', headerName: 'Titular', width: 90 },
            { field: 'importe', headerName: 'Importe', width: 110 },
            { field: 'lote', headerName: 'Lote', width: 150 },
            { field: 'nroComprobante', headerName: 'Comprobante', width: 110 },
            { field: 'periodo', headerName: 'Periodo', width: 90 },
            { field: 'codigoMovimiento', headerName: 'Codigo Movimiento', width: 90 },
            { field: 'externalRef', headerName: 'Referencia', width: 90 },
            { field: 'descripcionDelMovimiento', headerName: 'Descripcion', width: 260 },
        ];
        setColumnas(columnaAportes)
    };

    if (loading) {
        return (
            <Loading texto={"Cargando Lotes"} />
        )
    }

    if (saldos.length > 0) {
        return (
            <div className='ValorPlan'>
                <h2>Naranja Imputado</h2>
                <section className='tablaPadron-section-header'>
                    <h2 className='titulo-seccion'>Saldos Totales: {cantidad}</h2>
                    <h2 className='titulo-seccion'>Saldos Filtrados: {cantidadFiltrada}</h2>
                    <h2 className='titulo-seccion'>Valor Total: {pasarNumeroAMoneda(sumaValor)}</h2>
                </section>
                {saldos.length > 0 &&
                    <TableGenerator columns={columnas} data={saldos} />
                }
                <div className='botones'>
                    <button className='button-back' onClick={volverAtras}>Atras</button>
                    <ExcelButton data={saldos} nombreHoja={'Naranja'} nombreArchivo={`Naranja ${fechaMesDia()}`} />
                </div>
            </div>
        )
    }
    return (
        <div className="Osceara Obra-Social">
            <h2 className='titulo-seccion'>Carga de Lote Naranja</h2>
            <article className='formularios-aportes'>
                <FileUploader label="naranja.txt" title={"Naranja"} onFileSelect={handleNaranja} />
            </article>
            <div className='boton-de-carga'>
                <button className='submit-button' onClick={handleUploadClick}>Cargar Lotes</button>
            </div>
        </div>
    );
}

export default Naranja