import Swal from 'sweetalert2';

const parsearFecha = (numero) => {
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    let string = numero.toString()
    let mes = string.substring(string.length - 2, string.length)
    let year = string.substring(0, string.length - 2)
    if (!isNaN(mes) && mes >= 1 && mes <= 12) {
        let elemento = meses[mes - 1];
        let completo = `${elemento} ${year}`
        return completo
    }
}
const monthNow = () => {
    let fecha = new Date()
    return fecha.getMonth() < 9 ? `0${fecha.getMonth() + 1}` : `${fecha.getMonth() + 1}`
}
const yearNow = () => {
    let fecha = new Date()
    return fecha.getFullYear()
}
const fechaMes = () => {
    let fecha = new Date()
    let year = fecha.getFullYear()
    let mes = fecha.getMonth() + 1
    if (mes < 10) {
        mes = `0${mes}`
    }
    return `${year}${mes}`
}
const fechaMesDia = () => {
    let fecha = new Date()
    let year = fecha.getFullYear()
    let mes = fecha.getMonth() + 1
    let dia = fecha.getDate()
    if (mes < 10) {
        mes = `0${mes}`
    }
    return `${year}${mes}${dia}`
}
const obtenerFechaActual = () => {
    const fecha = new Date();
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear().toString();
    return `${dia}/${mes}/${anio}`;
}
const obtenerHoraActual = () => {
    const fecha = new Date();
    const hora = fecha.getHours() - 3;
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    return `${hora}:${minutos}`;
}
const obtenerFechaActualConHora = () => {
    const fecha = new Date();
    const dia = fecha.getDate().toString().padStart(2, '0');
    const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio = fecha.getFullYear().toString();
    const hora = fecha.getHours().toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    return `${dia}/${mes}/${anio} ${hora}:${minutos}`;
}
const getDaysInMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const lastDay = new Date(year, month + 1, 0).getDate();

    const days = [];
    for (let i = 1; i <= lastDay; i++) {
        days.push(new Date(year, month, i));
    }

    return days.length;
}
const daysInMonth = (yearMonth) => {
    const year = parseInt(yearMonth.substring(0, 4), 10);
    const month = parseInt(yearMonth.substring(4, 6), 10);
    return new Date(year, month, 0).getDate();
}
const pasarFecha = (fecha) => {
    let elementosFecha = fecha.split('/');
    let fechaConvertida = elementosFecha[2] + elementosFecha[1] + elementosFecha[0];
    return +fechaConvertida;
}
const inversaPeriodo = (title) => {
    let year = title.substring(title.lastIndexOf(" ") + 1)
    let mes = title.substring(19, title.lastIndexOf(" "))
    let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    let valorMes = meses.findIndex(month => month === mes) + 1
    return `${year}${valorMes < 10 ? "0" + valorMes : valorMes}`
}
const funcionFecha = (fechaString) => {
    // Convertimos la cadena de texto a objeto Date
    const fecha = new Date(fechaString.split('/').reverse().join('-'));

    // Definimos un objeto de opciones para el formato de fecha
    const opciones = { day: 'numeric', month: 'long', year: 'numeric' };

    // Convertimos la fecha a string en el formato deseado
    const fechaFormateada = fecha.toLocaleDateString('es-ES', opciones);

    // Retornamos la fecha formateada
    return fechaFormateada;
}
const funcionFechaCreatedAtUpdatedAt = (fechaISO) => {
    // Convertimos la fecha ISO a objeto Date
    const fecha = new Date(fechaISO);

    // Obtenemos el día, mes y año de la fecha
    const dia = fecha.getUTCDate();
    const mes = fecha.toLocaleDateString('es-ES', { month: 'long' });
    const anio = fecha.getUTCFullYear();

    // Obtenemos la hora, minutos y segundos de la fecha
    const hora = fecha.getUTCHours();
    const minutos = fecha.getUTCMinutes();
    const segundos = fecha.getUTCSeconds();

    // Creamos el string formateado
    const fechaFormateada = `${dia} de ${mes} del ${anio} a las ${hora}:${minutos}:${segundos}`;

    // Retornamos la fecha formateada
    return fechaFormateada;
}
const parsearPersonas = (str) => {
    const regex = /\[(.*?)\]/g;
    const matches = str.match(regex);

    if (!matches) {
        return [];
    }

    const result = matches.map(match => {
        const obj = {};
        const properties = match.slice(1, -1).split(',');

        properties.forEach(property => {
            const [key, value] = property.split(':');
            obj[key.trim()] = value.trim();
        });

        return obj;
    });

    return result;
}
const completaCaracteres = (numero, cantidadCaracteres) => {
    // Convertimos el número a un string
    let numeroString = numero.toString();

    // Si el string ya tiene 10 caracteres, simplemente lo devolvemos
    if (numeroString.length === cantidadCaracteres) {
        return numeroString;
    }

    // Si el string tiene menos de 10 caracteres, completamos con ceros al principio
    let cerosFaltantes = cantidadCaracteres - numeroString.length;
    let ceros = "0".repeat(cerosFaltantes);
    return ceros + numeroString;
}
const zfill = (number, width) => {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */
    var zero = "0"; /* String de cero */

    if (width <= length) {
        if (number < 0) {
            return ("-" + numberOutput.toString());
        } else {
            return numberOutput.toString();
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString());
        }
    }
}
const obtenerHoraActualGMT3 = () => {
    let fecha = new Date();
    fecha.setHours(fecha.getHours() - 3); // ajustar la hora por 3 horas para GMT-3
    let horas = fecha.getHours().toString().padStart(2, '0'); // obtener las horas y agregar un cero a la izquierda si es necesario
    let minutos = fecha.getMinutes().toString().padStart(2, '0'); // obtener los minutos y agregar un cero a la izquierda si es necesario
    return horas + minutos;
}
const obtenerFechaMaster = () => {
    let fecha = new Date();
    let dia = fecha.getDate().toString().padStart(2, '0'); // obtener el día y agregar un cero a la izquierda si es necesario
    let mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // obtener el mes y agregar un cero a la izquierda si es necesario
    let anio = fecha.getFullYear().toString().substring(2); // obtener los últimos dos dígitos del año
    return dia + mes + anio;
}
const obtenerPeriodo = (fecha) => {
    const [, mes, anio] = fecha.split('/');
    const periodo = `20${anio}${mes}`;

    return periodo;
}
const obtenerFechaYHoraGMT3 = () => {
    // Obtener la fecha y hora actual en el huso horario local
    const fechaHoraActualLocal = new Date();

    // Ajustar la diferencia horaria para obtener la fecha y hora en GMT-3 (Argentina)
    const gmtOffset = -3; // GMT-3: resta 3 horas al tiempo local
    const fechaHoraGMT3 = new Date(fechaHoraActualLocal.getTime() + gmtOffset * 60 * 60 * 1000);

    // Formatear la fecha y hora según tu preferencia
    const fechaFormateada = fechaHoraGMT3.toISOString().slice(0, 10);
    const horaFormateada = fechaHoraGMT3.toISOString().slice(11, 19);

    return {
        fecha: fechaFormateada,
        hora: horaFormateada,
    };
}
const escribirFuncion = (nombreFuncion) => {
    switch (nombreFuncion) {
        case "AUI":
            return "Aportes Utilizados Individuales"

        case "AUPC":
            return "Aportes Utilizados Padrón Completo"

        case "ACI":
            return "Aportes Contrato Individual"

        case "ACPC":
            return "Aportes Contrato Padrón Completo"

        case "VPI":
            return "Valor Plan Individual"

        case "VPP":
            return "Valor Plan Padrón Completo"

        case "VPCI":
            return "Valor Plan Contrato Individual"

        case "VPCP":
            return "Valor Plan Contrato Padrón Completo"

        case "SIC":
            return "Saldo Individual por Contrato"

        case "SPC":
            return "Saldo Contrato Afiliados"

        case "SIP":
            return "Saldo Individual Parcial"

        default:
            return "Nada por aqui ..."
    }
}

let unsecuredCopyToClipboard = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: "¡Texto Copiado al portapapeles!",
            showConfirmButton: false,
            timer: 1000
        })
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
}

const pasarNumeroAMoneda = (numero) => {

    let numeroFormateado = 0
    if(numero > 0) {
        // Convertir el número a una cadena y dividirlo en partes de 3 cifras desde el final
        numeroFormateado = numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    // Agregar el símbolo de moneda al principio
    return `$${numeroFormateado}`;
}


export {
    parsearFecha,
    monthNow,
    yearNow,
    fechaMes,
    fechaMesDia,
    obtenerFechaActual,
    obtenerHoraActual,
    obtenerFechaActualConHora,
    getDaysInMonth,
    daysInMonth,
    pasarFecha,
    inversaPeriodo,
    funcionFecha,
    funcionFechaCreatedAtUpdatedAt,
    parsearPersonas,
    completaCaracteres,
    zfill,
    obtenerHoraActualGMT3,
    obtenerFechaMaster,
    obtenerPeriodo,
    obtenerFechaYHoraGMT3,
    unsecuredCopyToClipboard,
    escribirFuncion,
    pasarNumeroAMoneda
};