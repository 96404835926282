import React, { useState } from 'react'
import { parsearFecha } from '../../../../utils/funciones'
import ExcelButton from '../../../../components/partials/Excel/ExcelButton'
import ModalSaldo from './ModalSaldo'

function Tarjetas({ cuil, saldos , volverAtras }) {
    const [showModal, setShowModal] = useState(false);
	const [selectedSaldo, setSelectedSaldo] = useState(null);

    const abrirModal = (saldo) => {
		setSelectedSaldo(saldo);
		setShowModal(true);
	};

	const cerrarModal = () => {
		setSelectedSaldo(null);
		setShowModal(false);
	};

    return (
        <div>
            <h2 className='columnas'>Saldos del usuario {cuil}</h2>
            <section className='Cards'>
                {saldos.map(saldo =>
                    <article key={saldo.id} className={`${saldo.planDePago === 1 ? "card-saldo PlanDePago" : "card-saldo"}`} onClick={() => abrirModal(saldo)}>
                        <div className="card-header">
                            <h6 className='tipo'>{`${saldo.planDePago === 1 ? "Plan" : "Saldo"}`}</h6>
                            <h6 className='aidi'>{saldo.contratoId}</h6>
                            <h5 className={saldo.pagados === 1 ? "pagado" : "impago"}>{parsearFecha(saldo.periodo)}</h5>
                        </div>
                        <div className="card-body">
                            <h5>Valor: <span>${saldo.valor}</span></h5>
                            <h5>Estado: <span className={saldo.pagados === 1 ? "pagado" : "impago"}>{saldo.pagados === 1 ? "Pagado" : "Impago"}</span></h5>
                            <small>{saldo.fechaActualizacion}</small>
                        </div>
                    </article>
                )}
            </section>

            <div className='botones'>
                <button className='button-back' onClick={volverAtras}>Atras</button>
                <ExcelButton data={saldos} nombreHoja={"Valores Plan"} nombreArchivo={`ValoresPlan ${cuil}`} />
            </div>

            {showModal && selectedSaldo && (
                <ModalSaldo selectedSaldo={selectedSaldo} cerrarModal={cerrarModal} />
            )}
        </div>
    )
}

export default Tarjetas