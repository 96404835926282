import React from 'react'
import carita from '../../assets/line.gif'

function Inexistente() {
  return (
    <main className='CuentaCorriente'>
      <section>
        <h1>No se encuentra la pagina que estas buscando</h1>
        <article className='image-container'>
          <img src={carita} alt="Cafe" />
        </article>
      </section>
    </main>
  )
}

export default Inexistente