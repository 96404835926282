import React, { useState } from 'react'
import Swal from 'sweetalert2';
import consulta from '../../../../utils/consulta'

function Prestacion({ cuil, volverAtras }) {

    const [formData, setFormData] = useState({
        diagnostico: '',
        prestacion: '',
        lugar: '',
        valor: '',
        cuotas: '',
        interes: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            let urlPrestacion = `/modificaciones/planesDePago/contrato?titular=${cuil}`
            let result = await Swal.fire({
                title: `Esta  seguro de crear un plan de pago para el afiliado: ${cuil}?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Crear',
                denyButtonText: `Cancelar`,
            })
            if (result.isConfirmed) {
                let response = await consulta.post(urlPrestacion, formData)
                if (response.status === 200) {
                    Swal.fire('Creada!', '', 'success')
                    volverAtras()
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Falta info " + response.status,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            } else if (result.isDenied) {
                Swal.fire('Cambios no guardados', '', 'error')
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <article className="Prestacion">
            <form onSubmit={handleSubmit}>
                <div className='datos-lugar'>
                    <div className="formData">
                        <label htmlFor="diagnostico">Diagnóstico</label>
                        <input
                            type="text"
                            name="diagnostico"
                            id="diagnostico"
                            value={formData.diagnostico}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formData">
                        <label htmlFor="prestacion">Prestación</label>
                        <input
                            type="text"
                            name="prestacion"
                            id="prestacion"
                            value={formData.prestacion}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formData">
                        <label htmlFor="lugar">Lugar</label>
                        <input
                            type="text"
                            name="lugar"
                            id="lugar"
                            value={formData.lugar}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='datos-valor'>
                    <div className="formData">
                        <label htmlFor="cuotas">Cuotas</label>
                        <select
                            name="cuotas"
                            id="cuotas"
                            value={formData.cuotas}
                            onChange={handleChange}
                        >
                            <option value="" hidden>
                                Seleccione una opción
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                        </select>
                    </div>
                    <div className="formData">
                        <label htmlFor="valor">Valor por Cuota</label>
                        <input
                            type="text"
                            name="valor"
                            id="valor"
                            value={formData.valor}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="formData">
                        <label htmlFor="interes">Interés</label>
                        <input
                            type="text"
                            name="interes"
                            id="interes"
                            value={formData.interes}
                            onChange={handleChange}
                        />
                    </div>

                </div>
                <div className='button-container'>
                    <button type="submit">Crear Plan de Pago</button>
                </div>
            </form>
        </article>
    );
}

export default Prestacion