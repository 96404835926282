import React, { useState } from 'react'
import Simulacion from './Componentes/Simulacion';
import Ejecucion from './Componentes/Ejecucion';

import './Styles/funciones.css'

function Funciones() {

	const [func, setFunc] = useState("")

	return (
		<div className='ValoresPlan'>
			{func === "Ejecución" ?
				<Ejecucion setFunc={setFunc} />
			: func === "Simulación" ?
				<Simulacion setFunc={setFunc} />
			:
				<div className='Funciones Componente-Principal'>
					<section className='funcion'>
						<div className='boton-gigante' onClick={() => setFunc('Simulación')}>
							<h1>Simulaciónes Valor Plan</h1>
						</div>
					</section>
					<section className='funcion'>
						<div className='boton-gigante' onClick={() => setFunc('Ejecución')}>
							<h1>Ejecución de Valores Plan a DB</h1>
						</div>
					</section>
				</div>
			}
		</div>
	)
}

export default Funciones