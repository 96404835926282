import React, { useState } from 'react'
import consulta from '../../../../utils/consulta'
import FileUploader from '../../../../components/Uploader/FileUploader';
import Loading from '../../../../components/partials/Loading'
import ExcelButton from '../../../../components/partials/Excel/ExcelButton';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator'

function Ospoce() {
	const [selectedOspoceDecla, setSelectedOspoceDecla] = useState(null);
	const [selectedOspoceTransf, setSelectedOspoceTransf] = useState(null);

	const [loading, setLoading] = useState(false)
	const [Aportes, setAportes] = useState([]);
	const [columnas, setColumnas] = useState([]);
	const [Errores, setErrores] = useState("")

	const handleOspoceDeclaSelect = (files) => {
		setSelectedOspoceDecla(files[0]);
	};

	const handleOspoceTransfSelect = (files) => {
		setSelectedOspoceTransf(files[0]);
	};

	const volverAtras = () => {
		setLoading(true)
		setColumnas([])
		setAportes([])
		setSelectedOspoceDecla(null)
		setSelectedOspoceTransf(null)
		setLoading(false)
	}

	const handleUploadClick = async () => {
		try {
			setLoading(true)
			const formData = new FormData();
			if (selectedOspoceDecla) {
				formData.append('ospoceDecla', selectedOspoceDecla);
			}
			if (selectedOspoceTransf) {
				formData.append('ospoceTransf', selectedOspoceTransf);
			}

			settearColumnas()
			// Realiza la solicitud POST con axios (o fetch)
			const response = await consulta.post('/aportes/ospoce', formData);
			setAportes(response.data)
			setLoading(false)
		} catch (error) {
			// Maneja los errores si es necesario
			console.error('Error:', error);
			setErrores(error.message)
			setLoading(false)
		}
	};

	const settearColumnas = () => {
		let columnaAportes = [
			{ field: 'persona', headerName: 'Nombre Cliente', sortable: false, width: 260 },
			{ field: 'cuil', headerName: 'CUIL', width: 110 },
			{ field: 'cuit', headerName: 'CUIT', width: 110 },
			{ field: 'obra', headerName: 'Obra Social', width: 90 },
			{ field: 'empresa', headerName: 'Empresa', width: 260 },
			{ field: 'remDecla', headerName: 'Remuneración Declarada', width: 150 },
			{ field: 'remContrib', headerName: 'Remuneración Transferida', width: 150 },
			{ field: 'totalTransferidos', headerName: 'Total Transferidos', width: 150 },
			{ field: 'apTransf', headerName: 'Aportes Transferidos', width: 150 },
			{ field: 'contrTransf', headerName: 'Contribución Transferida', width: 150 },
			{ field: 'totalDeclarados', headerName: 'Total Declarados', width: 150 },
			{ field: 'apDecla', headerName: 'Aportes Declarados', width: 150 },
			{ field: 'contrDecla', headerName: 'Contribución Declarada', width: 150 },
		];
		setColumnas(columnaAportes)
	}

	if (loading) {
		return (
			<Loading texto={"Cargando Aportes"} />
		)
	}

	if (Aportes.length > 0) {
		return (
			<div className='ValorPlan'>
				<h2 className='columnas'>¡Aportes Cargados!</h2>
				{Aportes.length > 0 &&
					<TableGenerator columns={columnas} data={Aportes} />
				}
				<div className='botones'>
					<button className='button-back' onClick={volverAtras}>Atras</button>
					<ExcelButton data={Aportes} nombreHoja={"Ospoce"} nombreArchivo={`Aportes`} />
				</div>
			</div>
		)
	}
	
	return (
		<div className="Ospoce Obra-Social">
			<h2 className='titulo-seccion'>Carga de archivos Ospoce</h2>
			<article className='formularios-aportes'>
				<FileUploader label="SNC Reduc.xlsx" title={"Aportes Declarados"} onFileSelect={handleOspoceDeclaSelect} />
				<FileUploader label="Transferidos.xlsx" title={"Aportes Transferidos"} onFileSelect={handleOspoceTransfSelect} />
			</article>
			{Errores !== "" &&
			<p>{Errores}</p>}
			<div className='boton-de-carga'>
				<button className='submit-button' onClick={handleUploadClick}>Cargar Aportes</button>
			</div>
		</div>
	);
}

export default Ospoce