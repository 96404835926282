import React from 'react'
import '../Styles/descargaPagos.css'

function DescargarPagos() {
  return (
    <div className='DescargarPagos'>
        <section className='ejemplos-planilla'>
            <h2>Informacion Lotes</h2>
            <article className='planilla-descargas'>
            <div>
                <h3>Tarjeta Naranja</h3>
                <a href={`https://fiscalizacion.createch.com.ar/documentacion/naranja.rar`} download="naranja.rar" className='descargas-button' >Descargar</a>
            </div>
            <div>
                <h3>Master Credito</h3>
                <a href={`https://fiscalizacion.createch.com.ar/documentacion/master.rar`} download="master.rar" className='descargas-button' >Descargar</a>
            </div>
            <div>
                <h3>Visa Credito</h3>
                <a href={`https://fiscalizacion.createch.com.ar/documentacion/visaCred.rar`} download="visaCred.rar" className='descargas-button' >Descargar</a>
            </div>
            <div>
                <h3>Visa Debito</h3>
                <a href={`https://fiscalizacion.createch.com.ar/documentacion/visaDeb.rar`} download="visaDeb.rar" className='descargas-button'>Descargar</a>
            </div>
            <div>
                <h3>CBU</h3>
                <a href={`https://fiscalizacion.createch.com.ar/documentacion/CBU.rar`} download="visaDeb.rar" className='descargas-button'>Descargar</a>
            </div>
            </article>
        </section>
    </div>
  )
}

export default DescargarPagos