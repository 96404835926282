import React, { useState } from 'react';

const FileUploader = ({ label,title, onFileSelect }) => {
	const [selectedFiles, setSelectedFiles] = useState([]);

	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);
		setSelectedFiles(files);
		onFileSelect(files);
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const files = Array.from(event.dataTransfer.files);
		setSelectedFiles(files);
		onFileSelect(files);
	};

	return (
		<div className="file-uploader">
			<h3 className='subtitulo-seccion'>{title}</h3>
			<label className="file-label" htmlFor={label}>
				<div className="drop-area" onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
					<p className="drop-message">Haz Click o Arrastra el archivo aquí</p>
					{label}
				</div>
			</label>
				<input
					type="file"
					name={label}
					id={label}
					className="file-input"
					onChange={handleFileChange}
				/>
			{selectedFiles.length > 0 && (
				<div className="selected-files">
					{selectedFiles.map((file, index) => (
						<div key={index} className="selected-file">
							{file.name}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default FileUploader;

