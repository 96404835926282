import React from 'react'
import logoAndes from '../../../../assets/Logo Central.png'
import '../Styles/clienteVista.css'

function ClienteVista({cliente}) {


    let  obtenerProvincia = (numero) => {
        let provincias = [
            { nombre: 'guion', numero: 26 },
            { nombre: 'CABA', numero: 1 },
            { nombre: 'BUENOS AIRES', numero: 2 },
            { nombre: 'CATAMARCA', numero: 3 },
            { nombre: 'CHACO', numero: 4 },
            { nombre: 'CHUBUT', numero: 5 },
            { nombre: 'CORDOBA', numero: 6 },
            { nombre: 'CORRIENTES', numero: 7 },
            { nombre: 'ENTRE RIOS', numero: 8 },
            { nombre: 'FORMOSA', numero: 9 },
            { nombre: 'JUJUY', numero: 10 },
            { nombre: 'LA PAMPA', numero: 11 },
            { nombre: 'LA RIOJA', numero: 12 },
            { nombre: 'MENDOZA', numero: 13 },
            { nombre: 'MISIONES', numero: 14 },
            { nombre: 'NEUQUEN', numero: 15 },
            { nombre: 'RIO NEGRO', numero: 16 },
            { nombre: 'SALTA', numero: 17 },
            { nombre: 'SAN JUAN', numero: 18 },
            { nombre: 'SAN LUIS', numero: 19 },
            { nombre: 'SANTA CRUZ', numero: 20 },
            { nombre: 'SANTA FE', numero: 21 },
            { nombre: 'SANTIAGO DEL ESTERO', numero: 22 },
            { nombre: 'TIERRA DEL FUEGO', numero: 23 },
            { nombre: 'TUCUMAN', numero: 24 },
            { nombre: 'Otro', numero: 25 },
          ]
        numero = +numero
        const provincia = provincias.find(p => p.numero === numero);
        return provincia ? provincia.nombre : 'Provincia no encontrada';
    }
    let  obtenerCondicionFrenteAIVA = (valor) => {
        let condiciones = [
            { value : "CF", tipo : "Consumidor Final"},
            { value : "RI", tipo : "Responsable Inscripto"},
            { value : "M", tipo : "Monotributo"},
            { value : "E", tipo : "Excento"},
            { value : "CDEX", tipo : "Cliente Del Exterior"},
            { value : "IVNA", tipo : "IVA No Alcanzado"},
        ]
        const condicion = condiciones.find(CI => CI.value === valor);
        return condicion ? condicion.tipo : 'Condicion no seleccionada';
    }
    let  obtenerCondicionVenta = (valor) => {
        let condiciones = [
            { value:"213" ,cantidad: "5 dias "},
            { value:"206" ,cantidad: "10 dias "},
            { value:"207" ,cantidad: "15 dias "},
            { value:"209" ,cantidad: "20 dias "},
            { value:"202" ,cantidad: "30 dias "},
            { value:"208" ,cantidad: "45 dias "},
            { value:"203" ,cantidad: "60 dias "},
            { value:"204" ,cantidad: "90 dias "},
            { value:"201" ,cantidad: "Contado "},
            { value:"205" ,cantidad: "Cuenta Corriente "},
            { value:"210" ,cantidad: "Transferencia Bancaria "},
            { value:"211" ,cantidad: "Tarjeta De Credito "},
            { value:"212" ,cantidad: "Tarjeta De Debito "},
            { value:"214" ,cantidad: "Otra "},
        ]
        const condicion = condiciones.find(CI => CI.value === valor);
        return condicion ? condicion.cantidad : 'Condicion no seleccionada';
    }

  return (
    <div className='Cliente-Vista'>
        <section className='Bloque'>
            <div className='Bloque-Superior'> 
                <div className='Primer-Bloque'>
                    <div className='bloque-logo'>
                        <img src={logoAndes} alt="Logo Central Andes" />
                    </div>
                    <div className="informacion-cabecera">
                        <p>Razón social</p>
                        <h5>ANDESALUD S.A.</h5>
                        <p>Domicilio</p>
                        <h5>Colon 136. - Mendoza, Mendoza</h5>
                        <p>Condicion frente al IVA</p>
                        <h5>Responsable Inscripto</h5>
                    </div>
                </div>
                <div className="Segundo-Bloque">
                    <div className='Tipo-Bloque'>
                        <h2>B</h2>
                        <span>COD.6</span>
                    </div>
                    <div className='Tipo-Bloque2'>
                        <h4>Factura B</h4>
                        <h2>Nro.:00004-0000000000</h2>
                        <span>Fecha: 03/03/2023</span>
                    </div>
                </div>
            </div>
            <div className="Bloque-Medio">
                <h5 className='bloque-medio-titular'>fiscalizacion@andessalu.ar // WhatsApp +54 261 330-0622</h5>
                <div className='info-bloque-medio'>
                    <div className='mas-informacion'>
                        <h5>CUIT:</h5>
                        <p>30713784407</p>
                    </div>
                    <div className='mas-informacion'>
                        <h5>Ingresos Brutos:</h5>
                        <p>0686413</p>
                    </div>
                    <div className='mas-informacion'>
                        <h5>Inicio actividades:</h5>
                        <p>01/03/2013</p>
                    </div>
                </div>
            </div>
            <div className="Bloque-Final">
                <div className='ultra-info'>
                    <h4>Apellido y nombre / Razón Social:</h4>
                    <p>{cliente.razon_social}</p>
                </div>
                <div className='ultra-info'>
                    <h4>Domicilio</h4>
                    <p>{cliente.domicilio} P:{obtenerProvincia(cliente.provincia)}</p>
                </div>
                <div className='ultra-info'>
                    <h4>{cliente.documento_tipo}: </h4>
                    <p>{cliente.documento_nro}</p>
                    <h4>Condición frente a IVA: </h4>
                    <p>{obtenerCondicionFrenteAIVA(cliente.condicionIva)}</p>
                    <h4>Cond. Venta: </h4>
                    <p>{obtenerCondicionVenta(cliente.condicion_pago)} (vencimiento: 03/03/2023)</p>
                </div>
                <div className='ultra-info'>
                    <h4>Período facturado: </h4>
                    <p>01/03/2023 al 31/03/2023</p>
                    <h4>Divisa: </h4>
                    <p>Pesos Argentinos - Tipo de Cambio: 1,00</p>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ClienteVista