import React from 'react';
import '../styles/TablaAportes.css';

const TablaAportes = ({ aportes }) => {
    const columns = Object.keys(aportes[0]);

    return (
        <article className='Valores-Informados'>
            <div className="table-list">
                <div className="table-header">
                    <div className="table-row">
                        {columns.map((column, index) => (
                            <div
                                className="table-header-cell"
                                key={index}
                            >
                                {column}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="table-body">
                    {aportes.map( (item) => (
                        <div className="table-row" key={item.id}>
                            {columns.map((column, columnIndex) => (
                                <div key={columnIndex} className={`table-cell ${column}`} >
                                    {item[column]}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </article>
    );
};

export default TablaAportes;
