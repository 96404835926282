import React, { useState } from 'react'
import Swal from "sweetalert2";
import { fechaMes, parsearFecha, pasarNumeroAMoneda } from '../../../../utils/funciones';
import consulta from '../../../../utils/consulta';

function FacturaManual({ facturas, cuil }) {

    const [periodo, setperiodo] = useState(fechaMes())
    const [monto, setMonto] = useState("")

    const cambiarMes = (e) => {
        let mes = e.target.value;
        let nuevoPeriodo = String(periodo)
        let year = nuevoPeriodo.substring(0, 4)
        setperiodo(`${year}${mes}`)
    }
    const cambiaryear = (e) => {
        console.log(e.target.value);
        let year = e.target.value
        let nuevoPeriodo = String(periodo)
        let mes = nuevoPeriodo.substring(4, 6)
        setperiodo(`${year}${mes}`)
    }
    const enviarDatos = async (e) => {
        try {
            e.preventDefault()
            const result = await Swal.fire({
                title: "¿Estas seguro que deseas crear esta factura?",
                text: `Titular: ${facturas[0].sujetoFacturable}, Periodo: ${parsearFecha(periodo)}, Monto: ${pasarNumeroAMoneda(monto)}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Crear",
                cancelButtonText: "Cancelar"
            })

            if (result.isConfirmed) {
                const urlEnviar = `/facturacion/api/manual?titular=${cuil}&monto=${monto}&periodo=${periodo}`
                const response = await consulta.post(urlEnviar)
                console.log("enviando -->", response);
                Swal.fire({
                    position: "top-end",
                    title: response.meta.msg,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1000,
                });
            }

        } catch (error) {
            console.log(error);
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Error con la factura",
                showConfirmButton: false,
                timer: 1000,
            });
        }
    }

    return (
        <section className='factura-manual'>
            <form onSubmit={enviarDatos}>
                <div className="formData">
                    <label htmlFor="monto">Monto</label>
                    <input
                        type="text"
                        name="monto"
                        id="monto"
                        onChange={(e) => setMonto(e.target.value)}
                        value={monto}
                        placeholder='Ingrese el valor de la factura'
                    />
                </div>
                <div className='formData'>
                    <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                    <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                        <option defaultValue="" hidden>Puede modificar el año</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2025">2026</option>
                        <option value="2025">2027</option>
                        <option value="2025">2028</option>
                    </select>
                </div>
                <div className='formData'>
                    <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                    <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                        <option defaultValue="" hidden>Puede modificar el mes</option>
                        <option value="01">Enero</option>
                        <option value="02">Febrero</option>
                        <option value="03">Marzo</option>
                        <option value="04">Abril</option>
                        <option value="05">Mayo</option>
                        <option value="06">Junio</option>
                        <option value="07">Julio</option>
                        <option value="08">Agosto</option>
                        <option value="09">Septiembre</option>
                        <option value="10">Octubre</option>
                        <option value="11">Noviembre</option>
                        <option value="12">Diciembre</option>
                    </select>
                </div>
                <div className='periodo-seleccionado'>
                    <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                </div>
                <div className='buttons'>
                    <button type="submit" className='submit-button'>Crear</button>
                </div>
            </form>
        </section>
    )
}

export default FacturaManual