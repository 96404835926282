import React, { useState } from 'react';
import axios from 'axios';
import Cliente from './Componentes/Cliente';
import ClienteVista from './Componentes/ClienteVista';
import Detalle from './Componentes/Detalle';
import DetalleVista from './Componentes/DetalleVista';
import Comprobante from './Componentes/Comprobante'
import ComprobanteVista from './Componentes/ComprobanteVista'
import Swal from 'sweetalert2';

import './Styles/facturacionManual.css'

function FacturaManual() {

    const [respuesta, setRespuesta] = useState([])
    const [contador, setContador] = useState(1)
    /* Elementos */
    const [cliente, setCliente] = useState({
        documento_tipo: "",
        documento_nro: "",
        condicionIva: "",
        razon_social: "",
        provincia: "",
        email: "",
        condicion_pago: "",
        domicilio: "",
        condicion_pago_otra: ""
    })
    const [detalles, setDetalles] = useState({
        cantidad: "",
        descripcion: "",
        leyenda: "",
        unidad_bulto: 0,
        lista_precios: "",
        precio_unitario_sin_iva: 0,
        alicuota: 0,
        impuestos_internos_alicuota: 0,
        bonificacion_porcentaje: 0,
        unidad_medida: 7,
    })
    const [comprobante, setComprobante] = useState({
        fecha: "",
        tipo: "FACTURA B",
        operacion: "V",
        idioma: 1,
        moneda: "PES",
        cotizacion: "",
        periodo_facturado_desde: "01/01/2023",
        periodo_facturado_hasta: "01/01/2023",
        rubro: "servicios",
        rubro_grupo_contable: "servicios",
        punto_venta: 4,
        bonificacion: 0,
        leyenda_gral: "",
        comentario: "",
        percepciones_iibb: 0,
        percepciones_iibb_base: 0,
        percepciones_iibb_alicuota: 0,
        percepciones_iva: 0,
        percepciones_iva_base: 0,
        percepciones_iva_alicuota: 0,
        exentos: 0,
        impuestos_internos: 0,
        impuestos_internos_base: 0,
        impuestos_internos_alicuota: 0,
        total: 0,
        external_reference: '',

    });

    /* Transicionar entre paginas */
    let aumentarNumero = () => {
        if (contador > 0) {
            setContador(contador + 1)
        }
    }
    let disminuirNumero = () => {
        if (contador > 0) {
            setContador(contador - 1)
        }
    }
    const handleReset = () => {
        setContador(1);
        setRespuesta([]);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        setContador(contador + 1)
        let obj = {
            clienteFinal: cliente,
            comprobanteFinal: comprobante,
            detalleFinal: detalles
        }
        await Swal.fire({
            title: '¿Esta seguro de generar la Factura?',
            showDenyButton: true,
            confirmButtonText: 'Crear',
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let url = 'https://andessalud.createch.com.ar/api/Manual'
                let response = await axios.post(url, obj)
                if (response.status === 200) {
                    Swal.fire('Se envio la factura \nal Email y Whatsapp \ndel usuario!', '', 'success')
                    setRespuesta(response.data.data)
                    console.log(response);
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Error al crear la factura " + response.status,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            } else if (result.isDenied) {
                Swal.fire('No se genero la factura', '', 'info')
            }
        })

    };

    return (
        <main className='Facturacion-Manual'>
            {
                (contador > 1 && contador !== 5) &&
                <button className='Volver-Atras' onClick={disminuirNumero}>
                    <i className="fas fa-long-arrow-alt-left"></i>
                </button>
            }
            <h2>Facturacion Manual</h2>
            {
                contador !== 5 &&
                <button className='Tipo-Factura'>Nota de Credito</button>
            }

            <form>
                {
                    contador === 1 &&
                    <section className='Facturacion-Cliente'>
                        <h3>Cliente</h3>
                        <Cliente
                            cliente={cliente}
                            setCliente={setCliente}
                        />

                        <div className='boton' onClick={aumentarNumero}>Continuar</div>

                        <ClienteVista
                            cliente={cliente}
                        />
                    </section>
                }
                {
                    contador === 2 &&
                    <section className='Facturacion-Detalle'>
                        <h3>Detalle</h3>

                        <Detalle
                            detalles={detalles}
                            setDetalles={setDetalles}
                        />

                        <div className='boton' onClick={aumentarNumero}>Continuar</div>

                        <DetalleVista
                            detalles={detalles}
                        />
                    </section>
                }
                {
                    contador === 3 &&
                    <section className='Facturacion-Detalle'>
                        <h3>Detalle</h3>

                        <Comprobante
                            comprobante={comprobante}
                            setComprobante={setComprobante}
                        />

                        <div className='boton' onClick={aumentarNumero}>Continuar</div>


                        <ComprobanteVista
                            comprobante={comprobante}
                        />
                    </section>
                }
                {
                    contador === 4 &&
                    <section className='Facturacion-Detalle'>
                        <h3 className='comprobante-final'>Comprobante Final</h3>

                        <ClienteVista
                            cliente={cliente}
                        />
                        <DetalleVista
                            detalles={detalles}
                        />
                        <ComprobanteVista
                            comprobante={comprobante}
                        />

                        <div onClick={handleSubmit} className='Enviar-Factura'>Enviar Factura</div>
                    </section>
                }
                {
                    contador === 5 &&
                    <section className='Respuesta-axios'>
                        <h3 className='comprobante-final'>Respuesta</h3>
                        <div className="Bloque-errores">
                            {respuesta.error === "S" && respuesta.error_details.map((error, index) =>
                                <div key={index} >
                                    <h5 className='error-texto'>{error.text}</h5>
                                </div>
                            )}
                        </div>
                        <div onClick={handleSubmit} className='Enviar-Factura'>Crear otra factura</div>
                    </section>
                }

            </form>
        </main>
    )
}

export default FacturaManual