import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Monotributos from './Componentes/Monotributos';
import EditarLista from './Componentes/EditarLista';

import './Styles/listasCotizador.css'

function ListasCotizador() {

    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [editarLista, setEditarLista] = useState(0);
    const [tablaValores, setTablaValores] = useState(null);
    const [datos, setDatos] = useState({
        categoriaA: 0,
        categoriaD: 0,
        categoriaE: 0,
        categoriaF: 0,
        categoriaG: 0,
        categoriaH: 0,
        categoriaI: 0,
        categoriaJ: 0,
        categoriaK: 0,
        ultimaActualizacion: ""
    })
    const [values, setValues] = useState({
        porcentajeAumento: 0,
        porcentajeDescuento: 0
    })
    let handleChange = (evt) => {
        const { target } = evt;
        const { name, value } = target;

        const newValues = {
            ...values,
            [name]: value,
        };
        setValues(newValues);
    }
    useEffect(() => {
        setIsLoading(true)
        axios.get("https://cotizador.createch.com.ar/listas")
            .then(response => {
                setTablaValores(response.data.data);
                let urlMonotributo = 'https://cotizador.createch.com.ar/monotributos'
                axios.post(urlMonotributo)
                    .then(monotributo => {
                        const objActualizado = monotributo.data.data.reduce((acc, dato) => {
                            switch (dato.categoria) {
                                case "A,B y C":
                                    acc.categoriaA = dato.valor;
                                    break;
                                case "D":
                                    acc.categoriaD = dato.valor;
                                    break;
                                case "E":
                                    acc.categoriaE = dato.valor;
                                    break;
                                case "F":
                                    acc.categoriaF = dato.valor;
                                    break;
                                case "G":
                                    acc.categoriaG = dato.valor;
                                    break;
                                case "H":
                                    acc.categoriaH = dato.valor;
                                    break;
                                case "I":
                                    acc.categoriaI = dato.valor;
                                    break;
                                case "J":
                                    acc.categoriaJ = dato.valor;
                                    break;
                                case "K":
                                    acc.categoriaK = dato.valor;
                                    break;
                                default :
                                    acc.categoriaA = dato.valor
                                break
                            }
                            if (new Date(dato.updatedAt) > new Date(acc.ultimaActualizacion)) {
                                acc.ultimaActualizacion = dato.updatedAt;
                            }
                            return acc;
                        }, {
                            categoriaA: 0,
                            categoriaD: 0,
                            categoriaE: 0,
                            categoriaF: 0,
                            categoriaG: 0,
                            categoriaH: 0,
                            categoriaI: 0,
                            categoriaJ: 0,
                            categoriaK: 0,
                            ultimaActualizacion: "2000-01-01T00:00:00.000Z",
                        });
                        setDatos(objActualizado);
                        setIsLoading(false);
                    })
            })
    }, []);
    let handleDiscount = (e) => {
        e.preventDefault()
    }
    let handleIncrease = (e) => {
        e.preventDefault()
    }
    const handleMonotributo = (e) => {
        e.preventDefault()
        if (open) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }
    const handleEditarLista = (valor) => {
        if (editarLista === valor) {
            setEditarLista(0)
        } else {
            setEditarLista(valor)
        }
    }

    if (isLoading) {
        return (
            <h1>Cargando</h1>
        )
    }
    return (
        <main className='listas-precios'>
            <div className='setDescuento'>
                <div className='descuentoAplicado'>
                    <h3>Aplicar Descuento</h3>

                    <form>
                        <input type="number" name='porcentajeDescuento' id='porcentajeDescuento' onChange={handleChange} defaultValue={values.porcentajeDescuento} />
                        <label htmlFor="porcentajeDescuento">%</label>
                        <button type="submit" onClick={handleDiscount}>Aplicar</button>
                    </form>
                </div>
                <div className='BotonMonotributo'>
                    <button onClick={handleMonotributo}>Valores Monotributo</button>
                </div>
                <div className='descuentoAplicado'>
                    <h3>Aplicar Aumentos</h3>
                    <form>
                        <input type="number" name='porcentajeDescuento' id='porcentajeDescuento' onChange={handleChange} defaultValue={values.porcentajeDescuento} />
                        <label htmlFor="porcentajeDescuento">%</label>
                        <button type="submit" onClick={handleIncrease}>Aplicar</button>
                    </form>
                </div>

            </div>

            {open &&
                <Monotributos
                    datos={datos}
                    setDatos={setDatos}
                    open={setOpen}
                    setOpen={setOpen}
                />}

            <div className='table-cotizator'>
                <table role="table" className='table-block'>
                    <thead>
                        <tr role="row">
                            <th role="columnheader">Plan</th>
                            <th role="columnheader">Condicion</th>
                            <th role="columnheader">Edad 0-17</th>
                            <th role="columnheader">Edad 18-25</th>
                            <th role="columnheader">Edad 26-35</th>
                            <th role="columnheader">Edad 36-44</th>
                            <th role="columnheader">Edad 45-50</th>
                            <th role="columnheader">Edad 51-55</th>
                            <th role="columnheader">Funcion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tablaValores.map((tabla, index) =>
                            <tr role="row">
                                <td className='tabla-row-plan'>- {tabla.tipoPlan}</td>
                                <td className='tabla-row-plan'>{tabla.condicion}</td>
                                <td>{tabla.edad0017 === 0 ? "-" : `$${tabla.edad0017}`}</td>
                                <td>{tabla.edad1825 === 0 ? "-" : `$${tabla.edad1825}`}</td>
                                <td>{tabla.edad2635 === 0 ? "-" : `$${tabla.edad2635}`}</td>
                                <td>{tabla.edad3644 === 0 ? "-" : `$${tabla.edad3644}`}</td>
                                <td>{tabla.edad4550 === 0 ? "-" : `$${tabla.edad4550}`}</td>
                                <td>{tabla.edad5155 === 0 ? "-" : `$${tabla.edad5155}`}</td>
                                <td>
                                    {editarLista === (index + 1) && <EditarLista
                                        lista={tabla}
                                        editarLista={editarLista}
                                        setEditarLista={setEditarLista}
                                        index={(index + 1)}
                                    />}
                                    <button onClick={(e) => handleEditarLista(index + 1)}>Modificar</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </main >
    )
}

export default ListasCotizador