import React, { useState } from 'react';

/* Componentes */
import Visa from './Componentes/Visa';
import Master from './Componentes/Master';
import Naranja from './Componentes/Naranja';
import Deuda from './Componentes/Deuda';
import DescargarPagos from './Componentes/DescargarPagos';

function CargaLotes() {
    const options = ['VISA', 'MASTER', 'NARANJA','TRANSFERENCIAS'];
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    let componentToRender = <Visa />;

    switch (selectedOption) {
        case 'VISA':
            componentToRender = <Visa />;
            break;
        case 'MASTER':
            componentToRender = <Master />;
            break;
        case 'NARANJA':
            componentToRender = <Naranja />;
            break;
        case 'TRANSFERENCIAS':
            componentToRender = <Deuda />;
            break;
        default:
            componentToRender = <Visa />;
            break
    }
    
    return (
        <div className="CargaAportes">
            <div className='carga-header'>
                {options.map((option, index) => (
                    <button
                        key={index}
                        className={`option-button ${selectedOption === option ? 'selected' : 'VISA'}`}
                        onClick={() => handleOptionClick(option)}
                    >
                        {option}
                    </button>
                ))}
            </div>
            <div className="componenteMistico">
                {componentToRender}
            </div>
            <DescargarPagos />
        </div>
    )
}

export default CargaLotes