import axios from 'axios';
import apiConfig from "./apiConfig"

const axiosInstance = axios.create({
    maxContentLength: 200000000, // Tamaño máximo de contenido en bytes (200 MB)
});
const baseUrl = `${apiConfig.fiscalizacionUrl}`
const storedToken = localStorage.getItem('authToken'); //Traemos el token de localstorage
const authToken = `Bearer ${storedToken}` //Hacemos consultas con el token obtenido

const apiUtils = {
    async get(url, headers = {}) {
        try {
            const modifiedHeaders = {
                ...headers,
                Authorization: authToken //En todo momento si tenemos token lo enviamos!
            };
            const response = await axiosInstance.get(`${baseUrl}${url}`, { headers: modifiedHeaders });
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },

    async post(url, data, headers = {}) {
        try {
            const modifiedHeaders = {
                ...headers,
                Authorization: authToken
            };
            const response = await axiosInstance.post(`${baseUrl}${url}`, data, { headers: modifiedHeaders });
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },

    async put(url, data, headers = {}) {
        try {
            const modifiedHeaders = {
                ...headers,
                Authorization: authToken
            };
            const response = await axiosInstance.put(`${baseUrl}${url}`, data, { headers: modifiedHeaders });
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },

    async delete(url, data, headers = {}) {
        try {
            const modifiedHeaders = {
                ...headers,
                Authorization: authToken
            };
            const response = await axiosInstance.delete(`${baseUrl}${url}`, data, { headers: modifiedHeaders });
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    },
};

export default apiUtils;