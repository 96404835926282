import React, { useState } from 'react'
import EdicionModal from './EdicionModal';

function SelectList({ contentEl, active, planes, titulo }) {

    const [editarLista, setEditarLista] = useState(0);

    const handleEditarLista = (valor) => {
        if (editarLista === valor) {
            setEditarLista(0)
        } else {
            setEditarLista(valor)
        }
    }

    return (
        <div
            ref={contentEl}
            className="answer_wrapper"
            style={
                active
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }
        >
            <div className='table-list'>
                <table role="table" className='table-block'>
                    <thead>
                        <tr role="row">
                            <th role="columnheader">Plan</th>
                            <th role="columnheader">Edad 0-17</th>
                            <th role="columnheader">Edad 18-25</th>
                            <th role="columnheader">Edad 26-35</th>
                            <th role="columnheader">Edad 36-44</th>
                            <th role="columnheader">Edad 45-50</th>
                            <th role="columnheader">Edad 51-55</th>
                            <th role="columnheader">Edad 56-60</th>
                            <th role="columnheader">Funcion</th>
                        </tr>
                    </thead>
                    <tbody>
                        {planes.map((plan, index) =>
                            <tr role="row" key={`plan-${index}`}>
                                <td className='tabla-row-plan'>- {plan.plan}</td>
                                <td>${plan.edad0017}</td>
                                <td>${plan.edad1825}</td>
                                <td>${plan.edad2635}</td>
                                <td>${plan.edad3644}</td>
                                <td>${plan.edad4550}</td>
                                <td>${plan.edad5155}</td>
                                <td>${plan.edad5660}</td>
                                <td>
                                    {editarLista === (index + 1) && <EdicionModal
                                        lista={plan}
                                        titulo={titulo}
                                        editarLista={editarLista}
                                        setEditarLista={setEditarLista}
                                        index={(index + 1)}
                                    />}
                                    <button onClick={(e) => (handleEditarLista(index + 1))}>Editar</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>

    )
}

export default SelectList