import React, { useEffect, useState } from 'react'
import Loading from '../../../components/partials/Loading'
import consulta from '../../../utils/consulta'
import './Styles/saldos.css'

import Tabla from './Componentes/Tabla'
import Tarjetas from './Componentes/Tarjetas'
import HeaderResumen from './Componentes/HeaderResumen'

function Saldos() {
	const [cuil, setCuil] = useState(0)
	const [isLoading, setIsLoading] = useState(false)
	const [saldos, setsaldos] = useState([])
	const [existe, setExiste] = useState(false)
	const [toggleFormat, setToggleFormat] = useState("list");
	console.log("Saldoos -->",saldos);

	const BuscarSaldoContrato = async (e) => {
		try {
			e.preventDefault()
			if (cuil.length === 11) {
				setIsLoading(true)
				let urlAporteUtilizado = `/informacion/api/saldos/individual?titular=${cuil}`
				let response = await consulta.get(urlAporteUtilizado)
				setsaldos(response.data)
				setIsLoading(false)
			} else {
				setExiste("Error con los datos")
			}
		} catch (error) {
			console.log(error)
			setExiste("Error con los datos:" + error.message)
			setIsLoading(false)
		}
	}

	const volverAtras = (e) => {
		e.preventDefault()
		setCuil('')
		setsaldos([])
		setExiste(false)
	}

	if (isLoading) {
		return (
			<Loading texto={"Cargando Saldos"} />
		)
	}

	if (saldos.length > 0) {
		return (
			<div className='ValorPlan'>
				
				<HeaderResumen toggleFormat={toggleFormat} setToggleFormat={setToggleFormat} />

				{toggleFormat === "list" ? 
					<Tabla saldos={saldos} cuil={cuil} volverAtras={volverAtras} BuscarSaldoContrato={BuscarSaldoContrato}/>
					:
					<Tarjetas saldos={saldos} cuil={cuil} volverAtras={volverAtras}  BuscarSaldoContrato={BuscarSaldoContrato}/>
				}
			</div>
		)
	}

	return (
		<div className='ValorPlan'>
			<section className='seccion-busqueda'>
				<form onSubmit={BuscarSaldoContrato}>
					<h2>Busqueda de los Saldos de un CUIL en especifico</h2>
					<div className='Busqueda'>
						<input
							id='search'
							name='search'
							className='form-control inputBuscar'
							onChange={(e) => setCuil(e.target.value)}
							placeholder="Ingresá un Cuil"
							type="text" />
						{existe &&
							<h4 className='ValorPlans-inexistentes'>{existe}</h4>
						}
						<div className='botones'>
							<button className='valor-Plan-Individual-button' type="submit">Buscar Saldos</button>
						</div>
					</div>
				</form>
			</section>
		</div>
	);
}


export default Saldos