import React from 'react'

function Tr({ lista, padron }) {
	let valoresPorEdad = (edad, estado) => {
		if (estado === 'BAJA') {
			return 0
		}
		return edad < 18 ? lista.edad0017 : edad >= 18 && edad <= 25 ? lista.edad1825 : edad >= 26 && edad <= 35 ? lista.edad2635 : edad >= 36 && edad <= 44 ? lista.edad3644 : edad >= 45 && edad <= 50 ? lista.edad4550 : edad >= 51 && edad <= 55 ? lista.edad5155 : edad >= 56 && edad <= 60 ? lista.edad5660 : edad >= 61 && edad <= 65 ? lista.edad6165 : edad >= 66 && edad <= 70 ? lista.edad6670 : lista.edad7100
	}

	return (
		<tr role="row">
			<td>{padron.CUILAfiliado}</td>
			<td>{padron.apellnombAfilado}</td>
			<td>{padron.parentesco}</td>
			<td>{padron.estado}</td>
			<td>{padron.tipoEstado}</td>
			<td>{padron.tipoConsumidor}</td>
			<td>{padron.edad}</td>
			<td>{valoresPorEdad(padron.edad, padron.estado) > 0 ? `$${valoresPorEdad(padron.edad, padron.estado)}` : '-'}</td>
		</tr>
	)
}

export default Tr