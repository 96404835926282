import React, { useState } from 'react'
import consulta from '../../../utils/consulta'
import { parsearFecha } from '../../../utils/funciones'
import Swal from 'sweetalert2';

function Pasarela({ saldoSeleccionado, setSaldoSeleccionado, nombre, calcularTotalDeuda, volverAtras }) {

    let pagoManualurl = '/facturacion/api/pagos/manual'
    const [transferencia, setTransferencia] = useState(false);
    const [text, setText] = useState('');
    const [medioPago, setMedioPago] = useState('');
    const [comprobante, setComprobante] = useState('');
    const maxCharacters = 500;

    const handleSubmit = async (e) => {
        await Swal.fire({
            title: 'Pagando la deuda de \n#' + nombre + '\n Por valor de:\n$' + calcularTotalDeuda(saldoSeleccionado),
            showCancelButton: true,
            confirmButtonText: 'Pagar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = {
                    comentarios: text,
                    medioPago: medioPago,
                    comprobante: comprobante,
                    saldos: saldoSeleccionado
                }
                let response = await consulta.post(pagoManualurl, data)
                if (response.status === 200) {
                    Swal.fire('Pagada!', '', 'success')
                    volverAtras()
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Falta info " + response.status,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }
        })
    }
    const seleccionarMedio = (e) => {
        const newText = e.target.value;
        console.log(newText);
        if (newText === "Efectivo") {
            setMedioPago("Efectivo")
            setTransferencia(false)
        } else {
            setTransferencia(true)
        }
    }
    const handleChange = (event) => {
        const newText = event.target.value;

        if (newText.length <= maxCharacters) {
            setText(newText);
        }
    };
    const quitarElemento = async (e, saldo) => {
        e.preventDefault()
        let datos = saldoSeleccionado.filter((s) => s !== saldo)
        setSaldoSeleccionado(datos);
    }

    return (
        <div className="Pasarela">
            <h1 className="projTitle">Titular: <span>{nombre}</span></h1>
            <h3 className="projSubTitle">Cantidad a pagar: <span>{saldoSeleccionado.length} {saldoSeleccionado.length > 1 ? "saldos" : "saldo"}</span></h3>
            <div className="Carrito">
                <ul className="lista-elementos">
                    {saldoSeleccionado.map((elemento, index) =>
                        <li className="datos-saldos" key={elemento.id}>
                            <div className="infoWrap">
                                <div className="puntitomistico">
                                    <div className="contenedor-megapunto"></div>
                                </div>
                                <div className="informacion-saldo">
                                    <p className="itemNumber">#{elemento.id}_{elemento.idUnico.substring(0, 7)}</p>
                                    <h3>Saldo de {parsearFecha(elemento.periodo)}</h3>
                                    <p className="stockStatus">En Deuda</p>
                                </div>
                                <div className="flechita">
                                    <div className="contenedor-rectangulo"></div>
                                    <div className="contenedor-triangulo"></div>
                                </div>
                                <div className="valor-total">
                                    <p>${elemento.valor}</p>
                                </div>
                                <div className="eliminar-elemento responsive" onClick={(e) => quitarElemento(e, elemento)}>
                                    <button href="#" className="remove">x</button>
                                </div>
                                <div className="eliminar-elemento bigData" onClick={(e) => quitarElemento(e, elemento)}>
                                    <button href="#" className="remove">Eliminar</button>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
            <div className="subtotal">
                <div className='derecha'>
                    <div className="formData">
                        <label htmlFor="medio">Medio de Pago</label>
                        <select name="medio" id="medio" onChange={seleccionarMedio}>
                            <option defaultValue="" hidden>Seleccione una opcion</option>
                            <option value="Efectivo">Efectivo</option>
                            <option value="Transferencia">Transferencia</option>
                        </select>
                    </div>
                    {transferencia && <div className="formData">
                        <label htmlFor="medio">Tipo de Transferencia</label>
                        <select name="medio" id="medio" onChange={(e) => setMedioPago(e.target.value)}>
                            <option defaultValue="" hidden>Seleccione una opcion</option>
                            <option value="Transferencia CBU">CBU</option>
                            <option value="Transferencia CVU">CVU</option>
                        </select>
                    </div>}
                    <div className="formData">
                        <label htmlFor="orden">Nro Comprobante</label>
                        <input type="text" name="orden" id="orden" onChange={(e) => setComprobante(e.target.value)} />
                    </div>
                    <h3 className="totalRow final">
                        <span className="label">Total</span> <span className='valor'>${calcularTotalDeuda(saldoSeleccionado)}</span>
                    </h3>
                    <button className="btn continue active" onClick={handleSubmit}>Pagar</button>
                </div>
                <div className='comentario'>
                    <div className="formData">
                        <label htmlFor="comentario">Comentarios</label>
                        <textarea name="comentario" id="comentario" cols="30" rows="8" onChange={handleChange}></textarea>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pasarela