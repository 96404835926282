import React from 'react'
import { parsearFecha } from '../../../utils/funciones'

function CargaSaldos({
    calcularTotalDeuda,
    saldos,
    nombre,
    Gf,
    setpage,
    saldoSeleccionado,
    setSaldoSeleccionado,
    seleccionarTodo,
    setSeleccionarTodo,
    deuda,
    total,
    setTotal,
    volverAtras,
    cuil
}) {

    const handleSaldoSeleccionadoChange = (e, saldo) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            let datos = [...saldoSeleccionado, saldo]
            setTotal(calcularTotalDeuda(datos))
            setSaldoSeleccionado(datos);
        } else {
            let datos = saldoSeleccionado.filter((s) => s !== saldo)
            setTotal(calcularTotalDeuda(datos))
            setSaldoSeleccionado(datos);
        }
    };

    const handleSeleccionarTodoChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setTotal(calcularTotalDeuda([...saldos]))
            setSaldoSeleccionado([...saldos]);
        } else {
            setTotal(0)
            setSaldoSeleccionado([]);
        }
        setSeleccionarTodo(isChecked);
    };

    const handlePagar = (e) => {
        e.preventDefault()
        if (saldoSeleccionado.length > 0) {
            setpage("pasarela")
        }
    }

    return (
        <section className='SaldoParcial'>
            <button className='volver-atras' onClick={volverAtras}>Atras</button>
            <h2>Saldos Parciales: {cuil}<br />{nombre}</h2>
            <ul className='datos'>
                <li className='header-saldo-individual'>
                    <div className='datavalue'>
                        <input type="checkbox"
                            name="all"
                            id="all"
                            checked={seleccionarTodo}
                            onChange={handleSeleccionarTodoChange}
                        />
                        <label htmlFor="all">Seleccionar</label>
                    </div>
                    <h4>Periodo</h4>
                    <h4>Tipo Cobranza</h4>
                    <h4>Valor</h4>
                </li>
                {saldos.map((saldo, index) =>
                    <label htmlFor={saldo.id}  key={index + 1}>
                        <li className='saldo-individual'>
                            <div className='datavalue'>
                                <input
                                    type="checkbox"
                                    name="saldo"
                                    id={saldo.id}
                                    onChange={(e) => handleSaldoSeleccionadoChange(e, saldo)}
                                    checked={saldoSeleccionado.includes(saldo)}
                                />
                            </div>
                            <div className='datavalue'>
                                <h4>{parsearFecha(saldo.periodo)}</h4>
                            </div>
                            <div className='datavalue'>
                                <h4>{saldo.tipoDeCobranza}</h4>
                            </div>
                            <div className='datavalue'>
                                <h4>${saldo.valor}</h4>
                            </div>
                        </li>
                    </label>
                )}
            </ul>
            <article className='calculadora'>

                <div></div>
                <div></div>

                <div className='calculo'>
                    <h3>Deuda Total</h3>
                    <h3>${deuda}</h3>
                </div>

                <div className='calculo'>
                    <h3>Seleccionado</h3>
                    <h3>${total}</h3>
                </div>
            </article>
            <button className='pago' onClick={handlePagar}>Crear Pago Manual</button>
        </section>
    )
}

export default CargaSaldos