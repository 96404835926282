import React, { useEffect, useState } from 'react'
import ExcelButton from '../../../../components/partials/Excel/ExcelButton'
import TableGenerator from '../../../../components/TableGenerator/TableGenerator'

function DescargaArchivos({datos,Archivo ,volverAtras}) {

    const [columnas, setColumna] = useState([
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'apellnombAfilado', headerName: 'Nombre Cliente', sortable: false, width: 260 },
        { field: 'CUILTitular', headerName: 'CUIL', width: 110 },
        { field: 'IdAfiliado', headerName: 'Id Afiliado', width: 110 },
        { field: 'parentesco', headerName: 'parentesco', width: 90 },
        { field: 'tipoEstado', headerName: 'Tipo Estado', width: 90 },
        { field: 'estado', headerName: 'Estado', width: 90 },
        { field: 'edad', headerName: 'Edad', width: 90 },
        { field: 'sexo', headerName: 'Sexo', width: 90 },
        { field: 'planAfiliado', headerName: 'Plan Afiliado', width: 90 },
        { field: 'subplan', headerName: 'Subplan', width: 90 },
        { field: 'categoria', headerName: 'Categoria', width: 90 },
        { field: 'catAfiliado', headerName: 'Cat Af', width: 90 },
        { field: 'tipoConsumidor', headerName: 'Tipo Consumidor', width: 90 },
        { field: 'OSAndes', headerName: 'OS', width: 90 },
        { field: 'provinciaDom', headerName: 'Provincia', width: 90 },
        { field: 'localidadDom', headerName: 'Localidad', width: 90 },
        { field: 'direccion', headerName: 'Direccion', width: 90 },
        { field: 'mail', headerName: 'Mail', width: 90 },
        { field: 'celular', headerName: 'Celular', width: 90 },
        { field: 'unifica', headerName: 'Unifica', width: 90 },
        { field: 'idContrato', headerName: 'Id Contrato', width: 90 },
    ])

    const columnaElegir = () => {
        if (Archivo === 'PANACEA') {
            setColumna([
                { field: 'periodo', headerName: 'periodo', width: 90 },
                { field: 'cuil', headerName: 'CUIL', width: 110 },
                { field: 'cuit', headerName: 'CUIT', width: 110 },
                { field: 'empresa', headerName: 'empresa', width: 110 },
                { field: 'remDecla', headerName: 'remDecla', width: 90 },
                { field: 'remContrib', headerName: 'remContrib', width: 90 },
                { field: 'Aporte', headerName: 'AporteDev', width: 90 },
                { field: 'Contribucion', headerName: 'ContribucionDev', width: 90 },
                { field: 'ApTransf', headerName: 'ApTransf', width: 90 },
                { field: 'ContrTransf', headerName: 'ContrTransf', width: 90 },
            ])
        }
    }

    useEffect(() => {
        columnaElegir()
    }, [])
    

    return (
        <div className='Funciones Tabla-Padron'>
            <div className='header-funciones'>
                <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
            </div>
            <h2 className='titulo-seccion'>Padron Total</h2>
            <div>
                <div>
                    {datos.length > 0 &&
                        <TableGenerator columns={columnas} data={datos} />
                    }
                    <div className='buttons'>
                        <ExcelButton data={datos} nombreHoja={Archivo} nombreArchivo={Archivo} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DescargaArchivos