import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CargaLotes from './CargaLotes/CargaLotes'
import CargaManual from './PagoManual/CargaManual'
import Saldos from './Saldos/Saldos';
import Funciones from './Funciones/Funciones';
import SaldoParcial from './SaldoParcial/SaldoParcial';
import PlanesDePago from './PlanesDePago/PlanesDePago';
import SaldosPadron from './SaldosPorPadron/SaldosPadron';
import Resumen from './Resumen/Resumen';

function Pagos() {

    const state = useSelector(state => state);
	const [rol, setRol] = useState(state.rol)

	useEffect(() => {
		setRol(state.rol)
	}, [state]);

    return (
        <main className='Ingresos'>
            <section>
                <h1>Registrar Pagos</h1>
                <Tabs>
                    <TabList>
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Funciones</Tab>
                        }
                        {(rol === "Administrador" || rol === "Facturacion") &&
                            <Tab>Resumen Facturacion</Tab>
                        }
                        <Tab>Saldos</Tab>
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Pagos Manuales</Tab>
                        }
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Saldos Periodo</Tab>
                        }
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Saldo Parcial</Tab>
                        }
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Planes de Pago</Tab>
                        }
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Lotes</Tab>
                        }
                    </TabList>

                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <Funciones />
                        </TabPanel>
                    }
                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <Resumen />
                        </TabPanel>
                    }
                    <TabPanel>
                        <Saldos />
                    </TabPanel>
                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <CargaManual />
                        </TabPanel>
                    }
                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <SaldosPadron />
                        </TabPanel>
                    }
                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <SaldoParcial />
                        </TabPanel>
                    }
                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <PlanesDePago />
                        </TabPanel>
                    }
                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <CargaLotes />
                        </TabPanel>
                    }
                    
                </Tabs>
            </section>
        </main>
    )
}

export default Pagos


