import React, { useState } from 'react'
import Loading from '../../../components/partials/Loading'
import consulta from '../../../utils/consulta'

function Bonificacion() {

    const [isLoading, setIsLoading] = useState(false)
    const regex = /^[0-9]+(\.[0-9]{1,2})?$/
    const [modificarMagia, setModificarMagia] = useState(false);
    const [errores, setErrores] = useState("");
    const [cuil, setCuil] = useState("");
    const [contrato, setContrato] = useState("");
    const [contratoEncontrado, setContratoEncontrado] = useState("");

    const buscarContrato = async (e) => {
        try {
            e.preventDefault()
            setIsLoading(true)
            let finalURL = `/contratos/individual?titular=${cuil}`
            let response = await consulta.get(finalURL)
            console.log(response);
            setContratoEncontrado(true)
            setContrato(response.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setErrores(error.meta.msg)
            setIsLoading(false)
        }
    }

    /* Cargando ... */
    if (isLoading) {
        return (
            <Loading texto={"Cargando Bonificaciones"} />
        )
    }
    return (
        <section className='Modificacion-Contrato'>
            <h2>Crear Bonificacion</h2>
            <article className='busqueda'>
                <form className='formulario' onSubmit={buscarContrato}>
                    <input
                        id='search'
                        name='search'
                        onChange={(e) => {
                            if (!regex.test(e.target.value) || e.key === "Backspace") {
                                e.preventDefault();
                            } else {
                                setCuil((e.target.value).trim())
                            }
                        }}
                        className='form-control inputBuscar'
                        placeholder="Busqueda por cuil"
                        type="text" />
                    <button>Buscar</button>
                </form>
                {errores &&
                    <small className='errores'>¡Error!: {errores}</small>}
            </article>
        </section>
    )
}

export default Bonificacion