import React, { useState } from 'react'
import consulta from '../../../../utils/consulta'
import { fechaMes, parsearFecha, escribirFuncion, parsearPersonas } from '../../../../utils/funciones'
import Loading from '../../../../components/partials/Loading'
import DataDisplay from '../../../../components/TableGenerator/DataDisplay'
import TableGenerator from '../../../../components/TableGenerator/TableGenerator'
import AcordeonTable from '../../../../components/Acordeon/AcordeonTable'
import ExcelButton from '../../../../components/partials/Excel/ExcelButton'

function Ejecucion({ setFunc }) {

    const [error, seterror] = useState(false)
    const [loading, setLoading] = useState(false)
    const [mostrarDataIndividual, setmostrarDataIndividual] = useState(false)
    const [mostrarDataPadron, setmostrarDataPadron] = useState(false)
    const [periodo, setperiodo] = useState(fechaMes())
    const [cuil, setcuil] = useState("")
    const [funcionEscogida, setFuncionEscogida] = useState("")
    const [NombreUsuario, setNombreUsuario] = useState("")
    const [ValorPlan, setValorPlan] = useState({})
    const [tipoError, setTipoError] = useState("")
    const [ValorPlanPadron, setValorPlanPadron] = useState([])
    const [personasArr, setPersonasArr] = useState([])
    const [columnasArr, setcolumnasArr] = useState([])

    const cambiarMes = (e) => {
        let mes = e.target.value;
        let nuevoPeriodo = String(periodo)
        let year = nuevoPeriodo.substring(0, 4)
        setperiodo(`${year}${mes}`)
    }
    const cambiaryear = (e) => {
        let year = e.target.value
        let nuevoPeriodo = String(periodo)
        let mes = nuevoPeriodo.substring(4, 6)
        setperiodo(`${year}${mes}`)
    }
    const volverAtras = (e) => {
        e.preventDefault()
        seterror(false)
        setmostrarDataIndividual(false)
        setmostrarDataPadron(false)
        setperiodo(fechaMes())
        setcuil("")
        setFuncionEscogida("")
        setNombreUsuario("")
        setValorPlan({})
        setFunc("")
    }
    const settearColumnas = (funcion) => {
        let columnaValorPlanPadron
        if (funcion === 'VPP') {
            columnaValorPlanPadron = [
                { field: 'id', headerName: 'ID', width: 70 },
                { field: 'cuil', headerName: 'CUIL', width: 110 },
                { field: 'mes', headerName: 'Periodo', width: 90 },
                { field: 'edad', headerName: 'Edad', width: 90 },
                { field: 'lista', headerName: 'Lista', width: 90 },
                { field: 'categoria', headerName: 'Categoría', width: 100 },
                { field: 'plan', headerName: 'Plan Afiliado', width: 140 },
                { field: 'valor', headerName: 'Valor plan', width: 140 },
                { field: 'bonificacion', headerName: 'Bonificacion', width: 90 }
            ];
        } else {
            columnaValorPlanPadron = [
                { field: 'contratoId', headerName: 'contratoId', width: 90 },
                { field: 'titular', headerName: 'Titular', width: 110 },
                { field: 'mes', headerName: 'Periodo', width: 90 },
                { field: 'gf', headerName: 'GF', width: 70 },
                { field: 'descuentos', headerName: 'Descuentos', width: 140 },
                { field: 'adicionales', headerName: 'Adicionales', width: 140 },
                { field: 'valorFinal', headerName: 'Valor Contrato', width: 140 },
                { field: 'valorContrato', headerName: 'Valor Final', width: 140 },
                { field: 'personas', headerName: 'Personas', width: 140 },
            ];
        }
        setcolumnasArr(columnaValorPlanPadron)
    }

    const ValorPlanIndividuales = async (e) => {
        try {
            e.preventDefault()
            if (cuil.length === 11) {
                seterror(false)
                setLoading(true)
                let urlValorPlan = `/calculos/valorPlan?titular=${cuil}&periodo=${periodo}`
                let response = await consulta.post(urlValorPlan)
                setNombreUsuario(response.meta.nombre)
                setValorPlan(response.data)
                setmostrarDataIndividual(true)
                setLoading(false)
            } else {
                seterror(true)
            }
        } catch (error) {
            seterror(true)
            setTipoError(error.meta.error)
            setLoading(false)
        }
    }
    const EjecutarValorPlanPadron = async () => {
        try {
            setLoading(true)
            settearColumnas(funcionEscogida)
            let urlValorPlanPadron = `/calculos/valoresPlan/padron?periodo=${periodo}`
            let response = await consulta.post(urlValorPlanPadron)
            setValorPlanPadron(response.data)
            setmostrarDataPadron(true)
            setLoading(false)
        } catch (error) {
            seterror(true)
            setTipoError(error.meta.error)
            setLoading(false)
        }
    }
    const ValoresPlanContratos = async (e) => {
        try {
            e.preventDefault()
            if (cuil.length === 11) {
                seterror(false)
                setLoading(true)
                let urlvalorPlanContrato = `/calculos/valorPlanContrato?titular=${cuil}&periodo=${periodo}`
                let response = await consulta.post(urlvalorPlanContrato)
                setNombreUsuario(response.meta.nombre)
                setPersonasArr(parsearPersonas(response.data.personas))
                delete response.data.personas
                setValorPlan(response.data)
                setmostrarDataIndividual(true)
                setLoading(false)
            } else {
                seterror(true)
            }
        } catch (error) {
            seterror(true)
            setTipoError(error.meta.error)
            setLoading(false)
        }
    }
    const ValoresPlanContratosPadron = async () => {
        try {
            setLoading(true)
            let urlValorPlanContratoPadron = `/calculos/valorPlanContrato/padron?&periodo=${periodo}`
            let response = await consulta.post(urlValorPlanContratoPadron)
            setValorPlanPadron(response.data)
            setmostrarDataPadron(true)
            setLoading(false)
        } catch (error) {
            seterror(true)
            setTipoError(error.meta.error)
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <Loading texto={"Cargando Valores Plan ..."} />
        )
    }

    if (mostrarDataIndividual) {
        return (
            <div className='Funciones'>
                <div className='header-funciones'>
                    <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
                </div>
                <h2 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h2>
                <h3 className='titulo-seccion'>Usuario: {NombreUsuario}</h3>
                <div>
                    <DataDisplay data={ValorPlan} />
                    {personasArr.length > 0 &&
                        <div className='tablaPersonas'>
                            <AcordeonTable data={personasArr} />
                        </div>
                    }
                </div>
                <h4 className='subtitulo-seccion'>Simulación Finalizada cuil:{cuil} </h4>
            </div>
        )
    }

    if (mostrarDataPadron) {
        return (
            <div className='Funciones Tabla-Padron'>
                <div className='header-funciones'>
                    <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
                </div>
                <h2 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h2>
                <h3 className='titulo-seccion'>Usuario: {NombreUsuario}</h3>
                <div>
                    {funcionEscogida === "VPP" ?
                        <div>
                            {ValorPlanPadron.length > 0 &&
                                <TableGenerator columns={columnasArr} data={ValorPlanPadron} />
                            }
                            <div className='buttons'>
                                <ExcelButton data={ValorPlanPadron} nombreHoja={funcionEscogida} nombreArchivo={`ValoresPlan`} />
                            </div>
                        </div>
                        :
                        <div>
                            {ValorPlanPadron.length > 0 &&
                                <TableGenerator columns={columnasArr} data={ValorPlanPadron} />
                            }
                            <div className='buttons'>
                                <ExcelButton data={ValorPlanPadron} nombreHoja={funcionEscogida} nombreArchivo={`Valores Plan Contratos`} />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    return (
        <div className='Funciones'>
            <div className='header-funciones'>
                <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
            </div>
            <h2 className='titulo-seccion'>Ejecuciones</h2>
            {funcionEscogida === "VPI" ?
                <article className='formulario'>
                    <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                    <form className='formulario-content' onSubmit={ValorPlanIndividuales}>
                        <div className='formData'>
                            <label htmlFor="cuil">Cuil Aflilado</label>
                            <input type="text" name="cuil" id="cuil" className={error ? "invalid" : "valid"} onChange={(e) => setcuil(e.target.value)} />
                        </div>
                        <div className='formData'>
                            <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                            <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                <option defaultValue="" hidden>Puede modificar el año</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>
                        <div className='formData'>
                            <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                            <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                <option defaultValue="" hidden>Puede modificar el mes</option>
                                <option value="01">Enero</option>
                                <option value="02">Febrero</option>
                                <option value="03">Marzo</option>
                                <option value="04">Abril</option>
                                <option value="05">Mayo</option>
                                <option value="06">Junio</option>
                                <option value="07">Julio</option>
                                <option value="08">Agosto</option>
                                <option value="09">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </div>
                        <div className='periodo-seleccionado'>
                            <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                        </div>
                        {error &&
                            <div className='error-del-sistema'>
                                <small>{tipoError}</small>
                            </div>
                        }
                        <div className='buttons'>
                            <button type="submit" className='submit-button'>Ejecutar</button>
                        </div>
                    </form>
                </article>
            : funcionEscogida === "VPP" ?
                <article className='formulario'>
                    <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                    <form className='formulario-content' onSubmit={EjecutarValorPlanPadron}>
                        <div className='formData'>
                            <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                            <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                <option defaultValue="" hidden>Puede modificar el año</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>
                        <div className='formData'>
                            <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                            <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                <option defaultValue="" hidden>Puede modificar el mes</option>
                                <option value="01">Enero</option>
                                <option value="02">Febrero</option>
                                <option value="03">Marzo</option>
                                <option value="04">Abril</option>
                                <option value="05">Mayo</option>
                                <option value="06">Junio</option>
                                <option value="07">Julio</option>
                                <option value="08">Agosto</option>
                                <option value="09">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </div>
                        <div className='periodo-seleccionado'>
                            <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                        </div>
                        {error &&
                            <div className='error-del-sistema'>
                                <small>{tipoError}</small>
                            </div>
                        }
                        <div className='buttons'>
                            <button className='cancel-button'>Cancelar</button>
                            <button type="submit" className='submit-button'>Ejecutar</button>
                        </div>
                    </form>
                </article>
            : funcionEscogida === "VPCI" ?
                <article className='formulario'>
                    <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                    <form className='formulario-content' onSubmit={ValoresPlanContratos}>
                        <div className='formData'>
                            <label htmlFor="cuil">Cuil Aflilado</label>
                            <input type="text" name="cuil" id="cuil" className={error ? "invalid" : "valid"} onChange={(e) => setcuil(e.target.value)} />
                        </div>
                        <div className='formData'>
                            <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                            <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                <option defaultValue="" hidden>Puede modificar el año</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>
                        <div className='formData'>
                            <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                            <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                <option defaultValue="" hidden>Puede modificar el mes</option>
                                <option value="01">Enero</option>
                                <option value="02">Febrero</option>
                                <option value="03">Marzo</option>
                                <option value="04">Abril</option>
                                <option value="05">Mayo</option>
                                <option value="06">Junio</option>
                                <option value="07">Julio</option>
                                <option value="08">Agosto</option>
                                <option value="09">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </div>
                        <div className='periodo-seleccionado'>
                            <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                        </div>
                        {error &&
                            <div className='error-del-sistema'>
                                <small>{tipoError}</small>
                            </div>
                        }
                        <div className='buttons'>
                            <button className='cancel-button'>Cancelar</button>
                            <button type="submit" className='submit-button'>Ejecutar</button>
                        </div>
                    </form>
                </article>
            :funcionEscogida === "VPCP" ?
            
                            <article className='formulario'>
                                <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                                <form className='formulario-content' onSubmit={ValoresPlanContratosPadron}>
                                    <div className='formData'>
                                        <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                                        <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                            <option defaultValue="" hidden>Puede modificar el año</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                        </select>
                                    </div>
                                    <div className='formData'>
                                        <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                                        <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                            <option defaultValue="" hidden>Puede modificar el mes</option>
                                            <option value="01">Enero</option>
                                            <option value="02">Febrero</option>
                                            <option value="03">Marzo</option>
                                            <option value="04">Abril</option>
                                            <option value="05">Mayo</option>
                                            <option value="06">Junio</option>
                                            <option value="07">Julio</option>
                                            <option value="08">Agosto</option>
                                            <option value="09">Septiembre</option>
                                            <option value="10">Octubre</option>
                                            <option value="11">Noviembre</option>
                                            <option value="12">Diciembre</option>
                                        </select>
                                    </div>
                                    <div className='periodo-seleccionado'>
                                        <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                                    </div>
                                    {error &&
                                        <div className='error-del-sistema'>
                                            <small>{tipoError}</small>
                                        </div>
                                    }
                                    <div className='buttons'>
                                        <button className='cancel-button'>Cancelar</button>
                                        <button type="submit" className='submit-button'>Ejecutar</button>
                                    </div>
                                </form>
                            </article>
                            :
                            <article className='botones-Funciones'>
                                <button className='simulacion-button' onClick={(e) => setFuncionEscogida("VPI")}>Valor Plan Individual</button>
                                <button className='simulacion-button' onClick={(e) => setFuncionEscogida("VPP")}>Valor Plan Padrón Completo</button>
                                <button className='simulacion-button' onClick={(e) => setFuncionEscogida("VPCI")}>Valor Plan Contrato Individual</button>
                                <button className='simulacion-button' onClick={(e) => setFuncionEscogida("VPCP")}>Valor Plan Contrato Padrón Completo</button>
                            </article>
            }
        </div>
    )
}

export default Ejecucion