import React, { useState } from 'react'
import consulta from '../../../../utils/consulta'
import Loading from '../../../../components/partials/Loading'
import { parsearFecha } from '../../../../utils/funciones'
import Swal from 'sweetalert2';
import ModalAportes from './ModalAportes';

function AportesNetos({ volverAtras }) {

    const regex = /^[0-9]+(\.[0-9]{1,2})?$/
    const [loading, setLoading] = useState(false)
    const [cuil, setCuil] = useState("")
    const [datos, setDatos] = useState([])
    const [modalAbierto, setModalAbierto] = useState(false);
    const [saldoEditando, setSaldoEditando] = useState(null);

    const handleSubmit = async (e) => {
        try {
            setLoading(true)
            e.preventDefault()
            let urlInfoAportes = `/informacion/api/aporte/netos?titular=${cuil}`
            let response = await consulta.get(urlInfoAportes)
            console.log(response);
            setDatos(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    const handleEdit = async (dato) => {
        try {
            console.log(dato);
            const urlEliminarAporteNeto = '/modificaciones/aporte/neto'
            let result = await Swal.fire({
                title: `Esta seguro que desea editar el siguiente aporte?`,
                showCancelButton: true,
                confirmButtonText: 'Generar',
            })
        } catch (error) {
            console.log(error.message)
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Error al generar \n" + error.message,
                showConfirmButton: false,
                timer: 1500
            })
        }
    }
    const cerrarModal = () => {
        setSaldoEditando(null);
        setModalAbierto(false);
    };

    const handleDelete = async (dato) => {
        try {
            const urlEliminarAporteNeto = '/modificaciones/aporte/neto'
            let result = await Swal.fire({
                title: `Esta seguro que desea eliminar el aporte de ${parsearFecha(dato.periodo)}`,
                showCancelButton: true,
                confirmButtonText: 'Generar',
            })
        } catch (error) {
            console.log(error.message)
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Error al generar \n" + error.message,
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    if (loading) {
        return (
            <Loading texto='Cargando Aportes Netos ...' />
        )
    }

    if (datos.length > 0) {
        return (
            <section className='AportesNetos'>
                <div className="atras">
                    <button onClick={(e) => volverAtras(e)}>Volver Atras</button>
                </div>
                <h2>Aportes Netos</h2>
                <article className='tablaEditar'>
                    <ul className='header-tabla'>
                        <li>Periodo</li>
                        <li>CUIL</li>
                        <li>CUIT</li>
                        <li>Empresa</li>
                        <li>Obra</li>
                        <li>Rem. Transferida</li>
                        <li>Rem. Declarada</li>
                        <li>Total Transferidos</li>
                        <li>Total Declarados</li>
                        <li>Funcion</li>
                    </ul>
                    {datos.map((dato) =>
                        <ul key={dato.id}>
                            <li>{parsearFecha(dato.periodo)}</li>
                            <li>{dato.cuil}</li>
                            <li>{dato.cuit}</li>
                            <li>{dato.empresa}</li>
                            <li>{dato.obra}</li>
                            <li>{dato.remContrib}</li>
                            <li>{dato.remDecla}</li>
                            <li>{dato.totalTransferidos}</li>
                            <li>{dato.totalDeclarados}</li>
                            <li>
                                <button onClick={() => handleEdit(dato)}>
                                    <svg
                                        width="15"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                        fill="green"
                                    >
                                        <path path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                    </svg>
                                </button>
                                <button onClick={() => handleDelete(dato)}>
                                    <svg
                                        width="15"
                                        height="15"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                        fill="red"
                                    >
                                        <path path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                                    </svg>
                                </button>
                            </li>
                        </ul>
                    )}
                </article>
                {modalAbierto && (
                <ModalAportes
                    saldoEditando={saldoEditando}
                    cerrarModal={cerrarModal}
                />
            )}
            </section>
        )
    }

    return (
        <section className='AportesNetos'>
            <div className="atras">
                <button onClick={(e) => volverAtras(e)}>Volver Atras</button>
            </div>
            <h2>Aportes Netos</h2>
            <article>
                <form className="construccion-formulario" onSubmit={handleSubmit}>
                    <div className="formData">
                        <label htmlFor="cuil">CUIL</label>
                        <input
                            type="text"
                            name="cuil"
                            id="cuil"
                            className="input-field"
                            placeholder="Busqueda por cuil"
                            onChange={(e) => {
                                if (!regex.test(e.target.value) || e.key === "Backspace") {
                                    e.preventDefault();
                                } else {
                                    setCuil((e.target.value).trim())
                                }
                            }}
                        />
                    </div>

                    <button className="submit-button" type="submit">Enviar</button>
                </form>
            </article>
        </section>
    )
}

export default AportesNetos