import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes } from "../../../reducers/index";
import { useNavigate } from 'react-router-dom';
import consulta from '../../../utils/consulta'
import Swal from 'sweetalert2';
import LogoAndes from '../../../assets/logoandes-blanco.png'
import { useEffect } from 'react';

import './Styles/profile.css'

function Profile() {

	const dispatch = useDispatch();
	const state = useSelector(state => state);
	const [User, setUser] = useState(state.userData[0])

	useEffect(() => {
		setUser(state.userData[0])
	}, [state]);

	const baseURL = "/users/pass"
	const navigate = useNavigate();
	const [editar, setEditar] = useState(false)
	const [info, setInfo] = useState(false)
	const [errorVista, setErrorVista] = useState(false)
	const [errorPass, setErrorPass] = useState("")
	const [data, setData] = useState({
		nombre: state.userData[0].nombre,
		apellido: state.userData[0].apellido,
		email: state.userData[0].email,
		newpass: "",
		password: "",
		passwordRepeat: "",
	})

	const handleChange = (evt) => {
		const { target } = evt;
		const { name, value } = target;

		const newValues = {
			...data,
			[name]: value,
		};
		setData(newValues);
	}

	const cerrarSesion = () => {
		setUser("")
		window.localStorage.setItem(
			'authToken', JSON.stringify("")
		)
		dispatch({ type: actionTypes.LOGOUT, payload: [] });
		navigate('/')
	}

	const enviarEdicion = async () => {
		try {
			const result = await Swal.fire({
				title: '¿Desea Guardar sus cambios?',
				showDenyButton: true,
				confirmButtonText: 'Guardar',
				denyButtonText: 'Cancelar',
			});

			if (result.isConfirmed) {
				const response = await consulta.post(baseURL, data);

				if (response.status === 200) {
					Swal.fire('Editado!', '', 'success');
					navigate('/herramientas');
				}
			} else if (result.isDenied) {
				Swal.fire('Cambios no guardados', '', 'info');
			}
		} catch (error) {
			console.error('Error:', error);
			setErrorPass(error.meta.msg);
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: error.meta.msg,
			});
		}
	};
	const activeInfo = () => {
		if (info) {
			setInfo(false)
		} else {
			setInfo(true)
			setTimeout(() => {
				setInfo(false)
			}, 3000);
		}
	}
	const activeError = () => {
		if (errorVista) {
			setErrorVista(false)
		} else {
			setErrorVista(true)
			setTimeout(() => {
				setErrorVista(false)
			}, 3000);
		}
	}
	const editarSesion = () => {

		if (editar) {
			setEditar(false)
		} else {
			setEditar(true)
		}
	}

	return (
		<main className='profile'>
			<section className="card">
				<article>
					<img src={LogoAndes} alt="Logo Andes" />
				</article>
				{(!editar) ?
					<div className='informacion'>
						<article>
							<h4>Permisos:</h4>
							<p>{User.permisos}</p>
						</article>
						<article>
							<h4>Nombre:</h4>
							<p>{User.nombre}</p>
						</article>
						<article>
							<h4>Apellido:</h4>
							<p>{User.apellido}</p>
						</article>
						<article>
							<h4>Email:</h4>
							<p>{User.email}</p>
						</article>
						<article>
							<h4>Telefono:</h4>
							<p>+{User.telefono}</p>
						</article>

					</div>
					:
					<form className='editar'>
						<article>
							<label htmlFor='nombre'>Nombre:</label>
							<input type="text" name="nombre" id="nombre" defaultValue={User.nombre} onChange={handleChange} />
						</article>
						<article>
							<label htmlFor='apellido'>Apellido:</label>
							<input type="text" name="apellido" id="apellido" defaultValue={User.apellido} onChange={handleChange} />
						</article>
						<article>
							<label htmlFor='email'>Email:</label>
							<input type="text" name="email" id="email" defaultValue={User.email} onChange={handleChange} />
						</article>
						<article className={errorPass !== "" ? 'confirm-pass active' : 'confirm-pass'}>
							<label htmlFor='newpass'>Nueva Contraseña:</label>
							<input type="password" name="newpass" id="newpass" onChange={handleChange} />
							<div className={errorPass !== "" ? 'info active' : "info"}>
								<i className="fas fa-info-circle" onClick={activeInfo}></i>
							</div>
						</article>
						<article className={errorPass !== "" ? 'confirm-pass active' : 'confirm-pass'}>
							<label htmlFor='passwordRepeat'>Repetir Contraseña:</label>
							<input type="password" name="passwordRepeat" id="passwordRepeat" onChange={handleChange} />
							<div className={errorVista ? "Mostrar-Error active" : "Mostrar-Error"}>
								<small>{errorPass}</small>
							</div>
							<div className={errorPass !== "" ? 'info active' : "info"}>
								<i className="fas fa-info-circle" onClick={activeError}></i>
							</div>
						</article>
						<article className={data.password !== "" && data.password.length > 7 ? 'confirm-pass' : 'confirm-pass active'}>
							<label htmlFor='password'>Contraseña:</label>
							<input type="password" name="password" id="password" onChange={handleChange} />
							<div className={info ? "informacion-seguridad active" : "informacion-seguridad"} >
								<p>La contraseña es obligatoria para cada modificacion</p>
							</div>
							<div className='info'>
								<i className="fas fa-info-circle" onClick={activeInfo}></i>
							</div>
						</article>
					</form>
				}
			</section>

			<div className='cerrar-sesion'>
				<div className={editar ? "custom-btn" : "custom-btn-edit"} onClick={editarSesion}>{editar ? "Cancelar" : "Editar Perfil"}</div>
				{
					data.password !== "" ?
						<div className={editar ? "custom-btn-edit" : "custom-btn"} onClick={editar ? enviarEdicion : cerrarSesion} >{editar ? "Guardar" : "Cerrar Sesion"}</div>
						:
						<div className={editar ? "custom-btn-edit-inactive" : "custom-btn"} onClick={editar ? console.log("Sin Permisos") : cerrarSesion}>{editar ? "Guardar" : "Cerrar Sesion"} </div>}
			</div>
		</main>
	)
}

export default Profile