import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { DespachanteDeAduanas, logear } from "../../../utils/logear"
import './Styles/login2.css'

function Login() {
    const dispatch = useDispatch();
    const [usu, setUsu] = useState("")
    const [pas, setPas] = useState("")
    const [cookie, setcookie] = useState(false)
    const [error, setError] = useState("")
    const [show, setshow] = useState(false)

    const iniciarSesion = async (e) => {
        try {
            e.preventDefault()
            setError("")
            const response = await logear(usu, pas, cookie);
            console.log("Respuesta -->",response);
            localStorage.setItem('authToken', response.meta.token);
            DespachanteDeAduanas(dispatch, response.data.permisos, response.data)
        } catch (error) {
            setError("Credenciales Invalidas")
        }
    }

    const verPass = () => {
        if (show === false) {
            setshow(true)
        } else {
            setshow(false)
        }
    }

    return (
        <div className="box-container" /* onClick="onclick" */>
            <div className="top"></div>
            <div className="bottom"></div>
            <form className="center" onSubmit={iniciarSesion}>
                <h2>Iniciar Sesion</h2>
                <div className='contenedor-input'>
                    <input
                        type="email"
                        id='txtusu'
                        name='email'
                        placeholder="email"
                        onChange={(e) => setUsu(e.target.value)}
                    />
                </div>
                <div className='contenedor-input'>
                    <input
                        type={show ? "text" : "password"}
                        id='txtpass'
                        name='password'
                        className='pass'
                        placeholder="Contraseña"
                        onChange={(e) => setPas(e.target.value)}
                    />
                    <div className='eye' onClick={verPass}>

                        {show ?
                            <i className="fas fa-eye"></i>
                            :
                            <i className="fas fa-eye-slash"></i>
                        }
                    </div>
                    <div className='contenedor-checkbox'>
                        <label htmlFor="cookies">¿Mantener sesion iniciada?</label>
                        <input type="checkbox" name="cookies" id="cookies" onClick={(e) => setcookie(!cookie)} />
                    </div>
                </div>

                <button type='submit'>Ingresar</button>
                {error !== "" &&
                    <small>¡{error}!</small>}
                <h2>&nbsp;</h2>
            </form>
        </div>
    )
}

export default Login