import React, { useState } from 'react'
import Swal from "sweetalert2";

import consulta from '../../../../utils/consulta';
import { parsearFecha } from "../../../../utils/funciones";

function NotasDeCredito({ facturas, columnas}) {

    const [actualizar, setActualizar] = useState(false);
    const [errorMensaje, setErrorMensaje] = useState("");
    const [cargandoFactura, setCargandoFactura] = useState({});


    const abrirUrlFactura = async (idUnico) => {
        try {
            let urlFacturas = `/facturacion/api/external?idUnico=${idUnico}`;
            let response = await consulta.get(urlFacturas);
            if (response.meta.total === 1) {
                window.open(response.data.UrlComprobante, "_blank"); // Abre la URL en una nueva pestaña
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Factura no encontrada",
                showConfirmButton: false,
                timer: 1000,
            });
        }
    };

    const crearUrlFactura = async (e, factura) => {
        e.preventDefault();
        try {
            let urlFacturas = `/facturacion/api/url-factura?titular=${factura.sujetoFacturable}&periodo=${factura.periodo}&idUnico=${factura.idUnico}`;
            const respuesta = await consulta.post(urlFacturas);
            console.log(respuesta);
            if (respuesta.meta.total === 1) {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Factura creada, consulte nuevamente en el boton",
                    showConfirmButton: false,
                    timer: 1000,
                });
            } else if (respuesta.meta.total === 2) {
                setActualizar(!actualizar);
            }
        } catch (error) {
            console.error(error);
            setErrorMensaje("Error al cargar la factura");
        }
    };

    return (
        <section className="Tabla-Mistica">
            <div className="headers">
                {columnas.map((columna, index) => (
                    <div className="header" key={index + 1}>
                        {columna.headerName}
                    </div>
                ))}
            </div>
            {facturas.map((factura, index) => (
                <div className="body" key={index + 1}>
                    <div>{parsearFecha(factura.periodo)}</div>
                    <div>
                        {factura.titular === undefined
                            ? factura.sujetoFacturable
                            : factura.titular}
                    </div>
                    <div>${factura.valor}</div>
                    <div>{factura.pagados === 1 ? "Pagado" : "Sin Pago"}</div>
                    <div>
                        {cargandoFactura[factura.id] ? (
                            <button>Cargando</button>
                        ) : factura.facturas === "Si" ? (
                            <button
                                className="link-button"
                                onClick={() => abrirUrlFactura(factura.idUnico)}
                            >
                                Abrir Factura
                            </button>
                        ) : (
                            <button
                                className="crear-button"
                                onClick={(e) => crearUrlFactura(e, factura)}
                            >
                                Crear Nota de Credito B
                            </button>
                        )}
                    </div>
                </div>
            ))}

            {errorMensaje && <div className="error-message">{errorMensaje}</div>}

        </section>
    )
}

export default NotasDeCredito