import React, { useState } from 'react'
import consulta from '../../../../utils/consulta';

function Descuento(props) {
    
    const [valorPorcentaje, setvalorPorcentaje] = useState(0)
    const regex = /^[0-9]+(\.[0-9]{1,2})?$/
    
    const descuento = (e) => {
        e.preventDefault()
        console.log(e);
        let input = document.querySelector('#discount')

        let body = {
            porcentaje : valorPorcentaje
        }
        let urlEdicionListas = `/listas/porcentaje/${props.listaId}`
        consulta.post(urlEdicionListas,body)
        .then((response) => {
            console.log(response)
            input.value = 0
            window.location.reload()
        })
        .catch(err => console.log(err))
    }

  return (
    <div className='descuento'>
        <form onSubmit={(e) => descuento(e)}>
            <input 
                type="text" 
                name="discount" 
                id="discount" 
                placeholder="%" 
                onChange={(e) => {
                    if(!regex.test(e.target.value) || e.key === "Backspace"){
                        e.preventDefault();
                    }else{
                        setvalorPorcentaje(e.target.value)
                    }
                }}
            />
            <button className='funcion' type='submit'>Aplicar</button>
        </form>
        <p>Vencimiento {props.vencimiento}</p>
    </div>
  )
}

export default Descuento