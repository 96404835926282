import React from 'react';
import { funcionFechaCreatedAtUpdatedAt } from '../../utils/funciones'

function DataDisplay({ data }) {
    const renderData = Object.entries(data).map(([key, value]) => (
        <div key={key} className="data-item">
            <span className="data-key">{key}</span>: <span className="data-value">{(key === 'createdAt' || key === 'updatedAt') ? funcionFechaCreatedAtUpdatedAt(value) : value}</span>
        </div>
    ));

    return <div className="data-display">{renderData}</div>;
}

export default DataDisplay;