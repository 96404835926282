import { createStore } from "redux";

const actionTypes = {
    /* SET_USER_DATA: "SET_USER_DATA" */
    LOGIN_ADMINISTRADOR: "LOGIN_ADMINISTRADOR",
    LOGIN_FACTURACION: "LOGIN_FACTURACION",
    LOGIN_APORTES: "LOGIN_APORTES",
    LOGIN_CONTRATOS: "LOGIN_CONTRATOS",
    LOGIN_USUARIO: "LOGIN_USUARIO",
    LOGIN_MARKETING: "LOGIN_MARKETING",
    LOGIN_ATC: "LOGIN_ATC",
    LOGIN_AUDITORIA: "LOGIN_AUDITORIA",
    LOGOUT: "LOGOUT"
};

const initialState = {
    userData: [],
    isLoggedIn: false,
    rol: "",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_DATA:
            return {
                ...state,
                userData: action.payload,
            };
        case actionTypes.LOGIN_ADMINISTRADOR:
            return {
                ...state,
                isLoggedIn: true,
                rol: "Administrador",
                userData: [...state.userData, action.payload]
            };
        case actionTypes.LOGIN_FACTURACION:
            return {
                ...state,
                isLoggedIn: true,
                rol: "Facturacion",
                userData: [...state.userData, action.payload]
            };
        case actionTypes.LOGIN_APORTES:
            return {
                ...state,
                isLoggedIn: true,
                rol: "Aportes",
                userData: [...state.userData, action.payload]
            };
        case actionTypes.LOGIN_CONTRATOS:
            return {
                ...state,
                isLoggedIn: true,
                rol: "Contratos",
                userData: [...state.userData, action.payload]
            };
        case actionTypes.LOGIN_MARKETING:
            return {
                ...state,
                isLoggedIn: true,
                rol: "Marketing",
                userData: [...state.userData, action.payload]
            };
        case actionTypes.LOGIN_ATC:
            return {
                ...state,
                isLoggedIn: true,
                rol: "Atencion al Cliente",
                userData: [...state.userData, action.payload]
            };
        case actionTypes.LOGIN_AUDITORIA:
            return {
                ...state,
                isLoggedIn: true,
                rol: "Auditoria",
                userData: [...state.userData, action.payload]
            };
        case actionTypes.LOGIN_USUARIO:
            return {
                ...state,
                isLoggedIn: true,
                rol: "Usuario",
                userData: [...state.userData, action.payload]
            };

        case actionTypes.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                rol: "",
                userData: []
            };
        default:
            return state;
    }
};

const store = createStore(reducer);

export { store, actionTypes };