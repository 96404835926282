import axios from 'axios';
import { actionTypes } from "../reducers/index";
import apiConfig from "./apiConfig"

const logear = async (usu, pas, cookie) => {
    try {
        const info = {
            email: usu,
            password: pas,
            cookie
        };

        const baseUrl = `${apiConfig.fiscalizacionUrl}`
        const loginRouter = '/users/login';
        const response = await axios.post(`${baseUrl}${loginRouter}`, info);
        console.log(response);
        return response.data;

    } catch (error) {
        throw error.message;
    }
}

const tokenLogin = async (token) => {
    try {
        const baseUrl = `${apiConfig.fiscalizacionUrl}`
        const tokenRouter = '/users/tokenLogin';
        let response = await axios.post(`${baseUrl}${tokenRouter}`, { token })
        return response.data;

    } catch (error) {
        throw error.message;
    }
}

const logout = async (token) => {
    try {

        const baseUrl = `${apiConfig.fiscalizacionUrl}`
        const tokenRouter = '/users/tokenLogin';
        let response = await axios.post(`${baseUrl}${tokenRouter}`, { token })
        return response.data;

    } catch (error) {
        throw error.message;
    }
}

const DespachanteDeAduanas = (dispatch, permisos, data) => {
    switch (permisos) {
        case 'Administrador':
            dispatch({ type: actionTypes.LOGIN_ADMINISTRADOR, payload: data });
            break;
        case 'Facturacion':
            dispatch({ type: actionTypes.LOGIN_FACTURACION, payload: data });
            break;
        case 'Aportes':
            dispatch({ type: actionTypes.LOGIN_APORTES, payload: data });
            break;
        case 'Contratos':
            dispatch({ type: actionTypes.LOGIN_CONTRATOS, payload: data });
            break;
        case 'Auditoria':
            dispatch({ type: actionTypes.LOGIN_AUDITORIA, payload: data });
            break;
        case 'Marketing':
            dispatch({ type: actionTypes.LOGIN_MARKETING, payload: data });
            break;
        case 'Atencion al Cliente':
            dispatch({ type: actionTypes.LOGIN_ATC, payload: data });
            break;
        default:
            dispatch({ type: actionTypes.LOGIN_USUARIO, payload: data });
    }
}

export { logear, tokenLogin, logout, DespachanteDeAduanas };
