import React from 'react'
import consulta from '../../../../utils/consulta'

function BusquedaPadron({
    cuil,
    setCuil,
    setIsLoading,
    setdatoMostrar,
    setResultadoIndividual,
    setResultadoTotal,
    setErrorIndividual,
    setErrorTotal,
    errorTotal,
    errorIndividual
}) {

    /* Padron Individual */
    const buscarPadronIndividual = async () => {
        try {
            setIsLoading(true)
            // Realizar una solicitud a un endpoint para buscar el padron individual usando 'cuil'
            let urlIndividual = `/padrones/individual?search=${cuil}`
            const response = await consulta.get(urlIndividual);
            if (response.status === 200) {
                setResultadoIndividual(response.data);
                setErrorIndividual(null); // Limpiar el mensaje de error en caso de éxito
                setdatoMostrar("Individual")
                setIsLoading(false)
                return
            } else {
                throw new Error("Error con los datos")
            }
        } catch (error) {
            console.error('Error al buscar el padron individual:', error.message);
            setErrorIndividual('Error al buscar el padron individual');
        }
    };
    /* Padron Total */
    const consultarPadronTotal = async () => {
        try {
            setIsLoading(true)
            let urlIndividual = `/padrones`
            console.log(urlIndividual);
            const response = await consulta.get(urlIndividual);
            console.log(response);
            if (response.status === 200) {
                setResultadoTotal(response.data);
                setErrorTotal(null); // Limpiar el mensaje de error en caso de éxito
                setdatoMostrar("Total")
                setIsLoading(false)
                return
            } else {
                throw new Error("Error con los datos")
            }
        } catch (error) {
            console.error('Error al consultar el padron total:', error.message);
            setErrorTotal('Error al consultar el padron total');
        }
    };
    /* Unificaciones */
    const consultarUnificaciones = async () => {
        try {
            setIsLoading(true)
            let urlIndividual = `/padrones/unificaciones`
            console.log(urlIndividual);
            const response = await consulta.get(urlIndividual);
            console.log(response);
            if (response.status === 200) {
                setResultadoTotal(response.data);
                setErrorTotal(null); // Limpiar el mensaje de error en caso de éxito
                setdatoMostrar("Unificacion")
                setIsLoading(false)
                return
            } else {
                throw new Error("Error con los datos")
            }
        } catch (error) {
            console.error('Error al consultar el padron total:', error.message);
            setErrorTotal('Error al consultar el padron total');
        }
    };

    return (
        <article className='busqueda-padron'>
            <h2>Busqueda en Padron</h2>
            <div className='boton-container'>
                <button className='boton' onClick={consultarPadronTotal}>Padron General</button>
                {errorTotal && <div className='error'>Error Total: {errorTotal}</div>}
            </div>

            <div className='boton-container'>
                <button className='boton' onClick={consultarUnificaciones}>Unificaciones</button>
                {errorTotal && <div className='error'>Error Total: {errorTotal}</div>}
            </div>

            <div className='boton-container'>
                <button className='boton' onClick={buscarPadronIndividual}>Buscar Padron Individual</button>
                <input
                    className='input-cuil'
                    type="text"
                    placeholder="Ingrese CUIL"
                    value={cuil}
                    onChange={(e) => setCuil(e.target.value)}
                />
                {errorIndividual && <div className='error'>Error Individual: {errorIndividual}</div>}
            </div>
        </article>
    )
}

export default BusquedaPadron