import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Swal from 'sweetalert2'
import consulta from '../../../../utils/consulta'
import '../Styles/tabla.css'


function Tabla({ cuil, saldos, volverAtras }) {

    const state = useSelector(state => state);
	const [rol, setRol] = useState(state.rol)

	useEffect(() => {
		setRol(state.rol)
	}, [state]);

    const eliminarSaldo = async (e, idSaldo) => {
        try {
            e.preventDefault()
            Swal.fire({
                title: "¿Estas seguro de eliminar este saldo?",
                text: "¡Esta accion no se puede deshacer!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                confirmButtonText: "Si, eliminar!",
                cancelButtonText: "No, cancelar!",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Redacte el motivo",
                        input: "text",
                        inputAttributes: {
                            autocapitalize: "off"
                        },
                        showLoaderOnConfirm: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Eliminar",
                        showCancelButton: true,
                        preConfirm: async (login) => {
                            try {

                            } catch (error) {
                                Swal.showValidationMessage(`Request failed: ${error}`);
                            }
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: "Saldo Eliminado!",
                                text: "El saldo de Abril 2024 ha sido eliminado",
                                icon: "success"
                            });
                        }
                    });
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    Swal.fire({
                        title: "Cancelado",
                        text: "El saldo periodo 202404 respira nuevamente",
                        icon: "error"
                    });
                }
            });
        } catch (error) {
            Swal.fire({
                title: "Cancelado",
                text: error.message,
                icon: "error"
            });
        }
    }

    const toggleRevision = async (e, idSaldo) => {
        try {
            e.preventDefault()
            const urlPonerRevision = `/informacion/api/saldos/individual?id=${idSaldo}`
            const response = await consulta.put(urlPonerRevision)
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Saldo Modificado",
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error"
            });
        }
    }

    const editarSaldo = async () => {
        try {

        } catch (error) {

        }
    }

    return (
        <section className='Resumen-Saldo'>
            <h2>Revision Saldos Cuil: {cuil}</h2>
            <table className="table-auto table-conteiner">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Periodo</th>
                        <th>Obra Social</th>
                        <th>Plan Titular</th>
                        <th>Categoria</th>
                        <th>Tipo Cobranza</th>
                        <th>Tipo Saldo</th>
                        <th>Pagados</th>
                        <th>Plan De Pago</th>
                        {(rol === "Administrador" || rol === "Facturacion") &&
                        <th>Revision</th>}
                        <th>Valor</th>
                        {(rol === "Administrador" || rol === "Facturacion") &&
                        <th>Funciones</th>}
                    </tr>
                </thead>
                <tbody>
                    {saldos.map((saldo, index) => (
                        <tr className={saldo.pagados === 1 ? `${saldo.revision !== 1 ? '' : 'revision'} pagado` : `${saldo.revision !== 1 ? '' : 'revision'} impago`}>
                            <td>{index + 1}</td>
                            <td>{saldo.periodo}</td>
                            <td>{saldo.ObraSocialTitular}</td>
                            <td>{saldo.planTitular}</td>
                            <td>{saldo.categoriaTitular}</td>
                            <td>{saldo.tipoDeCobranza}</td>
                            <td>{saldo.tipoSaldo}</td>
                            <td>{saldo.pagados}</td>
                            <td>{saldo.planDePago}</td>
                            {(rol === "Administrador" || rol === "Facturacion") &&
                            <td>
                                <input type="checkbox" name="" id="" checked={saldo.revision === 1} onChange={toggleRevision} />
                            </td>}
                            <td>{saldo.valor}</td>
                            {(rol === "Administrador" || rol === "Facturacion") &&
                            <td>
                                <button className='editar'>Editar</button>
                                <button className='eliminar' onClick={(e) => eliminarSaldo(e, saldo.id)}>Eliminar</button>
                            </td>}
                        </tr>
                    ))}
                </tbody>
            </table>
            <article className='botones'>
                <button className='generar-libro' disabled>Generar Libre Deuda (En desarrollo)</button>
                <button className='crear-reporte'>Crear Reporte Excel</button>
            </article>
        </section>
    )
}

export default Tabla