import React, { useState } from 'react'
/* Componentes */
import VisaDebito from './Componentes/VisaDebito/VisaDebito'
import Mastercard from './Componentes/MasterCard/Mastercard'
import VisaCredito from './Componentes/VisaCredito/VisaCredito'
import Naranja from './Componentes/Naranja/Naranja'
import CBU from './Componentes/CBU/CBU'

import Loading from '../../../components/partials/Loading';
import './Styles/lotes.css'



function Lotes() {

    const [loading, setloading] = useState(false)
    const [activeComponent, setActiveComponent] = useState(null);
    const [activeButton, setActiveButton] = useState(null);

    const loadComponent = async (componentFunction, buttonId) => {
        try {
            setloading(true)
            setActiveComponent(() => componentFunction());
            setActiveButton(buttonId);
            setloading(false);
        } catch (error) {
            console.log(error);
            setloading(false)
        }
    };

    let volverAtras = () => {
        setloading(true)
        setActiveComponent(null)
        setActiveButton(null)
        setloading(false)
    }

    if (loading) {
        return (
            <Loading texto={"Cargando Lotes ..."} />
        )
    }

    if (activeComponent !== null) {
        return (
            <article className='Lotes'>
                <h1>LOTES</h1>
                <div>
                    {activeComponent}
                </div>
            </article>
        )
    }

    return (
        <article className='Lotes'>
            <h1>LOTES</h1>
            <div className='button-container'>
                <button
                    className={activeButton === 1 ? 'active-button VisaDebito' : 'buttonera VisaDebito'}
                    onClick={() => loadComponent(() => (
                        <VisaDebito
                            volverAtras={volverAtras}
                        />
                    ), 1)}>
                    Visa Debito
                </button>
                <button
                    className={activeButton === 2 ? 'active-button VisaCredito' : 'buttonera VisaCredito'}
                    onClick={() => loadComponent(() => (
                        <VisaCredito
                            volverAtras={volverAtras}
                        />
                    ), 2)}>
                    Visa Credito
                </button>
                <button
                    className={activeButton === 3 ? 'active-button naranja' : 'buttonera naranja'}
                    onClick={() => loadComponent(() => (
                        <Naranja
                            volverAtras={volverAtras}
                        />
                    ), 3)}>
                    Naranja
                </button>
                <button
                    className={activeButton === 4 ? 'active-button Mastercard' : 'buttonera Mastercard'}
                    onClick={() => loadComponent(() => (
                        <Mastercard
                            volverAtras={volverAtras}
                        />
                    ), 4)}>
                    Mastercard
                </button>
                <button
                    className={activeButton === 5 ? 'active-button CBU' : 'buttonera CBU'}
                    onClick={() => loadComponent(() => (
                        <CBU
                            volverAtras={volverAtras}
                        />
                    ), 5)}>
                    CBU
                </button>
            </div>
        </article>
    )
}

export default Lotes