import React from 'react'
import { parsearFecha } from '../../utils/funciones';
import './periodo.css'

function Periodo({ periodo, setPeriodo }) {

    const cambiarMes = (e) => {
        let mes = e.target.value;
        let nuevoPeriodo = String(periodo)
        let year = nuevoPeriodo.substring(0, 4)
        setPeriodo(`${year}${mes}`)
    }
    const cambiaryear = (e) => {
        console.log(e.target.value);
        let year = e.target.value
        let nuevoPeriodo = String(periodo)
        let mes = nuevoPeriodo.substring(4, 6)
        setPeriodo(`${year}${mes}`)
    }

    return (
        <div className='periodo-seleccionado'>
            <div className='formData'>
                <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                    <option defaultValue="" hidden>Puede modificar el año</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2025">2026</option>
                    <option value="2025">2027</option>
                    <option value="2025">2028</option>
                </select>
            </div>
            <div className='formData'>
                <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                    <option defaultValue="" hidden>Puede modificar el mes</option>
                    <option value="01">Enero</option>
                    <option value="02">Febrero</option>
                    <option value="03">Marzo</option>
                    <option value="04">Abril</option>
                    <option value="05">Mayo</option>
                    <option value="06">Junio</option>
                    <option value="07">Julio</option>
                    <option value="08">Agosto</option>
                    <option value="09">Septiembre</option>
                    <option value="10">Octubre</option>
                    <option value="11">Noviembre</option>
                    <option value="12">Diciembre</option>
                </select>
            </div>
            <div className='periodo-seleccionado'>
                <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
            </div>
        </div>
    )
}

export default Periodo