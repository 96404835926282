import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import InfoValores from './Informacion/InfoValores';
import Funciones from './Funciones/Funciones';
import Modificaciones from './Modificacion/Modificaciones';
import CargaDeDescuentos from './Descuentos/CargaDeDescuentos';
import ModificarContrato from './Contratos/ModificarContrato';
import Bonificacion from './Bonificacion/Bonificacion';


function ValoresPlan() {

    const state = useSelector(state => state);
    const [rol, setRol] = useState(state.rol)

    useEffect(() => {
        setRol(state.rol)
    }, [state]);

    return (
        <main className='Ingresos'>
            <section>
                <h1>Valores Plan</h1>
                <Tabs>
                    <TabList>
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Funciones</Tab>
                        }
                        <Tab>Información Valores</Tab>
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Modificaciones</Tab>
                        }
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Contratos</Tab>
                        }
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Carga Descuentos</Tab>
                        }
                        {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                            <Tab>Bonificacion</Tab>
                        }
                    </TabList>

                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <Funciones />
                        </TabPanel>
                    }

                    <TabPanel>
                        <InfoValores />
                    </TabPanel>

                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <Modificaciones />
                        </TabPanel>
                    }

                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <ModificarContrato />
                        </TabPanel>
                    }
                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <CargaDeDescuentos />
                        </TabPanel>
                    }
                    {(rol === "Administrador" || rol === "Aportes" || rol === "Contratos") &&
                        <TabPanel>
                            <Bonificacion />
                        </TabPanel>
                    }

                </Tabs>
            </section>
        </main>
    )
}
export default ValoresPlan

