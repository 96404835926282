import React, { useEffect, useState } from 'react'
import { parsearFecha } from '../../../../utils/funciones'
import Loading from '../../../../components/partials/Loading'
import consulta from '../../../../utils/consulta';
import Swal from 'sweetalert2';


function Deuda({ cuil, volverAtras, deuda }) {

    const [isLoading, setIsLoading] = useState(false)
    const [pagar, setPagar] = useState(false)
    const [error, seterror] = useState("")
    const [saldos, setSaldos] = useState([])
    const [saldoSeleccionado, setSaldoSeleccionado] = useState([]);
    const [seleccionarTodo, setSeleccionarTodo] = useState(false);
    const [total, setTotal] = useState(0)
    const [formData, setFormData] = useState({
        cuotas: '',
        interes: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const calcularTotalDeuda = (saldos) => {
        const totalSaldo = saldos.reduce((acumulador, objeto) => {
            // Comprueba si el objeto tiene la clave "valor"
            if (objeto.hasOwnProperty("valor")) {
                acumulador += objeto.valor;
            }
            return acumulador;
        }, 0);
        return totalSaldo
    };
    const handleSaldoSeleccionadoChange = (e, saldo) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            let datos = [...saldoSeleccionado, saldo]
            setTotal(calcularTotalDeuda(datos))
            setSaldoSeleccionado(datos);
        } else {
            let datos = saldoSeleccionado.filter((s) => s !== saldo)
            setTotal(calcularTotalDeuda(datos))
            setSaldoSeleccionado(datos);
        }
    };
    const handleSeleccionarTodoChange = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setTotal(calcularTotalDeuda([...saldos]))
            setSaldoSeleccionado([...saldos]);
        } else {
            setTotal(0)
            setSaldoSeleccionado([]);
        }
        setSeleccionarTodo(isChecked);
    };
    const quitarElemento = async (e, saldo) => {
        e.preventDefault()
        let datos = saldoSeleccionado.filter((s) => s !== saldo)
        setSaldoSeleccionado(datos);
    };
    const handleSubmit = async (e) => {
        let planDePagoDeuda = `/modificaciones/planesDePago/deuda?titular=${cuil}`
        e.preventDefault()
        await Swal.fire({
            title: `Generando plan de pago por los ${formData.cuotas} proximos meses` + calcularTotalDeuda(saldoSeleccionado),
            showCancelButton: true,
            confirmButtonText: 'Pagar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                let data = {
                    cuotas: formData.cuotas,
                    saldos: saldoSeleccionado,
                    interes: formData.interes,
                    valor: calcularTotalDeuda(saldoSeleccionado)
                }
                let response = await consulta.post(planDePagoDeuda, data)
                if (response.status === 200) {
                    Swal.fire('Pagada!', '', 'success')
                    volverAtras()
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Falta info " + response.status,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }
        })
    };
    const handleCrearPago = () => {
        if (saldoSeleccionado.length > 0) {
            setPagar(true)
        }
    };

    if (isLoading) {
        return (
            <Loading texto={"Cargando Deuda..."} />
        )
    }
    if (pagar) {
        return (
            <section className="Pasarela">
                <h3 className="projSubTitle">Cantidad: <span>{saldoSeleccionado.length} {saldoSeleccionado.length > 1 ? "saldos" : "saldo"}</span></h3>
                <div className="Carrito">
                    <ul className="lista-elementos">
                        {saldoSeleccionado.map((elemento, index) =>
                            <li className="datos-saldos" key={elemento.id}>
                                <div className="infoWrap">
                                    <div className="puntitomistico">
                                        <div className="contenedor-megapunto"></div>
                                    </div>
                                    <div className="informacion-saldo">
                                        <p className="itemNumber">#{elemento.id}_{elemento.idUnico.substring(0, 7)}</p>
                                        <h3>Saldo de {parsearFecha(elemento.periodo)}</h3>
                                        <p className="stockStatus">En Deuda</p>
                                    </div>
                                    <div className="flechita">
                                        <div className="contenedor-rectangulo"></div>
                                        <div className="contenedor-triangulo"></div>
                                    </div>
                                    <div className="valor-total">
                                        <p>${elemento.valor}</p>
                                    </div>
                                    <div className="eliminar-elemento responsive" onClick={(e) => quitarElemento(e, elemento)}>
                                        <button href="#" className="remove">x</button>
                                    </div>
                                    <div className="eliminar-elemento bigData" onClick={(e) => quitarElemento(e, elemento)}>
                                        <button href="#" className="remove">Eliminar</button>
                                    </div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="subtotal">
                    <form>
                        <div className="formData">
                            <label htmlFor="cuotas">Cuotas</label>
                            <select
                                name="cuotas"
                                id="cuotas"
                                value={formData.cuotas}
                                onChange={handleChange}
                            >
                                <option value="" hidden>
                                    Seleccione una opción
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                            </select>
                        </div>
                        <div className="formData">
                            <label htmlFor="interes">Interés</label>
                            <input
                                type="text"
                                name="interes"
                                id="interes"
                                value={formData.interes}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='button-container'>
                            <h3 className="totalRow final">
                                <span className="label">Total</span> <span className='valor'>${calcularTotalDeuda(saldoSeleccionado)}</span>
                            </h3>
                            <button onClick={handleSubmit}>Crear Plan de Pago</button>
                        </div>
                    </form>
                </div>
            </section>
        )
    }

    return (
        <section className='CreacionDeuda'>
            <ul className='datos'>
                <li className='header-saldo-individual'>
                    <div className='datavalue'>
                        <input type="checkbox"
                            name="all"
                            id="all"
                            checked={seleccionarTodo}
                            onChange={handleSeleccionarTodoChange}
                        />
                        <label htmlFor="all">Seleccionar</label>
                    </div>
                    <h4>Periodo</h4>
                    <h4>Tipo Cobranza</h4>
                    <h4>Valor</h4>
                </li>
                {error !== "" && <small>{error}</small>}
                {deuda.map((saldo, index) =>
                    <label htmlFor={saldo.id} key={index + 1}>
                        <li className='saldo-individual'>
                            <div className='datavalue'>
                                <input
                                    type="checkbox"
                                    name="saldo"
                                    id={saldo.id}
                                    onChange={(e) => handleSaldoSeleccionadoChange(e, saldo)}
                                    checked={saldoSeleccionado.includes(saldo)}
                                />
                            </div>
                            <div className='datavalue'>
                                <h4>{parsearFecha(saldo.periodo)}</h4>
                            </div>
                            <div className='datavalue'>
                                <h4>{saldo.tipoDeCobranza}</h4>
                            </div>
                            <div className='datavalue'>
                                <h4>${saldo.valor}</h4>
                            </div>
                        </li>
                    </label>
                )}
            </ul>
            <article className='calculadora'>

                <div></div>
                <div></div>

                <div className='calculo'>
                    <h3>Deuda Total</h3>
                    <h3>${calcularTotalDeuda(deuda)}</h3>
                </div>
                <div className='calculo'>
                    <h3>Seleccionado</h3>
                    <h3>${total}</h3>
                </div>
            </article>
            <button className='pago' onClick={handleCrearPago}>Crear Pago Parcial</button>
        </section>
    )
}

export default Deuda