import React, { useState } from 'react'
import Loading from '../../../components/partials/Loading'
import ExcelButton from '../../../components/partials/Excel/ExcelButton'
import TableGenerator from '../../../components/TableGenerator/TableGenerator'
import consulta from '../../../utils/consulta'
import { fechaMes, parsearFecha } from '../../../utils/funciones'

import './Styles/saldosPadron.css'

function SaldosPadron() {

    const [error, seterror] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [saldos, setsaldos] = useState([])
    const [periodo, setperiodo] = useState(fechaMes())
    const columnasArr = [
        { field: 'contratoId', headerName: 'contratoId', width: 90 },
        { field: 'titular', headerName: 'Titular', width: 110 },
        { field: 'valor', headerName: 'Saldo', width: 140 },
        { field: 'periodo', headerName: 'Periodo', width: 90 },
        { field: 'tipoDeCobranza', headerName: 'tipoDeCobranza', width: 170 },
        { field: 'linkMp', headerName: 'linkMp', width: 140 },
        { field: 'pagados', headerName: 'Pagado', width: 90 },
    ]

    const cambiarMes = (e) => {
        let mes = e.target.value;
        let nuevoPeriodo = String(periodo)
        let year = nuevoPeriodo.substring(0, 4)
        setperiodo(`${year}${mes}`)
    }
    const cambiaryear = (e) => {
        console.log(e.target.value);
        let year = e.target.value
        let nuevoPeriodo = String(periodo)
        let mes = nuevoPeriodo.substring(4, 6)
        setperiodo(`${year}${mes}`)
    }

    const BuscarSaldoContrato = async (e) => {
        try {
            e.preventDefault()
            setIsLoading(true)
            let urlAporteUtilizado = `/informacion/api/saldos/periodo?periodo=${periodo}`
            let response = await consulta.get(urlAporteUtilizado)
            /* console.log(response);
            response.data.forEach((saldo) => {
                saldo.personas = parsearPersonas(saldo.personas)
            }); */
            setsaldos(response.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            seterror("Error con los datos:" + error.meta.msg)
            setIsLoading(false)
        }
    }
    const volverAtras = (e) => {
        e.preventDefault()
        setsaldos([])
    }

    if (isLoading) {
        return (
            <Loading texto={"Cargando Saldos"} />
        )
    }

    if (saldos.length > 0) {
        return (
            <div className='Funciones Tabla-Padron'>
                <div className='header-funciones'>
                    <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
                </div>
                <h2>Saldos de padron periodo: {parsearFecha(periodo)}</h2>
                <div>
                    <div>
                        {saldos.length > 0 &&
                            <TableGenerator columns={columnasArr} data={saldos} />
                        }
                        <div className='buttons'>
                            <ExcelButton data={saldos} nombreHoja={"Saldos Padron"} nombreArchivo={`Saldos`} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='Saldos-Periodo'>
            <section className='seccion-busqueda'>
                <form className='formulario-content' onSubmit={BuscarSaldoContrato}>
                    <div className='formData'>
                        <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                        <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                            <option defaultValue="" hidden>Puede modificar el año</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>
                    <div className='formData'>
                        <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                        <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                            <option defaultValue="" hidden>Puede modificar el mes</option>
                            <option value="01">Enero</option>
                            <option value="02">Febrero</option>
                            <option value="03">Marzo</option>
                            <option value="04">Abril</option>
                            <option value="05">Mayo</option>
                            <option value="06">Junio</option>
                            <option value="07">Julio</option>
                            <option value="08">Agosto</option>
                            <option value="09">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                        </select>
                    </div>
                    <div className='periodo-seleccionado'>
                        <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                    </div>
                    {error !== "" && <small className='error-message'>{error}</small>}
                    <div className='buttons'>
                        <button type="submit" className='submit-button'>Buscar</button>
                    </div>
                </form>
            </section>
        </div>
    );
}


export default SaldosPadron