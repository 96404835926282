import React, { useEffect, useState } from 'react'
import Swal from "sweetalert2";
import consulta from '../../../../utils/consulta';
import Loading from "../../../../components/partials/Loading";
import { parsearFecha } from '../../../../utils/funciones';
import { Pagination, Stack } from '@mui/material';

function VerFacturas({ cuil }) {

    const [isLoading, setIsLoading] = useState(false);
    const [facturas, setFacturas] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [totalPages, setTotalPages] = useState(1);

    const handleChange = async (e, value) => {
        try {
            e.preventDefault();
            setIsLoading(true)
            setPage(value);
            let urlFacturas = `/facturacion/api/consulta?titular=${cuil}&page=${value}&limit=${limit}`;
            let response = await consulta.get(urlFacturas);
            setFacturas(response.data);
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    }

    const abrirUrlFactura = async (idUnico) => {
        try {
            let urlFacturas = `/facturacion/api/external?idUnico=${idUnico}`;
            let response = await consulta.get(urlFacturas);
            if (response.meta.total === 1) {
                window.open(response.data.UrlComprobante, "_blank"); // Abre la URL en una nueva pestaña
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Factura no encontrada",
                showConfirmButton: false,
                timer: 1000,
            });
        }
    };

    const buscarFacturas = async () => {
        try {
            setIsLoading(true);
            let urlFacturas = `/facturacion/api/consulta?titular=${cuil}&page=${page}&limit=${limit}`;
            let response = await consulta.get(urlFacturas);
            setTotalPages(response.meta.totalPages)
            setFacturas(response.data);
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        buscarFacturas()
    }, [limit])

    if (isLoading) {
        return <Loading texto={"Cargando Facturas"} />;
    }

    return (
        <section className="mega-container">
            <h2>Facturas de un contrato en especifico</h2>
            {facturas.map((factura, index) => (
                <article className="body" key={index + 1}>
                    <div>{factura.comprobanteTipo}</div>
                    <div>{parsearFecha(factura.periodo)}</div>
                    <div>{factura.numeroComprobante}</div>
                    <div>{factura.provincia}</div>
                    <div>${factura.total}</div>
                    <div>
                        <button
                            className="link-button"
                            onClick={() => abrirUrlFactura(factura.idUnico)}
                        >
                            Abrir Factura
                        </button>
                    </div>
                </article>
            ))}

            <div className='mega-paginador'>
                <Stack spacing={4} className='paginador'>
                    <Pagination count={totalPages} page={page} onChange={handleChange} />
                </Stack>
            </div>
        </section>
    )
}

export default VerFacturas