import React, { useState } from 'react'

/* Componentes */
import AportesNetos from './components/AportesNetos'
import AportesManuales from './components/AportesManuales'

/* Styles */
import './Styles/CreacionAportes.css'

function CreacionAportes() {

    const [funcion, setFuncion] = useState("")

    const handleChange = (e, value) => {
        e.preventDefault()
        setFuncion(value)
    }

    if (funcion === "Aportes Netos") {
        return <AportesNetos setFuncion={setFuncion} />
    }
    if (funcion === "Aportes Manuales") {
        return <AportesManuales setFuncion={setFuncion} />
    }

    return (
        <section className='Creacion-Aportes'>
            <h1>Crear Aportes</h1>
            <article className='botones-aportes'>
                <button 
                    onClick={(e) => handleChange(e,"Aportes Netos")}
                    className='boton-netos'
                >Aportes Netos
                </button>
                <button 
                    onClick={(e) => handleChange(e,"Aportes Manuales")}
                    className='boton-manuales'
                >Aportes Manuales
                </button>
            </article>
        </section>
    )
}

export default CreacionAportes