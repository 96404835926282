import React from 'react'

import ExcelButton from '../../../../components/partials/Excel/ExcelButton'

function Unificaciones({tipoDeVista, volverAtras, unificaciones}) {

    if (tipoDeVista === 'Modificando') {
        return (
            <article className='Funciones Unificaciones'>
                <div className='header-funciones'>
                    <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
                </div>
                <h2 className='titulo-seccion'>Unificaciones</h2>
                <div>
                    <div>
                        <div className='buttons'>
                            <ExcelButton data={unificaciones} nombreHoja={'Unificaciones'} nombreArchivo={`Unificacion`} />
                        </div>
                        <div className='buttons'>
                            <button>Crear unificacion</button>
                        </div>
                        <div className='buttons'>
                            <button>Desvinculacion</button>
                        </div>
                    </div>
                </div>
            </article>
        )
    }

    return (
        <article className="Unificaciones" >
            <h3>Unificaciones</h3>
            <button>Ver unificaciones</button>
        </article>
    )
}

export default Unificaciones