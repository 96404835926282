import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import AcordeonItem from "./Componentes/AcordeonItem";
import Descuento from './Componentes/Descuento'
import consulta from '../../../utils/consulta'

function Listas() {
    const [isLoading, setIsLoading] = useState(true);
    const [tablaValores, setTablaValores] = useState([]);
    const [clicked, setClicked] = useState("0");
    const referencia = useRef(null)
    const rol = useSelector(state => state.rol);

    useEffect(() => {
        setIsLoading(true)
        consulta.get('/listas')
        .then(data =>{
            setTablaValores(data.data);
            setIsLoading(false);
        })
    }, []);

    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0");
        }
        setClicked(index);
    };

    if (isLoading) {
        return (
            <h1>Cargando</h1>
        )
    }
    return (
        <main className='listas-precios'>
            {tablaValores.map((valores, index) =>
                <section className='section-lista' key={index + 1}>
                    <div className='header-lista'>
                        <h3 className='titulo-lista'>Lista {valores.nombre}</h3>
                        {rol === "Administrador" &&
                        <Descuento
                            listaId={valores.id}
                            vencimiento={valores.vencimiento}
                        />}
                    </div>
                    <div>
                        <div>
                            {valores.monotributo.length > 0 &&
                                <article>
                                    <AcordeonItem
                                        onToggle={() => handleToggle(17 + index)}
                                        active={clicked === 17 + index}
                                        key={17 + index}
                                        valor={valores.monotributo}
                                        referencia={referencia}
                                        titulo="Monotributo"
                                    />
                                </article>
                            }
                        </div>
                        <div>
                            {valores.relacionDependencia.length > 0 &&
                                <article>
                                    <AcordeonItem
                                        onToggle={() => handleToggle(8 + index)}
                                        active={clicked === 8 + index}
                                        key={8 + index}
                                        valor={valores.relacionDependencia}
                                        referencia={referencia}
                                        titulo="Relacion de dependencia"
                                    />
                                </article>
                            }
                        </div>

                        <div>
                            {valores.voluntarios.length > 0 &&
                                <article>
                                    <AcordeonItem
                                        onToggle={() => handleToggle(2 + index)}
                                        active={clicked === 2 + index}
                                        key={2 + index}
                                        valor={valores.voluntarios}
                                        referencia={referencia}
                                        titulo="Voluntarios"
                                    />
                                </article>
                            }
                        </div>
                    </div>
                </section>
            )
            }
        </main >
    )
}

export default Listas