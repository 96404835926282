import React from 'react'
import logoAfip from '../../../../assets/logo-afip.png'
import qr from '../../../../assets/qr.png'
import '../Styles/comprobanteVista.css'

function ComprobanteVista() {
  return (
    <div className='Comprobante-Vista'>
        <section className='Comprobante-impuestos'>
            <article className='tabla-comprobante-impuestos'>
                <h4>Detalle de otros tributos:</h4>
                <div className='Header-tabla-Comprobante'>
                    <h5>Descripción</h5>
                    <h5>Importe</h5>
                </div>
                <div className='valores-tabla-Comprobante'>
                    <h5>Per./Ret de IVA</h5>
                    <h5>$0,00</h5>
                </div>
                <div className='valores-tabla-Comprobante'>
                    <h5>Per./Ret de IIBB</h5>
                    <h5>$0,00</h5>
                </div>
                <div className='valores-tabla-Comprobante'>
                    <h5>Per./Ret de IIBB</h5>
                    <h5>$0,00</h5>
                </div>
                <div className='valores-tabla-Comprobante'>
                    <h5>Impuestos internos</h5>
                    <h5>$0,00</h5>
                </div>
            </article>
            <article className='informacion-comprobante'>
                <div className='qr'>
                    <img src={qr} alt="" />
                </div>
                <div className='afip'>
                    <img src={logoAfip} alt="Logo Afip" />
                    <h4>Comprobante Autorizado</h4>
                    <p>Esta Administracón Federal no se responzabiliza por los datos ingresados en el detalle de la operación</p>
                </div>
                <div className='CAE'>
                    <h4>CAE N° : 000000000000000000</h4>
                    <h4>Fecha vencimiento CAE: 00/00/0000</h4>
                </div>
            </article>
        </section>
        <section className='Comprobante-Bonificaciones'>
            <article>
                <div className='Bloque-Tabla-Comprobante'>
                    <div className='Bloque-Tabla-Comprobante-Cuerpo'>
                        <h5>Bonificación General</h5>
                        <h6>$0,00</h6>
                    </div>
                    <div className='Bloque-Tabla-Comprobante-Cuerpo'>
                        <h5>Subtotal</h5>
                        <h6>$500,00</h6>
                    </div>
                    <div className='Bloque-Tabla-Comprobante-Cuerpo'>
                        <h5>Importe Otros Tributos</h5>
                        <h6>$0,00</h6>
                    </div>
                    <div className='Bloque-Tabla-Comprobante-Footer'>
                        <h5>Importe Total</h5>
                        <h6>$500,00</h6>
                    </div>
                </div>
            </article>
        </section>
    </div>
  )
}

export default ComprobanteVista