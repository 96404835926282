import React, { useState, useEffect } from 'react'
import '../Styles/modalsaldo.css'
import { parsearFecha } from '../../../../utils/funciones'
import Loading from '../../../../components/partials/Loading'
import consulta from '../../../../utils/consulta'

function ModalSaldo({ selectedSaldo, cerrarModal }) {

    const [loading, setLoading] = useState(false)
    const [ValoresPlan, setValoresPlan] = useState({
        id: 0,
        valorFinal: 0,
        valorContrato: 0,
        periodo: 0,
        gf: 1,
        personas: [],
        titular: 0,
        descuentos: '$0',
        adicionales: '$0',
        contratoId: 0,
    })
    const [Aportes, setAportes] = useState({
        id: 0,
        valor: 0,
        periodo: 0,
        personas:[],
        gf: 1,
        titular: 0,
        contratoId: 0,
    })
    const [Pagos, setPagos] = useState(null)

    console.log("Valores Plan -->",ValoresPlan);
    console.log("Aportes -->",Aportes);

    const consultarDatos = async () => {
        try {
            setLoading(true)
            let urlConsulta = '/informacion/api/saldos/reporte/cuenta'
            let response = await consulta.post(urlConsulta, selectedSaldo)
            console.log(response);
            setValoresPlan(response.data.valorPlan)
            setAportes(response.data.aportes)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    const consultarPlanesDePago = async () => {
        try {
            setLoading(true)
            let urlConsulta = '/informacion/api/saldos/reporte/planes'
            let response = await consulta.post(urlConsulta, selectedSaldo)
            console.log(response);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }

    const eliminarSaldo = async (e, idSaldo) => {
        
    }
    const toggleRevision = async (e, idSaldo) => {
        
    }

    useEffect(() => {
        if (selectedSaldo.planDePago === 1) {
            consultarPlanesDePago()
        }else{
            consultarDatos()
        }
    }, [])
    
    if (loading) {
        return (
            <div className='modal-saldo'>
                <div className='modal-background' onClick={cerrarModal}></div>
                <div className='modal loading'>
                    <Loading texto='Cargando Saldo' />
                </div>
            </div>
        )
    }

    return (
        <div className='modal-saldo'>
            <div className='modal-background' onClick={cerrarModal}></div>
            <div className='modal'>
                <div className='modal-bloque'>
                    <h3>Detalle del Saldo {parsearFecha(selectedSaldo.periodo)}</h3>
                    <div className='modal-contrato'>
                        {ValoresPlan && (
                            <div className='modal-valorPlan'>
                                <h3>Valor Plan: ${ValoresPlan.valorContrato} + {ValoresPlan.adicionales} - {ValoresPlan.descuentos}</h3>
                            </div>
                        )}
                        {Aportes && (
                            <div className='modal-aportes'>
                                <h4>Aportes: ${Aportes.valor} </h4>
                                {Aportes.personas.map(ap => {
                                    <h4>Cuil: {ap.cuil} - {ap.obra} - {ap.valor}  - {ap.tipo} </h4>
                                })}
                            </div>
                        )}
                    </div>
                    <div className='modal-saldo'>
                        <h3>Calculo</h3>
                        <div className="modal-data">
                            <p>+ Valor Plan:</p>
                            <p>${selectedSaldo.valorPlanContrato}</p>
                        </div>
                        <div className="modal-data">
                            <p>- Aporte:</p>
                            <p>${selectedSaldo.AporteContrato}</p>
                        </div>
                        <div className="modal-data">
                            <p>+ Adicionales: </p>
                            <p>{selectedSaldo.adicionales}</p>
                        </div>
                        <div className="modal-data">
                            <p>- Descuentos: </p>
                            <p>{selectedSaldo.descuentos}</p>
                        </div>
                        <div className="modal-data valor-final">
                            <p>Valor Final</p>
                            <p>${selectedSaldo.valor}</p>
                        </div>
                    </div>
                    <div className='modal-buttons'>
                        <button onClick={cerrarModal} className='eliminar'>Eliminar Saldo</button>
                        <button onClick={cerrarModal}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSaldo