import React, { useState } from 'react';
import Pasarela from './Pasarela';
import Loading from '../../../components/partials/Loading';
import consulta from '../../../utils/consulta';
import CargaSaldos from './CargaSaldos';

function CargaManual() {

    const [cuil, setCuil] = useState("0")
    const [nombre, setNombre] = useState("")
    const [Gf, setGf] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [page, setpage] = useState("cuil")
    const [saldos, setSaldos] = useState([])
    const [saldoSeleccionado, setSaldoSeleccionado] = useState([]);
    const [seleccionarTodo, setSeleccionarTodo] = useState(false);
    const [deuda, setDeuda] = useState(0)
    const [total, setTotal] = useState(0)
    const [restante, setRestante] = useState(0)
    const [errores, setErrores] = useState("")

    const BuscarSaldoContrato = async (e) => {
        try {
            e.preventDefault()
            if (cuil.length === 11) {
                setIsLoading(true)
                let urlAporteUtilizado = `/informacion/api/saldos/impagos?titular=${cuil}`
                let response = await consulta.get(urlAporteUtilizado)
                console.log(response.data);
                setSaldos(response.data)
                setNombre(response.meta.nombreAfiliado)
                setGf(+response.meta.gf)
                setDeuda(calcularTotalDeuda(response.data))
                setpage("cargaSaldos")
                setErrores("")
                setIsLoading(false)
            } else {
                throw new Error("Error con los datos")
            }
        } catch (error) {
            console.log(error)
            setErrores(error.message)
            setIsLoading(false)
        }
    }
    const volverAtras = async (e) => {
        setIsLoading(true)
        setSeleccionarTodo(false)
        setCuil("0")
        setpage("cuil")
        setSaldos([])
        setSaldoSeleccionado([])
        setDeuda(0)
        setTotal(0)
        setRestante(0)
        setErrores("")
        setIsLoading(false)
    }
    const calcularTotalDeuda = (saldos) => {
        const totalSaldo = saldos.reduce((acumulador, objeto) => {
            // Comprueba si el objeto tiene la clave "valor"
            if (objeto.hasOwnProperty("valor")) {
                acumulador += objeto.valor;
            }
            return acumulador;
        }, 0);
        return totalSaldo
    }

    if (isLoading) {
        return (
            <div>
                <Loading texto={"Cargando Pagos..."} />
            </div>
        )
    }

    if (page === "pasarela") {
        return (
            <div>
                <Pasarela
                    nombre={nombre}
                    saldoSeleccionado={saldoSeleccionado}
                    setSaldoSeleccionado={setSaldoSeleccionado}
                    calcularTotalDeuda={calcularTotalDeuda}
                    volverAtras={volverAtras}
                />
            </div>
        )
    }

    if (page === "cargaSaldos") {
        return (
            <CargaSaldos
                calcularTotalDeuda={calcularTotalDeuda}
                volverAtras={volverAtras}
                saldos={saldos}
                setpage={setpage}
                Gf={Gf}
                nombre={nombre}
                cuil={cuil}
                saldoSeleccionado={saldoSeleccionado}
                setSaldoSeleccionado={setSaldoSeleccionado}
                seleccionarTodo={seleccionarTodo}
                setSeleccionarTodo={setSeleccionarTodo}
                deuda={deuda}
                setDeuda={setDeuda}
                total={total}
                setTotal={setTotal}
                restante={restante}
            />
        )
    }

    return (
        <div className='ValorPlan'>
            <section className='seccion-busqueda'>
                <h2>Pago Manual de Saldos</h2>
                <div className='Busqueda'>
                    <input
                        id='search'
                        name='search'
                        className='form-control inputBuscar'
                        onChange={(e) => setCuil(e.target.value)}
                        placeholder="Ingresá un Cuil"
                        type="text" />
                    <div className='botones'>
                        <button className='valor-Plan-Individual-button' onClick={BuscarSaldoContrato}>Buscar Saldos</button>
                    </div>
                    {errores !== "" &&
                        <article>
                            <small>{errores}</small>
                        </article>}
                </div>
            </section>
        </div>
    )
}

export default CargaManual