import React, { useState } from "react";
import axios from "axios";

const AportesUtilizados = () => {
	const [formData2, setFormData2] = useState({
		cuil: "",
		obraSocial: "",
		tipoDeAporte: "",
		caso: "",
		periodoOrigen: "",
		periodoImpacto: "",
	});

	const [formSubmitted, setFormSubmitted] = useState(false);
	const [errors, setErrors] = useState({});

	const validateForm = () => {
		const validationErrors = {};
		if (formData2.cuil.trim() === "") {
			validationErrors.cuil = "El Cuil es requerido";
		}
		if (formData2.obraSocial.trim() === "") {
			validationErrors.obraSocial = "La Obra Social es requerida";
		}
		if (formData2.tipoDeAporte.trim() === "") {
			validationErrors.tipoDeAporte = "El tipo de Aporte es requerido";
		}
		if (formData2.caso.trim() === "") {
			validationErrors.caso = "El Caso es requerido";
		}
		if (formData2.periodoOrigen.trim() === "") {
			validationErrors.periodoOrigen = "La periodo de Origen es requerido";
		}
		if (formData2.periodoImpacto.trim() === "") {
			validationErrors.periodoImpacto = "El Periodo de Impacto es requerido";
		}

		return validationErrors;
	};

	const [selectedMonth, setSelectedMonth] = useState("");

	const handleSubmit = async () => {
		const validationErrors = validateForm();
		if (Object.keys(validationErrors).length === 0) {
			try {
				const dataFormulario = {
					FormData: formData2,
				};

				setFormData2({
					cuil: "",
					obraSocial: "",
					tipoDeAporte: "",
					caso: "",
					periodoOrigen: "",
					periodoImpacto: "",
				});
				setErrors({});

				console.log("este es el dataFormulario--->", dataFormulario);
				const response = await axios.get(
					"https://fiscalizacion.createch.com.ar",
					dataFormulario
				);

				console.log(
					"Datos enviados al servidor: dataFormulario--->",
					dataFormulario
				);

				// Redirección al usuario a la página que corresponda:

				const respuestaPeticion = response.data.data.url;
				console.log("este es la respuestaPetición--->", respuestaPeticion);
				window.location.href = respuestaPeticion;

				setFormSubmitted(true);
			} catch (error) {
				console.error("Error al enviar el Form:---> ", error);
			}
		} else {
			setErrors(validationErrors);
			console.log("errores de validación: " + JSON.stringify(validationErrors));
			setFormSubmitted(false);
		}
	};

	return (
		<div className="Modificaciones">
			<h2>Formulario Cta Cte xddd d d d d d111!!!</h2>
			<form onSubmit={(e) => e.preventDefault()} className="formContainer">
				<div className="select-container">
					<div className="inputContainer">
						<input
							type="text"
							placeholder="Cuil"
							value={formData2.cuil}
							onChange={(event) =>
								setFormData2((prevData) => ({
									...prevData,
									cuil: event.target.value,
								}))
							}
						/>
						{errors.cuil && (
							<span className="error-message">{errors.cuil}</span>
						)}
					</div>
				</div>
				<div>
					<label htmlFor="obraSocial"></label>
					<div className="select-container">
						<select
							id="obraSocial"
							name="obraSocial"
							value={formData2.obraSocial}
							onChange={(event) =>
								setFormData2((prevData) => ({
									...prevData,
									obraSocial: event.target.value,
								}))
							}
						>
							<option value="">Selecciona una obra social</option>
							<option value="oseara">Oseara</option>
							<option value="ospose">Ospose</option>
							<option value="osmedica">Osmedica</option>
							<option value="oscica">Oscica</option>
						</select>
						{errors.obraSocial && (
							<span className="error-message">{errors.obraSocial}</span>
						)}
					</div>
				</div>

				<div>
					<label htmlFor="tipoDeAporte"></label>
					<div className="select-container">
						<select
							id="tipoDeAporte"
							name="tipoDeAporte"
							value={formData2.tipoDeAporte}
							onChange={(event) =>
								setFormData2((prevData) => ({
									...prevData,
									tipoDeAporte: event.target.value,
								}))
							}
						>
							<option value="">Selecciona el tipo de aporte</option>
							<option value="Transferido">Transferido</option>
							<option value="Devengado">Devengado</option>
							<option value="Estimado">Estimado</option>
						</select>
						{errors.tipoDeAporte && (
							<span className="error-message">{errors.tipoDeAporte}</span>
						)}
					</div>
				</div>

				<div>
					<label htmlFor="caso"></label>
					<div className="select-container">
						<select
							id="caso"
							name="caso"
							value={formData2.caso}
							onChange={(event) =>
								setFormData2((prevData) => ({
									...prevData,
									caso: event.target.value,
								}))
							}
						>
							<option defaultValue="" hidden>Selecciona un caso</option>
							<option value="sin-aportes">Sin aportes</option>
							<option value="aportes-estimados">Aportes estimados</option>
							<option value="aportes-transferidos">Aportes transferidos</option>
							<option value="aportes-devengados">Aportes devengados</option>
							<option value="mas-de-un-aporte">Más de un aporte</option>
						</select>

						{errors.caso && (
							<span className="error-message">{errors.caso}</span>
						)}
					</div>
				</div>
				<div>
					<label htmlFor="periodoOrigen"></label>
					<div className="select-container">
						<select
							id="periodoOrigen"
							name="periodoOrigen"
							value={formData2.periodoOrigen}
							onChange={(event) =>
								setFormData2((prevData) => ({
									...prevData,
									periodoOrigen: event.target.value,
								}))
							}
						>
							<option value="">Selecciona el período de origen</option>
							<option value="2022">2022</option>
							<option value="2023">2023</option>
							<option value="2024">2024</option>
							<option value="2025">2025</option>
						</select>
						{errors.periodoOrigen && (
							<span className="error-message">{errors.periodoOrigen}</span>
						)}
					</div>
				</div>

				<div>
					<label htmlFor="periodoImpacto"></label>
					<div className="select-container">
						<select
							id="periodoImpacto"
							name="periodoImpacto"
							value={formData2.periodoImpacto}
							onChange={(event) => {
								const selectedMonth = event.target.value;
								setFormData2((prevData) => ({
									...prevData,
									periodoImpacto: selectedMonth,
								}));
								setSelectedMonth(
									event.target.options[event.target.selectedIndex].text
								);
							}}
						>
							<option value="">Selecciona el periodo de impacto</option>
							<option value="01">Enero</option>
							<option value="02">Febrero</option>
							<option value="03">Marzo</option>
							<option value="04">Abril</option>
							<option value="05">Mayo</option>
							<option value="06">Junio</option>
							<option value="07">Julio</option>
							<option value="08">Agosto</option>
							<option value="09">Septiembre</option>
							<option value="10">Octubre</option>
							<option value="11">Noviembre</option>
							<option value="12">Diciembre</option>
						</select>
						{errors.periodoImpacto && (
							<span className="error-message">{errors.periodoImpacto}</span>
						)}
					</div>
				</div>
				<div className="botones">
					{/* Agrega los demás campos del formulario aquí */}
					<button type="submit" onClick={handleSubmit}>
						Buscar aportes individuales
					</button>
					<button type="submit" onClick={handleSubmit}>
						Buscar aportes Contrato
					</button>
				</div>
			</form>
		</div>
	);
};

export default AportesUtilizados