import React, { useState } from 'react'
import Loading from '../../../components/partials/Loading'
import ExcelButton from '../../../components/partials/Excel/ExcelButton'
import consulta from '../../../utils/consulta'
import './styles/aportes.css'
import TablaAportes from './components/TablaAportes'

function InfoAportes() {
	const [cuil, setCuil] = useState("0")
	const [isLoading, setIsLoading] = useState(false)
	const [siguiente, setSiguiente] = useState(false)
	const [vista, setVista] = useState("Aportes Contrato")
	const [errorAporte, setErrorAporte] = useState("")

	/* Valor informado por el cliente */
	const [Padron, setPadron] = useState({})
	const [aportesManuales, setAportesManuales] = useState([])
	const [aportesNetos, setAportesNetos] = useState([])
	const [aportesIndividuales, setAportesIndividuales] = useState([])
	const [aportesContrato, setAportesContrato] = useState([])

	const busquedaAportes = async (e) => {
		try {
			setIsLoading(true)
			e.preventDefault()
			const urlAportes = `/informacion/api/aportes?titular=${cuil}`
			const response = await consulta.get(urlAportes)
			setPadron(response.data.Padron)
			setAportesManuales(response.data.aportesManuales.sort((a, b) => b.periodo - a.periodo))
			setAportesNetos(response.data.aportesNetos.sort((a, b) => b.periodo - a.periodo))
			setAportesIndividuales(response.data.aportesIndividuales.sort((a, b) => b.periodo - a.periodo))
			setAportesContrato(response.data.aportesContrato.sort((a, b) => b.periodo - a.periodo))
			setSiguiente(true)
			setIsLoading(false)
		} catch (error) {
			console.log(error);
			setErrorAporte(error.message)
			setIsLoading(false)
		}
	}

	const volverAtras = (e) => {
		e.preventDefault()
		setCuil('')
		setSiguiente(false)
	}

	if (isLoading) {
		return (
			<Loading texto={"Cargando Aportes"} />
		)
	}

	if (siguiente) {
		return (
			<section className="VistaAportes">
				<h2>Aportes de {cuil}:
					<span className='nombrePadron'>
						{Padron.apellnombAfilado}
					</span>
				</h2>

				<article className='Valores-Informados'>
					{/* Tabla */}
					<TablaAportes aportes={aportesContrato} />
				</article>
			</section>
		)
	}

	return (
		<div className='InfoAportes'>
			<section className='seccion-busqueda'>
				<h2>Busqueda de Aportes</h2>
				<form className="Busqueda" onSubmit={busquedaAportes}>
					<div className="formData">
						<input
							id='search'
							name='search'
							className='form-control inputBuscar'
							onChange={(e) => setCuil(e.target.value)}
							placeholder="Ingresá un Cuil"
							type="text"
						/>
					</div>
					{errorAporte && <small className='error-busqueda'>{errorAporte}</small>}
					<button className='searchButton' type='submit'>Buscar</button>
				</form>
			</section>
		</div>
	);
}


export default InfoAportes