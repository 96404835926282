import React, { useState, useEffect } from 'react'
import consulta from '../../../../../utils/consulta'

/* Componentes */
import Loading from '../../../../../components/partials/Loading'
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator'
import ExcelButton from '../../../../../components/partials/Excel/ExcelButton'
import Swal from 'sweetalert2'
import { fechaMesDia, pasarNumeroAMoneda } from '../../../../../utils/funciones'

function Mastercard({ volverAtras }) {

    const columnasArr = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'titular', headerName: 'CUIL', width: 110 },
        { field: 'gf', headerName: 'GF', width: 90 },
        { field: 'valor', headerName: 'Valor', width: 120 },
        { field: 'periodo', headerName: 'periodo', width: 100 },
        { field: 'tipoDeCobranza', headerName: 'Tipo De Cobranza', width: 140 },
        { field: 'pagados', headerName: 'Pagado', width: 140 },
        { field: 'CAE', headerName: 'CAE', width: 100 },
        { field: 'nroFactura', headerName: 'nroFactura', width: 100 },
        { field: 'planDePago', headerName: 'Plan De Pago', width: 100 },
        { field: 'contratoId', headerName: 'Contrato Id', width: 100 },
    ]
    const [loading, setloading] = useState(false)
    const [Reportes, setReportes] = useState([])
    const [cantidad, setCantidad] = useState(0)
    const [cantidadFiltrada, setCantidadFiltrada] = useState(0)
    const [sumaValor, setSumaValor] = useState(0)

    useEffect(() => {
        let fechData = async () => {
            try {
                setloading(true);
                let master = '/lotes/api/master/revision'
                let response = await consulta.post(master)
                setReportes(response.data);
                setCantidad(response.meta.saldos);
                setSumaValor(response.meta.sumaTotal);
                setCantidadFiltrada(response.meta.saldosFiltrados);
                setloading(false);
            } catch (error) {
                console.log(error);
                setloading(false);
            }
        }

        fechData()
    }, [])

    const cargarFacturasUsuariosMaster = async () => {
        try {
            let urlFacturasMaster = '/lotes/api/master/facturas'
            let result = await Swal.fire({
                title: `Esta seguro que desea generar ${cantidad - cantidadFiltrada} Facturas de MasterCard?`,
                showCancelButton: true,
                confirmButtonText: 'Generar',
            })

            if (result.isConfirmed) {
                let response = await consulta.post(urlFacturasMaster)
                const downloadLink = document.createElement('a');
                const blob = new Blob([response], { type: 'text/plain' });
                const url = window.URL.createObjectURL(blob);
                downloadLink.href = url;
                downloadLink.download = 'DA186D.txt';
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Factura no encontrada",
                showConfirmButton: false,
                timer: 1000,
            });
        }
    }

    const cargarReporteUsuariosMaster = async () => {
        try {
            let urlTXT = '/lotes/api/master/generar'
            let result = await Swal.fire({
                title: `Se esta generando un lote por ${cantidadFiltrada} saldos, de Mastercard?`,
                showCancelButton: true,
                confirmButtonText: 'Generar',
            })

            if (result.isConfirmed) {
                let response = await consulta.post(urlTXT)

                if (response.status === 200) {
                    console.log(response);
                    Swal.fire(`¡Facturas generadas! \n ${response.meta.saldosFiltrados}`, '', 'success')
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Error al generar " + response.status,
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    if (loading) {
        return (
            <Loading texto={"Cargando Mastercard ..."} />
        )
    }

    return (
        <div className='Funciones Tabla-Padron'>
            <div className='header-funciones'>
                <button className='back-button' onClick={() => volverAtras()}>Atras</button>
            </div>
            <h2>MASTERCARD</h2>
            <section className='tablaPadron-section-header'>
                <h2 className='titulo-seccion'>Saldos Totales: {cantidad}</h2>
                <h2 className='titulo-seccion'>Saldos Filtrados: {cantidadFiltrada}</h2>
                <h2 className='titulo-seccion'>Valor Total: {pasarNumeroAMoneda(sumaValor)}</h2>
            </section>
            <div>
                <div>
                    {Reportes.length > 0 &&
                        <TableGenerator columns={columnasArr} data={Reportes} />
                    }
                    <div className='buttons'>
                        <ExcelButton data={Reportes} nombreHoja={`Mastercard`} nombreArchivo={`Mastercard Reporte ${fechaMesDia()}`} />
                    </div>
                </div>
                <div>
                    <button className="buttonVisa" onClick={() => cargarFacturasUsuariosMaster()}>
                        Generar Facturas Mastercard Faltantes
                    </button>
                    <button className="buttonVisa" onClick={() => cargarReporteUsuariosMaster()}>
                        Generar Reporte TXT Mastercard
                    </button>
                </div>
            </div>
        </div>
    )
}


export default Mastercard