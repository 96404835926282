import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2';

function NotificadorAndes() {

    const [isLoading, setIsLoading] = useState(false)
    const [Actualizar, setActualizar] = useState(false)
    const [texto, setTexto] = useState([])

    useEffect(() => {
        setIsLoading(true)
        const urlNotificadorJSON = "https://cotizador.createch.com.ar/notificador"
        axios.get(urlNotificadorJSON)
            .then(response => {
                setTexto(response.data.data)
                setIsLoading(false)
            })
    }, [Actualizar])

    const handleEdit = (index, editedText, deleteItem) => {
        const updatedTexto = [...texto];

        if (deleteItem) {
            updatedTexto.splice(index, 1); // Elimina el objeto del array
        } else {
            updatedTexto[index].mensaje = editedText; // Actualiza el texto del objeto
        }

        setTexto(updatedTexto);
    };

    const handleAdd = () => {
        const newItem = {
            "mensaje": ""
        };
        setTexto([...texto, newItem]);
    };

    const handleInputChange = (event, index) => {
        const inputValue = event.target.value;
        handleEdit(index, inputValue);
    };

    const handleActualizarDatos = async (e) => {
        e.preventDefault()
        const datos = {
            notificador: texto
        }
        const urlNotificadorEditar = "https://cotizador.createch.com.ar/notificador/editar"
        await Swal.fire({
            title: '¿Esta seguro de editar estos datos?',
            showDenyButton: true,
            confirmButtonText: 'Aceptar',
            denyButtonText: `Cancelar`,
          }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              let response = await axios.put(urlNotificadorEditar, datos)
              if (response.status === 200) {
                setActualizar(!Actualizar)
                Swal.fire('Se edito la lista utilizable', '', 'success')
              } else {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: "Error al editar" + response.status,
                  showConfirmButton: false,
                  timer: 1500
                })
              }
            } else if (result.isDenied) {
              Swal.fire('No se edito el elemento', '', 'info')
            }
          })
    }

    if (isLoading) {
        return (
            <h1>Cargando</h1>
        )
    }
    return (
        <main className='Notificador-Web-Andes'>
            <h1>Notificador</h1>
            <section className='section-textos'>
                    {texto.map((item, index) => (
                        <div key={index} className='contenedor-notificador-texto'>
                            <textarea value={item.mensaje} onChange={(event) => handleInputChange(event, index)} />
                            <button onClick={() => handleEdit(index, null, true)}>Eliminar Parrafo</button>
                        </div>
                    ))}
                    <div className="button-agregar">
                        <button onClick={handleAdd} className='agregar'><p>Agregar Parrafo</p><i class="fas fa-plus-circle"></i></button>
                    </div>
            </section>
            <article className='Guardar-Cambios'>
                <button onClick={handleActualizarDatos}>Guardar Cambios</button>
            </article>
        </main>
    )
}

export default NotificadorAndes