import React, { useState } from 'react'

function HeaderResumen({ toggleFormat, setToggleFormat }) {

    const cambiarValores = (e) => {
        e.preventDefault()
        if (toggleFormat === 'list') {
            setToggleFormat('cards')
        } else {
            setToggleFormat('list')
        }
    }

    return (
        <article className='header-resumen'>
            <div>
                <h3>Grupo familiar: 3</h3>
            </div>
            <div className='formulario'>
                <div className="formData">
                    <div className='formData-checkbox'>
                        <label htmlFor="planesDePago">Planes de Pago</label>
                        <input type="checkbox" name="planesDePago" id="planesDePago" className='checkbox' />
                    </div>
                    <div className='formData-checkbox'>
                        <label htmlFor="revision">Revision</label>
                        <input type="checkbox" name="revision" id="revision" className='checkbox' />
                    </div>
                    <div className='formData-checkbox'>
                        <label htmlFor="pagado">Pagados</label>
                        <input type="checkbox" name="pagado" id="pagado" className='checkbox' />
                    </div>
                    <div className='formData-checkbox'>
                        <label htmlFor="saldoPeriodo">Saldo Periodo</label>
                        <input type="checkbox" name="saldoPeriodo" id="saldoPeriodo" className='checkbox' />
                    </div>
                    {toggleFormat === "list" ?
                        <button onClick={cambiarValores}>Tarjetas</button>
                        :
                        <button onClick={cambiarValores}>Lista</button>
                    }
                </div>
            </div>
        </article>
    )
}

export default HeaderResumen