import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

/* Componentes */
import Listas from './Listas/Listas';
import ListasCotizador from './ListasCotizadores/ListasCotizador';
import 'react-tabs/style/react-tabs.css';
import Ejemplos from './descargarEjemplos/Ejemplos';
import Cotizador from './Cotizador/Cotizador';
import NotificadorAndes from './NotificadorWebAndes/NotificadorAndes';
import Padrones from './Padrones/Padrones';
<script src="https://unpkg.com/react-tabs/dist/react-tabs.development.js" />

export default function Main() {
    
    const state = useSelector(state => state);
    const [rol, setRol] = useState(state.rol)

    useEffect(() => {
        setRol(state.rol)
    }, [state]);

    return (
        <main className='herramientas'>
            <section className='herramientas-section'>
                <h1>
                    Seleccione su herramienta
                </h1>
                <Tabs>
                    <TabList>
                        <Tab>Listas de precios</Tab>
                        {(rol === "Marketing" || rol === "Administrador") &&
                            <Tab>Listas de cotizadores</Tab>
                        }
                        <Tab>Cotizador</Tab>
                        {(rol === "Marketing" || rol === "Administrador") &&
                            <Tab>Notificador Home</Tab>
                        }

                        {rol === "Administrador" &&
                            <Tab>Padrones</Tab>
                        }
                        <Tab>Manuales de Uso</Tab>
                    </TabList>

                    <TabPanel>
                        <Listas />
                    </TabPanel>

                    {(rol === "Marketing" || rol === "Administrador") &&
                        <TabPanel>
                            <ListasCotizador />
                        </TabPanel>
                    }
                    <TabPanel>
                        <Cotizador />
                    </TabPanel>

                    {(rol === "Marketing" || rol === "Administrador") &&
                        <TabPanel>
                            <NotificadorAndes />
                        </TabPanel>
                    }

                    {rol === "Administrador" &&
                        <TabPanel>
                            <Padrones />
                        </TabPanel>
                    }
                    <TabPanel>
                        <Ejemplos />
                    </TabPanel>

                </Tabs>
            </section>
        </main>
    )
}
