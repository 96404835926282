import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';

export default function DataTable({ columns, data }) {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setIsLoading(false);
    }, [data]);

    if (isLoading) {
        return (
            <div>
                Cargando...
            </div>
        );
    }

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                getRowId={() => Math.floor(Math.random() * 100000000)}
                pageSize={5}
                rowsPerPageOptions={[5]}
            /* checkboxSelection */
            />
        </div>
    );
}

DataTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired
};