import React, { useState } from 'react';
import Loading from '../../../components/partials/Loading'
import DataDisplay from '../../../components/TableGenerator/DataDisplay'
import ExcelButton from '../../../components/partials/Excel/ExcelButton'
import TableGenerator from '../../../components/TableGenerator/TableGenerator'
import consulta from '../../../utils/consulta'

import './Styles/padrones.css'
import DescargaArchivos from './Componentes/DescargaArchivos';
import Resumen from './Componentes/Resumen';
import Unificaciones from './Componentes/Unificaciones';
import BusquedaPadron from './Componentes/BusquedaPadron';

function Padrones() {
    const [cuil, setCuil] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [datoMostrar, setdatoMostrar] = useState("Nada");
    const [resultadoIndividual, setResultadoIndividual] = useState(null);
    const [resultadoTotal, setResultadoTotal] = useState(null);
    const [errorIndividual, setErrorIndividual] = useState(null);
    const [errorTotal, setErrorTotal] = useState(null);

    const volverAtras = (e) => {
        setIsLoading(true)
        setCuil('')
        setdatoMostrar("Nada")
        setResultadoIndividual(null);
        setResultadoTotal(null);
        setErrorIndividual(null);
        setErrorTotal(null);
        setIsLoading(false)
    }
    const settearColumnas = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'apellnombAfilado', headerName: 'Nombre Cliente', sortable: false, width: 260 },
        { field: 'CUILTitular', headerName: 'CUIL', width: 110 },
        { field: 'IdAfiliado', headerName: 'Id Afiliado', width: 110 },
        { field: 'parentesco', headerName: 'parentesco', width: 90 },
        { field: 'tipoEstado', headerName: 'Tipo Estado', width: 90 },
        { field: 'estado', headerName: 'Estado', width: 90 },
        { field: 'edad', headerName: 'Edad', width: 90 },
        { field: 'sexo', headerName: 'Sexo', width: 90 },
        { field: 'planAfiliado', headerName: 'Plan Afiliado', width: 90 },
        { field: 'subplan', headerName: 'Subplan', width: 90 },
        { field: 'categoria', headerName: 'Categoria', width: 90 },
        { field: 'catAfiliado', headerName: 'Cat Af', width: 90 },
        { field: 'tipoConsumidor', headerName: 'Tipo Consumidor', width: 90 },
        { field: 'OSAndes', headerName: 'OS', width: 90 },
        { field: 'provinciaDom', headerName: 'Provincia', width: 90 },
        { field: 'localidadDom', headerName: 'Localidad', width: 90 },
        { field: 'direccion', headerName: 'Direccion', width: 90 },
        { field: 'mail', headerName: 'Mail', width: 90 },
        { field: 'celular', headerName: 'Celular', width: 90 },
        { field: 'unifica', headerName: 'Unifica', width: 90 },
        { field: 'idContrato', headerName: 'Id Contrato', width: 90 },
    ];

    /* Consulta Panacea */
    const consultarPanacea = async (e) => {
        try {
            e.preventDefault()
            setIsLoading(true)
            let urlIndividual = `/aportes/panacea?periodo=${202401}`
            console.log(urlIndividual);
            const response = await consulta.post(urlIndividual);
            console.log(response);
            if (response.status === 200) {
                setResultadoTotal(response.data);
                setErrorTotal(null); // Limpiar el mensaje de error en caso de éxito
                setdatoMostrar("Panacea")
                setIsLoading(false)
                return
            } else {
                throw new Error("Error con los datos")
            }
        } catch (error) {
            console.error('Error al consultar el padron total:', error.message);
            setErrorTotal('Error al consultar el padron total');
            setIsLoading(false)
        }
    };
    const CategorizacionDeConsumos = async (e) => {
        try {
            e.preventDefault()
            setIsLoading(true)
            let urlIndividual = `/aportes/consumos?periodo=${202401}`
            console.log(urlIndividual);
            const response = await consulta.post(urlIndividual);
            console.log(response);
            if (response.status === 200) {
                setResultadoTotal(response.data);
                setErrorTotal(null); // Limpiar el mensaje de error en caso de éxito
                setdatoMostrar("CategorizacionConsumos")
                setIsLoading(false)
                return
            } else {
                throw new Error("Error con los datos")
            }
        } catch (error) {
            console.error('Error al consultar el padron total:', error.message);
            setErrorTotal('Error al consultar el padron total');
            setIsLoading(false)
        }
    };

    if (isLoading) {
        return (
            <Loading texto={"Cargando Padrones"} />
        )
    }
    if (datoMostrar === "Individual") {
        return (
            <div className='Funciones'>
                <div className='header-funciones'>
                    <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
                </div>
                <h3 className='titulo-seccion'>Usuario: {cuil}</h3>
                <DataDisplay data={resultadoIndividual} />
            </div>
        )
    }
    if (datoMostrar === "Total") {
        return (
            <div className='Funciones Tabla-Padron'>
                <div className='header-funciones'>
                    <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
                </div>
                <h2 className='titulo-seccion'>Padron Total</h2>
                <div>
                    <div>
                        {resultadoTotal.length > 0 &&
                            <TableGenerator columns={settearColumnas} data={resultadoTotal} />
                        }
                        <div className='buttons'>
                            <ExcelButton data={resultadoTotal} nombreHoja={'Padron'} nombreArchivo={`Padrones`} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    if (datoMostrar === "Panacea") {
        return (
            <DescargaArchivos datos={resultadoTotal} Archivo={"Panacea"} volverAtras={volverAtras} />
        )
    }
    if (datoMostrar === "CategorizacionConsumos") {
        return (
            <DescargaArchivos datos={resultadoTotal} Archivo={"Categorizacion Consumos"} volverAtras={volverAtras} />
        )
    }
    if (datoMostrar === "Unificacion") {
        return (
            <Unificaciones tipoDeVista={'Modificando'} volverAtras={volverAtras} unificaciones={resultadoTotal} />
        )
    }

    return (
        <main className='Padrones'>

            <h1 className='titulo'>Padrones</h1>

            <section className='Resumen'>
                <Resumen />
            </section>

            <section className='buscarDatos'>
                <BusquedaPadron 
                    cuil={cuil}
                    setCuil={setCuil}
                    setIsLoading={setIsLoading}
                    setdatoMostrar={setdatoMostrar}
                    setResultadoIndividual={setResultadoIndividual}
                    setResultadoTotal={setResultadoTotal}
                    setErrorIndividual={setErrorIndividual}
                    setErrorTotal={setErrorTotal}
                    errorTotal={errorTotal}
                    errorIndividual={errorIndividual}
                    />
            </section>

            <section className='DescargasArchivos'>
                <h3>Descargas Programadas</h3>
                <article>
                    <button onClick={consultarPanacea}>Aportes Panacea</button>
                    <button onClick={CategorizacionDeConsumos}>Categorizacion de consumos</button>
                </article>
            </section>
        </main>
    );
}

export default Padrones;
