const apiConfig = {
    localUrl: 'http://localhost:4000',
    fiscalizacionUrl:'https://fiscalizacion.createch.com.ar',
    ipv4Url: 'https://35.162.93.149',
}

export default apiConfig;

/* 
    let totalTransf = 20
    let remuneracion = totalTransf / 0.0765
    let aporte = remuneracion * 0.0255
    let contribucion = remuneracion * 0.051
    let aporte = totalTransf * ???
    let contribucion = totalTransf * ???
*/