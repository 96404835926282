import React, { useState } from 'react'
import Swal from 'sweetalert2';
import axios from 'axios';

function EditarLista({lista,editarLista,setEditarLista,index}) {

  const [datos, setdatos] = useState({
    tipoPlan:lista.tipoPlan,
    condicion:lista.condicion,
    edad0017:lista.edad0017,
    edad1825:lista.edad1825,
    edad2635:lista.edad2635,
    edad3644:lista.edad3644,
    edad4550:lista.edad4550,
    edad5155:lista.edad5155,
    id:lista.id,
    createdAt:lista.createdAt,
    updatedAt:lista.updatedAt
  })
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    
    const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    const formattedTime = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    
    return `${formattedDate} a las ${formattedTime}`;
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    let urlEditar = `https://cotizador.createch.com.ar/actualizacion/individual/${lista.id}`
    await Swal.fire({
      title: '¿Esta seguro de editar estos datos?',
      showDenyButton: true,
      confirmButtonText: 'Crear',
      denyButtonText: `Cancelar`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let response = await axios.put(urlEditar, datos)
        if (response.status === 200) {
          Swal.fire('Se edito la lista utilizable', '', 'success')
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: "Error al editar" + response.status,
            showConfirmButton: false,
            timer: 1500
          })
        }
      } else if (result.isDenied) {
        Swal.fire('No se edito el elemento', '', 'info')
      }
    })
  }
  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    if ((isNaN(value) || value < 0)) {
      return
    }

    const newValues = {
        ...datos,
        [name]: value,
    };
    setdatos(newValues);
  }
  const handleClick = (valor) => {
      if (editarLista === valor) {
          setEditarLista(0)
      }else{
          setEditarLista(valor)
      }
  }
  return (
    <div className='Background-Change' >
        <form onSubmit={handleSubmit}>
            <h4 onClick={() => handleClick(index)}><i class="fas fa-times"></i></h4>
            <h3>Editar Plan {lista.tipoPlan} {lista.condicion}</h3>
            <ul> 
              <li>
                  <label htmlFor="edad0017">Edad 0-17 </label>
                  <input type="text" name="edad0017" id="edad0017" value={datos.edad0017} onChange={handleChange}/>
              </li>
              <li>
                  <label htmlFor="edad1825">Edad 18-25 </label>
                  <input type="text" name="edad1825" id="edad1825" value={datos.edad1825} onChange={handleChange}/>
              </li>
              <li>
                  <label htmlFor="edad2635">Edad 26-35 </label>
                  <input type="text" name="edad2635" id="edad2635" value={datos.edad2635} onChange={handleChange}/>
              </li>
              <li>
                  <label htmlFor="edad3644">Edad 36-44 </label>
                  <input type="text" name="edad3644" id="edad3644" value={datos.edad3644} onChange={handleChange}/>
              </li>
              <li>
                  <label htmlFor="edad4550">Edad 45-50 </label>
                  <input type="text" name="edad4550" id="edad4550" value={datos.edad4550} onChange={handleChange}/>
              </li>
              <li>
                  <label htmlFor="edad5155">Edad 51-55 </label>
                  <input type="text" name="edad5155" id="edad5155" value={datos.edad5155} onChange={handleChange}/>
              </li>
            </ul>
            <div>
                <button type="submit">Enviar Formulario</button>
            </div>
            <small>Ultima actualizacion {formatDate(lista.updatedAt)}</small>
        </form>
    </div>
  )
}

export default EditarLista