import React from 'react'

function AportesContratos({volverAtras}) {
  return (
    <section>
        <div className="atras">
            <button onClick={volverAtras}>Volver Atras</button>
        </div>
    </section>
  )
}

export default AportesContratos