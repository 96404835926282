import React, { useState, useEffect } from 'react'
import Tr from './Tr';

function Cotizando({ listas, padrones }) {

	let buscarTitular = (arr) => {
		return arr.find(padron => padron.parentesco === 'TITULAR')
	}
	let buscarCategoria = (obj) => {
		let valor = ""
		if (obj.categoria === "REL") {
			valor = 'relacionDependencia'
		} else if (obj.categoria === "MON") {
			valor = 'monotributo'
		} else {
			valor = 'voluntarios'
		}
		return valor
	}
	let buscarPlan = (obj) => {
		let superplan = obj.planAfiliado.substring(0, obj.planAfiliado.indexOf(" "))
		if (superplan === "PLAN") {
			superplan = obj.planAfiliado.substring(11, obj.planAfiliado.lastIndexOf(" "))
		}
		return superplan
	}
	let buscarApEstimado = (obj) => {
		let aporte = Math.trunc(obj.ApEstimado * 0.0765)
		return aporte
	}
	let buscarNombreLista = (subplan) => {
		if (subplan.match(/MS21/)) {
			return 'MINERIA (MS21)'
		} else if (subplan.match(/S21/)) {
			return subplan.match(/S21/)[0]
		} else {
			return "S20"
		}
	}
	let buscarLista = (texto, categoria, plan) => {
		let retornoLista = {}
		let nombreLista = ''
		if (texto.match(/MS21/)) {
			nombreLista = 'MINERIA (MS21)'
		} else if (texto.match(/S21/)) {
			nombreLista = texto.match(/S21/)[0]
		} else {
			nombreLista = "S20"
		}

		listas.forEach(lista => {
			if (lista.nombre === nombreLista) {
				lista[categoria].forEach(categoria => {
					if (categoria.plan.toUpperCase().includes(plan.toUpperCase())) {
						retornoLista = categoria

					}
				})
			}
		});
		return retornoLista
	}
	let valoresPorEdad = (edad, estado, lista) => {
		if (estado === 'BAJA') {
			return 0
		}
		return edad < 18 ? lista.edad0017 : edad >= 18 && edad <= 25 ? lista.edad1825 : edad >= 26 && edad <= 35 ? lista.edad2635 : edad >= 36 && edad <= 44 ? lista.edad3644 : edad >= 45 && edad <= 50 ? lista.edad4550 : edad >= 51 && edad <= 55 ? lista.edad5155 : edad >= 56 && edad <= 60 ? lista.edad5660 : edad >= 61 && edad <= 65 ? lista.edad6165 : edad >= 66 && edad <= 70 ? lista.edad6670 : lista.edad7100
	}
	let valorPlanTotal = (personas, lista) => {
		let valor = 0

		personas.forEach(padron => {
			if (padron.estado !== 'BAJA') {
				valor = valor + valoresPorEdad(padron.edad, padron.estado, lista)
				if (padron.tipoEstado.includes("ALTA PROVISORIA")) {
					valor = +valor - (+valor * 0.2)
				}
			}
		})

		return valor
	}
	let buscarSaldoFinal = (valorPlan, Aportes) => {
		let saldo = 0
		if (valorPlan - Aportes > 0) {
			saldo = valorPlan - Aportes
		} else {
			saldo = 0
		}
		return saldo
	}

	let titular = buscarTitular(padrones)
	let aportesEstimado = buscarApEstimado(titular)
	let [plan, setPlan] = useState(buscarPlan(titular))
	let [categoria, setCategoria] = useState(buscarCategoria(titular))
	let [nombreLista, setNombreLista] = useState(buscarNombreLista(titular.subplan))
	let [lista, setLista] = useState(buscarLista(nombreLista, categoria, plan))
	let [valoresPlan, setValoresPlan] = useState(0)
	let [aportes, setAportes] = useState(0)
	let [saldos, setSaldos] = useState(0)
	let [contador, setContador] = useState(0)

	/* console.log("Padrones --->",padrones)
	console.log("Listas --->",listas)
	console.log("Nombre Lista --->",nombreLista)
	console.log("plan --->",plan)
	console.log("categoria --->",categoria)
	console.log("Lista --->",lista)
	console.log("aportes --->",aportes)
	console.log("aportes Estimados --->",aportesEstimado)
	console.log("valoresPlanFinales --->",valoresPlan)
	console.log("saldos --->",saldos) */

	useEffect(() => {
		let lista = buscarLista(nombreLista, categoria, plan);
		let valoresPlanFinales = valorPlanTotal(padrones, lista);
		let saldos = buscarSaldoFinal(valoresPlanFinales, aportes);
		setLista(lista);
		setValoresPlan(valoresPlanFinales);
		setSaldos(saldos);
	}, [nombreLista, categoria, plan, aportes, padrones]);



	const handleCambiarPlan = (e) => {
		setPlan(e.target.value)
	}
	const handleCambiarCategoria = (e) => {
		setCategoria(e.target.value)
	}
	const handleCambiarNombreLista = (e) => {
		setNombreLista(e.target.value)
	}
	const handleAportes = (e) => {
		const { target } = e;
		const { name, value } = target;
		if (name === "aportesManuales" && (isNaN(value))) {
			return
		}
		setAportes(value)
	}
	const handleContadorAumento = (e) => {
		setContador(contador + 1)
	}
	/* const handleContadorDisminuir = (e) => {
		setContador(contador - 1)
	} */

	return (
		<section className='Tabla-Cotizando'>
			<article className='formulario-modificador'>
				<form>
					<div className='form-data'>
						<label htmlFor="plan">Seleccione una Lista</label>
						<select name="plan" id="plan" onChange={handleCambiarNombreLista}>
							<option defaultValue="S21" hidden>{nombreLista}</option>
							<option value="S21">S21</option>
							<option value="S20">S20</option>
							<option value="MINERIA (MS21)">MS21</option>
						</select>
					</div>
					<div className='form-data'>
						<label htmlFor="plan">Seleccione un plan para cotizar</label>
						<select name="plan" id="plan" onChange={handleCambiarPlan}>
							<option defaultValue="plan" hidden>PLAN {plan}</option>
							<option value="Titanium">Titanium</option>
							<option value="Black">Black</option>
							<option value="Platinum">Platinum</option>
							<option value="Gold">Gold</option>
						</select>
					</div>
					<div className='form-data'>
						<label htmlFor="plan">Seleccione una categoria</label>
						<select name="plan" id="plan" onChange={handleCambiarCategoria}>
							<option defaultValue={categoria} hidden>{categoria === 'relacionDependencia' ? 'Relacion de Dependencia' : categoria === 'monotributo' ? 'Monotributista' : 'voluntarios'}</option>
							<option value="relacionDependencia">Relacion de dependencia</option>
							<option value="monotributo">Monotributista</option>
							<option value="voluntarios">Voluntario</option>
						</select>
					</div>
					<div className='form-data'>
						<label htmlFor="aportesManuales">Indique la cantidad de aportes</label>
						<input type="text" name="aportesManuales" id="aportesManuales" onChange={handleAportes} defaultValue={aportes} />
					</div>
				</form>
			</article>
			<article className='valores-finales'>
				<h2>Valores</h2>
				<div className='valores-individuales'>
					<h4>Valor Plan</h4>
					<p>{valoresPlan > 0 ? `$${valoresPlan}` : "-"}</p>
				</div>
				<div className='valores-individuales'>
					<h4>Aportes Estimados</h4>
					<p>{aportesEstimado > 0 ? `$${aportesEstimado}` : "-"}</p>
				</div>
				<div className='valores-individuales'>
					<h4>Aportes del Contrato</h4>
					<p>${aportes}</p>
				</div>
				<div className='valores-individuales'>
					<h4>Saldo Contrato</h4>
					<p>${saldos}</p>
				</div>
			</article>
			<article>
				<h2>Padrones</h2>
				<table role="table" className='table-block'>
					<thead>
						<tr role="row">
							<th role="columnheader">Cuil</th>
							<th role="columnheader">Nombre</th>
							<th role="columnheader">Parentesco</th>
							<th role="columnheader">Estado</th>
							<th role="columnheader">Tipo Estado</th>
							<th role="columnheader">Tipo Consumidor</th>
							<th role="columnheader">Edad</th>
							<th role="columnheader">Valor Plan</th>
						</tr>
					</thead>
					<tbody>
						{padrones.map((padron, index) =>
							<Tr
								key={padron.id}
								lista={lista}
								padron={padron}
							/>
						)}
					</tbody>
				</table>
			</article>
			{contador > 0 &&
				<article className='Familiares-Agregados'>

					<table role="table" className='table-block'>
						<thead>
							<tr role="row">
								<th role="columnheader">Cuil</th>
								<th role="columnheader">Nombre</th>
								<th role="columnheader">Parentezco</th>
								<th role="columnheader">Estado</th>
								<th role="columnheader">Tipo Estado</th>
								<th role="columnheader">Tipo Consumidor</th>
								<th role="columnheader">Edad</th>
								<th role="columnheader">Valor Plan</th>
							</tr>
						</thead>
						<tbody>
							<tr role="row">
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>-</td>
								<td>$-</td>
							</tr>
						</tbody>
					</table>
				</article>}
			<article className='section-more'>
				<h3>Añadir integrantes</h3>
				<div className='icon-more' onClick={handleContadorAumento}>
					+
				</div>
			</article>

		</section>
	)
}

export default Cotizando