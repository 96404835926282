import React, { useState } from 'react'
import consulta from '../../../utils/consulta'
import FileUploader from '../../../components/Uploader/FileUploader';
import Loading from '../../../components/partials/Loading'
import ExcelButton from '../../../components/partials/Excel/ExcelButton';
import TableGenerator from '../../../components/TableGenerator/TableGenerator'

function CargaDeDescuentos() {
	const [selectedOscicaRel, setSelectedOscicaRel] = useState(null);
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState("")
	const [Aportes, setAportes] = useState([]);
	const [columnas, setColumnas] = useState([])

	const handleOscicaRelSelect = (files) => {
		setSelectedOscicaRel(files[0]);
	};

	const volverAtras = () => {
		setLoading(true)
		setColumnas([])
		setAportes([])
		setSelectedOscicaRel(null)
		setLoading(false)
	}

	const handleUploadClick = async () => {
		try {
			setLoading(true)
			const formData = new FormData();
			if (selectedOscicaRel) {
				formData.append('bonificacion', selectedOscicaRel);
			}
			settearColumnas()
			// Realiza la solicitud POST con axios (o fetch)
			const response = await consulta.post('/reportes/bonificaciones', formData);
			// Maneja la respuesta del servidor si es necesario
			setAportes(response.data)
			setLoading(false)

		} catch (error) {
			// Maneja los errores si es necesario
			console.error('Error:', error);
			setError(error.meta.msg)
			setLoading(false)
		}
	};

	const settearColumnas = () => {
		let columnaAportes = [
			{ field: 'cuilTitular', headerName: 'CUIL', width: 110 },
			{ field: 'adicionales', headerName: 'Adicionales', width: 110 },
			{ field: 'tipoAdicionales', headerName: 'Tipo Adicionales', width: 110 },
			{ field: 'descuento', headerName: 'Descuento', width: 110 },
			{ field: 'tipoDescuento', headerName: 'Tipo Descuento', width: 110 },
			{ field: 'periodoDesde', headerName: 'Desde', width: 110 },
			{ field: 'periodoHasta', headerName: 'Hasta', width: 110 },
		];
		setColumnas(columnaAportes)
	}
	if (loading) {
		return (
			<Loading texto={"Cargando Aportes"} />
		)
	}

	if (Aportes.length > 0) {
		return (
			<div className='ValorPlan'>
				<h2 className='columnas'>¡Aportes Cargados!</h2>
				{Aportes.length > 0 &&
					<TableGenerator columns={columnas} data={Aportes} />
				}
				<div className='botones'>
					<button className='button-back' onClick={volverAtras}>Atras</button>
					<ExcelButton data={Aportes} nombreHoja={"Bonificaciones"} nombreArchivo={`Bonificaciones Generales`} />
				</div>
			</div>
		)
	}

	return (
		<div className="CargaAportes">
			<div className="selected-component">
				<div className="Oscica Obra-Social">
					<h2 className='titulo-seccion'>Carga de Contratos</h2>
					<article className='formularios-aportes'>
						<FileUploader label="Contratos.xlsx" title={"Descuentos y Aumentos Contratos"} onFileSelect={handleOscicaRelSelect} />
					</article>
					{error !== "" && <small>{error}</small>}
					<div className='boton-de-carga'>
						<button className='submit-button' onClick={handleUploadClick}>Cargar Documento</button>
					</div>
				</div>
			</div>
		</div>
	);
}



export default CargaDeDescuentos