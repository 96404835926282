import React, { useEffect, useState } from 'react'
import consulta from '../../../../utils/consulta'
import gif from '../../../../assets/Spin-1s-200px.gif'


function Resumen() {

    const [loading, setLoading] = useState(false)
    const [datos, setDatos] = useState({
        PadronesAlta: 0,
        totalTitanium: 0,
        totalBlack: 0,
        totalPlatinum: 0,
        totalGold: 0,
        totalPlanJoven: 0,
        totalGreen: 0,
        totalPMO: 0,
        totalDiferentes: 0
    })
    const [categorias, setCategorias] = useState({
        totalRel: 0,
        totalMon: 0,
        totalOtr: 0,
        totalAdh: 0,
        totalDOM: 0,
        totalAFT: 0,
        totalALT: 0,
        totalMOD: 0,
        totalDiferentes: 0
    })
    const [mediosPago, setMediosPago] = useState({
        totalEfectivo: 0,
        totalTransferencia: 0,
        totalDebitoVisa: 0,
        totalCreditoVisa: 0,
        totalMastercard: 0,
        totalNaranja: 0,
        totalDiferentes: 0
    })
    const [tipoAportes, setTipoAportes] = useState({
        totalEfectivo: 0,
        totalTransferencia: 0,
        totalDebitoVisa: 0,
        totalCreditoVisa: 0,
        totalMastercard: 0,
        totalNaranja: 0,
        totalDiferentes: 0
    })

    console.log(datos);

    const consultarResumen = async () => {
        try {
            setLoading(true)
            const urlResumen = `/padrones/resumen`
            const response = await consulta.post(urlResumen)

            setDatos(response.data)
            console.log(response);
            setLoading(false)

        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }
    const consultarCategorias = async () => {
        try {
            setLoading(true)
            const urlResumen = `/padrones/resumen/categorias`
            const response = await consulta.post(urlResumen)
            setCategorias(response.data)
            console.log(response);
            setLoading(false)

        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }
    const consultarMediosDePago = async () => {
        try {
            setLoading(true)
            const urlResumen = `/padrones/resumen/mediosPago`
            const response = await consulta.post(urlResumen)
            setMediosPago(response.data)
            console.log(response);
            setLoading(false)

        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }
    const consultarTipoAportes = async () => {
        try {
            setLoading(true)
            const urlResumen = `/padrones/resumen/adicionales`
            const response = await consulta.post(urlResumen)
            setTipoAportes(response.data)
            console.log(response);
            setLoading(false)

        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        consultarResumen()
        consultarCategorias()
        consultarMediosDePago()
        consultarTipoAportes()
    }, [])


    if (loading) {
        return (
            <article className="carga">
                <h3>Cargando Padron ...</h3>
                <img src={gif} alt="loading gif..." />
            </article>
        )
    }

    return (
        <article className="cuadro" >
            <h2>Padrones Totales de Alta: <span className='text-number'>{datos.PadronesAlta}</span></h2>
            <div className='teibol pl'>
                <h3>Resumen Planes</h3>
                <ul>
                    <li>
                        <span className='titanium title'>Titanium:</span>
                        <span className='text-number'>{datos.totalTitanium}</span>
                    </li>
                    <li>
                        <span className='black title'>Black:</span>
                        <span className='text-number'>{datos.totalBlack}</span>
                    </li>
                    <li>
                        <span className='platinum title'>Platinum:</span> 
                        <span className='text-number'>{datos.totalPlatinum}</span>
                    </li>
                    <li>
                        <span className='gold title'>Gold:</span> 
                        <span className='text-number'>{datos.totalGold}</span>
                    </li>
                    <li>
                        <span className='joven title'>Plan Joven:</span> 
                        <span className='text-number'>{datos.totalPlanJoven}</span>
                    </li>
                    <li>
                        <span className='green title'>Green:</span> 
                        <span className='text-number'>{datos.totalGreen}</span>
                    </li>
                    <li>
                        <span className='pmo title'>PMO:</span> 
                        <span className='text-number'>{datos.totalPMO}</span>
                    </li>
                    <li>
                        <span className='diferentes title'>Diferentes:</span> 
                        <span className='text-number'>{datos.totalDiferentes}</span>
                    </li>
                </ul>
            </div>
            <div className='teibol ca'>
                <h3>Resumen Categorias</h3>
                <ul>
                    <li>
                        <span className='titanium title'>Relacion de dependencia:</span> <span className='text-number'>{categorias.totalRel}</span>
                    </li>
                    <li>
                        <span className='titanium title'>Trabajadores Domesticos:</span> <span className='text-number'>{categorias.totalDOM}</span>
                    </li>
                    <li>
                        <span className='black title'>Monotributistas:</span> <span className='text-number'>{categorias.totalMon}</span>
                    </li>
                    <li>
                        <span className='black title'>Monotributista Directo:</span> <span className='text-number'>{categorias.totalMOD}</span>
                    </li>
                    <li>
                        <span className='gold title'>OTROS:</span> <span className='text-number'>{categorias.totalOtr}</span>
                    </li>
                    <li>
                        <span className='gold title'>Adherentes:</span> <span className='text-number'>{categorias.totalAdh}</span>
                    </li>
                    <li>
                        <span className='green title'>Afiliados en transito:</span> <span className='text-number'>{categorias.totalAFT}</span>
                    </li>
                    <li>
                        <span className='pmo title'>Altas Tempranas:</span> <span className='text-number'>{categorias.totalALT}</span>
                    </li>
                    <li>
                        <span className='diferentes title'>Diferentes:</span> <span className='text-number'>{categorias.totalDiferentes}</span>
                    </li>
                </ul>
            </div>
            <div className='teibol mp'>
                <h3>Medios de pago</h3>
                <ul>
                    <li>
                        <span className='black title'>Efectivo:</span> <span className='text-number'>{mediosPago.totalEfectivo}</span>
                    </li>
                    <li>
                        <span className='joven title'>Transferencia:</span> <span className='text-number'>{mediosPago.totalTransferencia}</span>
                    </li>
                    <li>
                        <span className='visa title'>Visa Debito:</span> <span className='text-number'>{mediosPago.totalDebitoVisa}</span>
                    </li>
                    <li>
                        <span className='visa title'>Visa Credito:</span> <span className='text-number'>{mediosPago.totalCreditoVisa}</span>
                    </li>
                    <li>
                        <span className='master title'>Master Card:</span> <span className='text-number'>{mediosPago.totalMastercard}</span>
                    </li>
                    <li>
                        <span className='naranja title'>Naranja:</span> <span className='text-number'>{mediosPago.totalNaranja}</span>
                    </li>
                </ul>
            </div>
            <div className='teibol ap'>
                <h3>Aportes</h3>
                <ul>
                    <li>
                        <span className='green title'>Sin Consumo:</span> <span className='text-number'>{tipoAportes.totalSC}</span>
                    </li>
                    <li>
                        <span className='pmo title'>Altos Consumidores:</span> <span className='text-number'>{tipoAportes.totalAC}</span>
                    </li>
                    <li>
                        <span className='black title'>OSCEARA:</span> <span className='text-number'>{tipoAportes.totalOSCEARA}</span>
                    </li>
                    <li>
                        <span className='black title'>OSPOCE:</span> <span className='text-number'>{tipoAportes.totalOSPOCE}</span>
                    </li>
                    <li>
                        <span className='black title'>OSCICA:</span> <span className='text-number'>{tipoAportes.totalOSCICA}</span>
                    </li>
                    <li>
                        <span className='black title'>OSMEDICA:</span> <span className='text-number'>{tipoAportes.totalOSMEDICA}</span>
                    </li>
                    <li>
                        <span className='platinum title'>OSPAVIAL:</span> <span className='text-number'>{tipoAportes.totalOSPAVIAL}</span>
                    </li>
                    <li>
                        <span className='gold title'>Sin Obra Social:</span> <span className='text-number'>{tipoAportes.sinOS}</span>
                    </li>
                </ul>
            </div>
        </article>
    )
}

export default Resumen