import React, { useState } from 'react'
import AportesNetos from './Componentes/AportesNetos'
import AportesUtilizados from './Componentes/AportesUtilizados'
import AportesContratos from './Componentes/AportesContratos'

import './Styles/modificacion.css'
import CrearAportesNetos from './Componentes/CrearAportesNetos'
import AportesManuales from './Componentes/AportesManuales'

function ModificacionAportes() {
	
	const [Elemento, setElemento] = useState("")

	const volverAtras = (e) => {
		e.preventDefault()
		setElemento("")
	}

	if (Elemento === "ApNetos") {
		return(
			<AportesNetos volverAtras={volverAtras} />
		)
	}
	if (Elemento === "ApUtilizados") {
		return(
			<AportesUtilizados volverAtras={volverAtras}/>
		)
	}
	if (Elemento === "ApContratos") {
		return(
			<AportesContratos volverAtras={volverAtras}/>
		)
	}
	if (Elemento === "CrearApNetos") {
		return(
			<CrearAportesNetos volverAtras={volverAtras}/>
		)
	}
	if (Elemento === "CrearApManuales") {
		return(
			<AportesManuales volverAtras={volverAtras}/>
		)
	}

  return (
	<section className='Modificacion-Aportes'>
		<h2>Seleccione una opcion</h2>

		<div className='modificar'>
			<h2>Aportes</h2>
			<button onClick={() => setElemento("CrearApNetos")}>Crear Aportes Netos</button>
			<button onClick={() => setElemento("ApNetos")}>Editar Aportes Netos</button>
			<button onClick={() => setElemento("CrearApManuales")}>Aportes Manuales</button>
			<button onClick={() => setElemento("ApUtilizados")}>Editar Aportes Utilizados</button>
			<button onClick={() => setElemento("ApContratos")}>Editar Aportes Contrato</button>
		</div>
	</section>
  )
}

export default ModificacionAportes