import React, { useState } from 'react';
import Osmedica from './Componentes/Osmedica';
import Oscica from './Componentes/Oscica';
import Ospoce from './Componentes/Ospoce';
import Osceara from './Componentes/Osceara';
import Resumen from './Componentes/Resumen';
import './Styles/cargaAportes.css'

const CargaDeAportes = () => {
  const options = ['OSCEARA', 'OSPOCE', 'OSMEDICA', 'OSCICA', 'RESUMEN'];
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  let componentToRender = null;

  switch (selectedOption) {
    case 'OSCEARA':
      componentToRender = <Osceara />;
      break;
    case 'OSMEDICA':
      componentToRender = <Osmedica />;
      break;
    case 'OSCICA':
      componentToRender = <Oscica />;
      break;
    case 'OSPOCE':
      componentToRender = <Ospoce />;
      break;
    case 'RESUMEN':
      componentToRender = <Resumen />;
      break;
    default:
      componentToRender = null;
  }

  return (
    <div className="CargaAportes">
      <div className='carga-header'>
        {options.map((option, index) => (
          <button
            key={index}
            className={`option-button ${selectedOption === option ? 'selected' : ''}`}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </button>
        ))}
      </div>
      <div className="selected-component">
        {componentToRender}
      </div>
    </div>
  );
};

export default CargaDeAportes;

