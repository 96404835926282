import React, { useState } from 'react';
import './acordion.css'

const AcordeonTable = ({ data, title = "Aportes por persona" }) => {
    const [expanded, setExpanded] = useState(false);
    const toggleAccordion = () => {
        setExpanded(!expanded);
    };

    const tableHeaders = Object.keys(data[0] || {});

    return (
        <div className="Accordion">
            <div className={`accordion-header ${expanded ? 'expanded' : ''}`} onClick={toggleAccordion}>
                <h3 className="subtitulo-seccion">{title}</h3>
            </div>
            {expanded && (
                <table className={`data-table-container ${expanded ? 'expanded' : ''}`}>
                    <thead>
                        <tr>
                            {tableHeaders.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                {tableHeaders.map((header, index) => (
                                    <td key={index}>{item[header]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AcordeonTable;
