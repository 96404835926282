import React from 'react'
import { NavLink } from 'react-router-dom'
import './Styles/home.css'


function Home() {
	return (
		<main className='home'>
			<section className='section'>
				<h2>¿Que hacemos hoy?</h2>
				<article className='card'>
					<div>
						<NavLink to="/facturacion" >
							<button>Facturacion</button>
						</NavLink>
					</div>
					<div>
						<NavLink to="/aportes" >
							<button>Aportes</button>
						</NavLink>
					</div>
					<div>
						<NavLink to="/pagos" >
							<button>Pagos</button>
						</NavLink>
					</div>
					<div>
						<NavLink to="/cuenta" >
							<button>Cuenta Corriente</button>
						</NavLink>
					</div>
					<div>
						<NavLink to="/valor-plan" >
							<button>Valores Plan</button>
						</NavLink>
					</div>
					<div>
						<NavLink to="/herramientas" >
							<button>Herramientas</button>
						</NavLink>
					</div>
				</article>
			</section>
		</main>
	)
}

export default Home