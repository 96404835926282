import React, { useState } from 'react'

/* Componentes */
import TableGenerator from '../../../components/TableGenerator/TableGenerator'
import Loading from '../../../components/partials/Loading'
import ExcelButton from '../../../components/partials/Excel/ExcelButton'
import consulta from '../../../utils/consulta'

/* Estilos */
import './Styles/libroIva.css'

/* Utilidades */
import { yearNow } from '../../../utils/funciones'

function Libro() {
	const [loading, setLoading] = useState(false)
	const [error, seterror] = useState(false)
	const [periodo, setperiodo] = useState("")
	const [tiempo, settiempo] = useState(12)
	const [provincia, setprovincia] = useState("Todas")
	const [factura, setfactura] = useState("Todas")
	const [cuil, setcuil] = useState("Ninguno")
	const [LibroIva, setLibroIva] = useState([])
	
	const columns = [
		{ field: 'periodo', headerName: 'Fecha Emision', width: 120 },
		{ field: 'nombre', headerName: 'Nombre Cliente',description: 'This column has a value getter and is not sortable.',sortable:false,width: 260},
		{ field: 'titular', headerName: 'CUIL', width: 110},
		{ field: 'CAE', headerName: 'CAE', width: 140 },
		{ field: 'provincia', headerName: 'Provincia', width: 120},
		{ field: 'comprobanteTipo', headerName: 'Tipo IVA', width: 90 },
		{ field: 'impNOGravado', headerName: 'Imp NO Gravado', width: 130 },
		{ field: 'impExento', headerName: 'Imp Exento', width: 90 },
		{ field: 'impGravadoIva1', headerName: 'Imp Gravado 10,5%', description:"21,00%",type: 'number', width: 140},
		{ field: 'impGravadoIva2', headerName: 'Imp Gravado 21%',description:"10,50%", type: 'number', width: 140},
		{ field: 'impGravadoIva3', headerName: 'Imp Gravado 27%',description:"27,00%", type: 'number', width: 140},
		{ field: 'impIva1', headerName: 'Imp IVA 10,5%', type: 'number',description:"21,00%", width: 110},
		{ field: 'impIva2', headerName: 'Imp IVA 21%', type: 'number',description:"10,50%", width: 110},
		{ field: 'impIva3', headerName: 'Imp IVA 27%', type: 'number',description:"27,00%", width: 110},
		{ field: 'impPercGanancias', headerName: 'Imp Perc Ganancias', type: 'number', width: 140},
		{ field: 'impPercIva', headerName: 'Imp Perc Iva', type: 'number', width: 120},
		{ field: 'impIB', headerName: 'Imp IB', type: 'number', width: 90},
		{ field: 'neto', headerName: 'Neto', type: 'number', width: 120},
		{ field: 'ivaTotal', headerName: 'IVA', type: 'number', width: 120},
		{ field: 'total', headerName: 'TOTAL', type: 'number', width: 120},
	];

	const consultarLibro = async (e) => {
		try {
			setLoading(true)
			e.preventDefault()
			if (cuil.length > 11 || cuil === "Ninguno") {
				let obj = {
					titular: cuil,
					periodo,
					tiempo,
					provincia,
					factura
				}
				let urlLibro = "/informacion/api/libroIva"
				console.log(obj);
				let response = await consulta.post(urlLibro,obj)
				console.log("datitos",response.data);
				setLibroIva(response.data)
				setLoading(false)
			}else{
				seterror("El cuil ingresado es incorrecto")
			}
		} catch (error) {
			console.log(error)
		}
	}

	if (loading) {
        return(
            <Loading texto={"Cargando Libro Iva ..."} />
        )
    }
	if (LibroIva.length > 0) {
        return(
            <main className='Libro-Iva'>
				<section className='Libro-Iva-Contenedor'>
					<h3>Libro Iva</h3>
					<TableGenerator
						columns={columns}
						data={LibroIva}
					/>
					<div className='button-excel'>
						<ExcelButton data={LibroIva} nombreHoja={"Libro iva"} nombreArchivo={`Libro iva`} />
					</div>
				</section>
			</main>
        )
    }

	return (
		<main className='Libro-Iva'>
			<section className='Libro-Iva-Contenedor'>
				<h3>Retornar Libro Iva {"(Ningún dato es obligatorio)"}</h3>
				<form onSubmit={consultarLibro}>	
					<div className='formData'>
						<label htmlFor="periodo">Periodo {yearNow()}</label>
						<select name="periodo" id="periodo" className='select-input' onChange={(e) => setperiodo(e.target.value)}>
							<option defaultValue="" hidden>Mes tenido en cuenta</option>
							<option value={`${yearNow()}01`}>Enero</option>
							<option value={`${yearNow()}02`}>Febrero</option>
							<option value={`${yearNow()}03`}>Marzo</option>
							<option value={`${yearNow()}04`}>Abril</option>
							<option value={`${yearNow()}05`}>Mayo</option>	
							<option value={`${yearNow()}06`}>Junio</option>
							<option value={`${yearNow()}07`}>Julio</option>
							<option value={`${yearNow()}08`}>Agosto</option>
							<option value={`${yearNow()}09`}>Septiembre</option>
							<option value={`${yearNow()}10`}>Octubre</option>
							<option value={`${yearNow()}11`}>Noviembre</option>
							<option value={`${yearNow()}12`}>Diciembre</option>
						</select>
					</div>
					<div className='formData'>
						<label htmlFor="cuil">Cuil Afiliado</label>
						<input type="text" name="cuil" id="cuil" className={error ? "invalid" : "valid"} onChange={(e) => setcuil(e.target.value)} />
					</div>
					<div className='formData'>
						<label htmlFor="factura">Tipo Factura</label>
						<select name="factura" id="factura" className='select-input' onChange={(e) => setfactura(e.target.value)}>
							<option defaultValue="" hidden>Puede modificar el año</option>
							<option value="A">A</option>
							<option value="B">B</option>
							<option value="C">C</option>
							<option value="Todas">Todas</option>
						</select>
					</div>
					<div className='formData'>
						<label htmlFor="provincia">Provincia</label>
						<select name="provincia" id="provincia" className='select-input' onChange={(e) => setprovincia(e.target.value)}>
							<option defaultValue="" hidden>Seleccione una provincia</option>
							<option value="Mendoza">Mendoza</option>
							<option value="San Juan">San Juan</option>
							<option value="San Luis">San Luis</option>
							<option value="Todas">Todas</option>
						</select>
					</div>
					<div className='formData errores'>
						<small>{error}</small>
					</div>
					<div className='formData'>
						<label htmlFor="tiempo">Año</label>
						<select name="tiempo" id="tiempo" className='select-input' onChange={(e) => settiempo(e.target.value)}>
							<option defaultValue="" hidden>Puede modificar el año</option>
							<option value="09">2022</option>
							<option value="10">2023</option>
							<option value="11">2024</option>
							<option value="12">2025</option>
							<option value="">Todos</option>
						</select>
					</div>
					<div className='buttons'>
						<button type="submit" className='submit-button'>Buscar Facturas</button>
					</div>
				</form>
			</section>
		</main>
	)
}

export default Libro