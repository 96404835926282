import React, { useState } from 'react'
import consulta from '../../../utils/consulta'
import Loading from '../../../components/partials/Loading';
import Swal from 'sweetalert2';
import './Styles/Modificacion.css'

function ModificarContrato() {
    const regex = /^[0-9]+(\.[0-9]{1,2})?$/
    const [isLoading, setIsLoading] = useState(false);
    const [modificarMagia, setModificarMagia] = useState(false);
    const [errores, setErrores] = useState("");
    const [cuil, setCuil] = useState("");
    const [ContratoEncontrado, setContratoEncontrado] = useState(false);
    const [contrato, setContrato] = useState({
        periodoDesde: "",
        periodoHasta: "",
        descuento: "",
        tipoDescuento: "",
        adicionales: "",
        tipoAdicionales: "",
        descuentoPlan: "",
        tipoDescuentoPlan: "",
        adicionalesPlan: "",
        tipoAdicionalesPlan: "",
    });

    const irAtras = () => {
        setModificarMagia(false)
        setErrores("")
        setCuil("")
        setContratoEncontrado(false)
        setContrato({
            periodoDesde: "",
            periodoHasta: "",
            descuento: "",
            tipoDescuento: "",
            adicionales: "",
            tipoAdicionales: "",
            descuentoPlan: "",
            tipoDescuentoPlan: "",
            adicionalesPlan: "",
            tipoAdicionalesPlan: "",
        })
    }

    const buscarCtaCte = async (e) => {
        try {
            e.preventDefault()
            setIsLoading(true)
            let finalURL = `/contratos/individual?titular=${cuil}`
            let response = await consulta.get(finalURL)
            setContratoEncontrado(true)
            setContrato(response.data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setErrores(error.meta.msg)
            setIsLoading(false)
        }
    }

    const aaModificar = () => {
        setModificarMagia(true)
    }

    const handleChange = (e) => {
        const { target } = e;
        const { name, value } = target;

        const newValues = {
            ...contrato,
            [name]: value,
        };

        setContrato(newValues);
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            let urlModificar = `/contratos/modificar/Contrato?titular=${cuil}`; // Asegúrate de definir la URL
            const result = await Swal.fire({
                title: '¿Está seguro de editar este contrato?',
                showDenyButton: true,
                confirmButtonText: 'Editar',
                denyButtonText: 'Cancelar',
            });

            if (result.isConfirmed) {

                const response = await consulta.post(urlModificar, contrato);

                if (response.status === 200) {
                    Swal.fire('Se editó el contrato', '', 'success');
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.meta.msg,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            } else if (result.isDenied) {
                Swal.fire('No se editó el contrato', '', 'info');
            }
        } catch (error) {
            console.error(error);
        }
    };

    /* Cargando ... */
    if (isLoading) {
        return (
            < Loading texto={"Cargando Contrato"} />
        )
    }

    if (modificarMagia) {
        return (

            <section className='Modificacion-Contrato'>
                <button className='irAtras' onClick={irAtras}>Atras</button>
                <h2>Modificar Contrato: {cuil}</h2>
                <form onSubmit={handleSubmit}>
                    <div className='formData'>
                        <label htmlFor="descuento">Descuentos</label>
                        <input type="text" name="descuento" id="descuento" value={contrato.descuento} onChange={handleChange} />
                    </div>
                    <div className='formData'>
                        <label htmlFor="tipoDescuento">Tipo Descuentos</label>
                        <select name="tipoDescuento" id="tipoDescuento" onChange={handleChange}>
                            <option defaultValue={contrato.tipoDescuento} hidden>{contrato.tipoDescuento}</option>
                            <option value='Monto'>Monto</option>
                            <option value='Porcentaje'>Porcentaje</option>
                        </select>
                    </div>
                    <div className='formData'>
                        <label htmlFor="adicionales">Adicional por Recategorizacion</label>
                        <input type="text" name="adicionales" id="adicionales" value={contrato.adicionales} onChange={handleChange} />
                    </div>
                    <div className='formData'>
                        <label htmlFor="tipoAdicionales">Tipo Recategorizacion</label>
                        <select name="tipoAdicionales" id="tipoAdicionales" onChange={handleChange}>
                            <option defaultValue={contrato.tipoAdicionales} hidden>{contrato.tipoAdicionales}</option>
                            <option value='Monto'>Monto</option>
                            <option value='Porcentaje'>Porcentaje</option>
                        </select>
                    </div>
                    <div className='formData'>
                        <label htmlFor="descuentoPlan">Descuentos Valor Plan</label>
                        <input type="text" name="descuentoPlan" id="descuentoPlan" value={contrato.descuentoPlan} onChange={handleChange} />
                    </div>
                    <div className='formData'>
                        <label htmlFor="tipoDescuentoPlan">Tipo Descuentos Plan</label>
                        <select name="tipoDescuentoPlan" id="tipoDescuentoPlan" onChange={handleChange}>
                            <option defaultValue={contrato.tipoDescuentoPlan} hidden>{contrato.tipoDescuentoPlan}</option>
                            <option value='Monto'>Monto</option>
                            <option value='Porcentaje'>Porcentaje</option>
                        </select>
                    </div>
                    <div className='formData'>
                        <label htmlFor="adicionalesPlan">Adicional por Recategorizacion</label>
                        <input type="text" name="adicionalesPlan" id="adicionalesPlan" value={contrato.adicionalesPlan} onChange={handleChange} />
                    </div>
                    <div className='formData'>
                        <label htmlFor="tipoAdicionalesPlan">Tipo Recategorizacion</label>
                        <select name="tipoAdicionalesPlan" id="tipoAdicionalesPlan" onChange={handleChange}>
                            <option defaultValue={contrato.tipoAdicionalesPlan} hidden>{contrato.tipoAdicionalesPlan}</option>
                            <option value='Monto'>Monto</option>
                            <option value='Porcentaje'>Porcentaje</option>
                        </select>
                    </div>
                    <div className='formData'>
                        <label htmlFor="periodoDesde">Periodo Desde</label>
                        <input type="text" name="periodoDesde" id="periodoDesde" value={contrato.periodoDesde} onChange={handleChange} />
                    </div>
                    <div className='formData'>
                        <label htmlFor="periodoHasta">Periodo Hasta</label>
                        <input type="text" name="periodoHasta" id="periodoHasta" value={contrato.periodoHasta} onChange={handleChange} />
                    </div>
                    <button type='submit'>Enviar Datos</button>
                </form>
            </section>
        )
    }
    if (ContratoEncontrado) {
        return (
            <section className='Modificacion-Contrato'>
                <button className='irAtras' onClick={irAtras}>Atras</button>
                <ul>
                    <li>Titular: {contrato.CUILTitular}</li>
                    <li>Descuentos: {contrato.descuento} {contrato.tipoDescuento}</li>
                    <li>Adicionales: {contrato.adicionales} {contrato.tipoAdicionales}</li>
                    <li>Descuentos Plan: {contrato.descuentoPlan} {contrato.tipoDescuentoPlan}</li>
                    <li>Adicionales Plan: {contrato.adicionalesPlan} {contrato.tipoAdicionalesPlan}</li>
                    <li>Periodo Desde: {contrato.periodoDesde}</li>
                    <li>Periodo Hasta: {contrato.periodoHasta}</li>
                </ul>
                <button onClick={aaModificar}>Modificar Contrato</button>
            </section>
        )
    }

    return (
        <section className='Modificacion-Contrato'>
            <h2>Búsqueda</h2>
            <article className='busqueda'>
                <form className='formulario' onSubmit={buscarCtaCte}>
                    <input
                        id='search'
                        name='search'
                        onChange={(e) => {
                            if (!regex.test(e.target.value) || e.key === "Backspace") {
                                e.preventDefault();
                            } else {
                                setCuil((e.target.value).trim())
                            }
                        }}
                        className='form-control inputBuscar'
                        placeholder="Busqueda por cuil"
                        type="text" />
                    <button>Buscar</button>
                </form>
                {errores &&
                    <small className='errores'>¡Error!: {errores}</small>}
            </article>
        </section>
    )
}

export default ModificarContrato