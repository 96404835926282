import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { actionTypes } from "./reducers/index";
import "./styles/styles.css";

/* Partial */
import Header from "./components/partials/Header/HeaderCompleto";
import HeaderSinPermisos from "./components/partials/Header/HeaderSinPermisos";
import Inexistente from "./pages/error/Inexistente"
import Loading from "./components/partials/Loading";

/* Routes */
import Home from "./pages/Home/Home";
import Herramientas from "./pages/herramientas/Herramientas";

/* Aportes */
import Aportes from "./pages/aportes/Aportes"

/* Aportes */
import ValoresPlan from "./pages/ValoresPlan/ValoresPlan"

/* Facturacion */
import Facturacion from "./pages/facturacion/Facturacion"

/* Pagos */
import Pagos from "./pages/pagos/Pagos"

/* Cuenta Corriente */
import CuentaCorriente from "./pages/CuentaCorriente/CuentaCorriente"

/* Login */
import Login from "./pages/users/Login/Login";
import Profile from "./pages/users/Profile/Profile";
import { DespachanteDeAduanas, tokenLogin } from "./utils/logear";

function App() {
	const state = useSelector(state => state.isLoggedIn);
	const storedToken = localStorage.getItem('authToken');
	const dispatch = useDispatch();

	const [loggedIn, setLoggedIn] = useState(state)
	const [isLoading, setIsLoading] = useState(false);

	const loginUser = async (token) => {
		try {
			const response = await tokenLogin(token)
			if (response.status === 400) {
				window.localStorage.setItem(
					'authToken', JSON.stringify("")
				)
				dispatch({ type: actionTypes.LOGOUT, payload: [] });
			} else {
				DespachanteDeAduanas(dispatch, response.data.permisos, response.data)
			}

		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		setIsLoading(true)
		if (storedToken) {
			loginUser(storedToken)
		}
		setIsLoading(false)
	}, []);

	useEffect(() => {
		if (state) {
			setLoggedIn(state)
		} else {
			setLoggedIn(false)
		}
	}, [state])

	if (isLoading) {
		return (
			<div>
				<HeaderSinPermisos />
				<Loading texto={"Cargando Sesión ..."} />
			</div>
		)
	}

	if (loggedIn) {
		return (
			<div>
				<BrowserRouter>
					<Header />
					<Routes>
						<Route path="/" element={<Home />} />;
						<Route path="/herramientas" element={<Herramientas />} />;
						<Route path="/profile" element={<Profile />} />;
						<Route path="/facturacion" element={<Facturacion />} />;
						<Route path="/aportes" element={<Aportes />} />;
						<Route path="/valor-plan" element={<ValoresPlan />} />;
						<Route path="/pagos" element={<Pagos />} />;
						<Route path="/cuenta" element={<CuentaCorriente />} />;
					</Routes>
				</BrowserRouter>
			</div>
		)
	}
	return (
		<div>
			<BrowserRouter>
				<HeaderSinPermisos />
				<Routes>
					<Route path="*" element={<Login />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
