import React, { useState } from 'react'
import consulta from '../../../../utils/consulta'
import { fechaMes,parsearFecha,escribirFuncion, parsearPersonas } from '../../../../utils/funciones'
import Loading from '../../../../components/partials/Loading'
import DataDisplay from '../../../../components/TableGenerator/DataDisplay'
import TableGenerator from '../../../../components/TableGenerator/TableGenerator'
import AcordeonTable from '../../../../components/Acordeon/AcordeonTable'
import ExcelButton from '../../../../components/partials/Excel/ExcelButton'

function Ejecucion({setFunc}) {
    
    const [error, seterror] = useState(false)
    const [tipoError, setTipoError] = useState("")
    const [loading, setLoading] = useState(false)
    const [mostrarDataIndividual, setmostrarDataIndividual] = useState(false)
    const [mostrarDataPadron, setmostrarDataPadron] = useState(false)
    const [periodo, setperiodo] = useState(fechaMes())
    const [cuil, setcuil] = useState("")
    const [funcionEscogida, setFuncionEscogida] = useState("")
    const [NombreUsuario, setNombreUsuario] = useState("")
    const [aportesUtilizados, setAportesUtilizados] = useState({})
    const [aportesPadron, setAportesPadron] = useState([])
    const [personasArr, setPersonasArr] = useState([])
    const [columnasArr, setcolumnasArr] = useState([])
    const [metaData, setmetaData] = useState({})

    const cambiarMes = ( e ) => {
        let mes = e.target.value;
        let nuevoPeriodo = String(periodo)
        let year = nuevoPeriodo.substring(0,4)
        setperiodo(`${year}${mes}`)
    }
    const cambiaryear = ( e ) => {
        console.log(e.target.value);
        let year = e.target.value
        let nuevoPeriodo = String(periodo)
        let mes = nuevoPeriodo.substring(4,6)
        setperiodo(`${year}${mes}`)
    }
    const volverAtras = (e) => {
        e.preventDefault()
        seterror(false)
        setTipoError("")
        setmostrarDataIndividual(false)
        setperiodo(fechaMes())
        setcuil("")
        setFuncionEscogida("")
        setNombreUsuario("")
        setAportesUtilizados({})
        setFunc("")
    }
    const settearColumnas = (funcion) => {
        let columnaAportePadron
        if (funcion === 'AUPC') {
            columnaAportePadron = [
                { field: 'cuil', headerName: 'CUIL', width: 110 },
                { field: 'valor', headerName: 'Valor Aportes', width: 140 },
                { field: 'caso', headerName: 'Caso', width: 140 },
                { field: 'periodoImpacto', headerName: 'Impacto', width: 90 },
                { field: 'periodoOrigen', headerName: 'Origen', width: 90 },
                { field: 'obraSocial', headerName: 'Obra Social', width: 120 },
                { field: 'tipoDeAporte', headerName: 'tipoDeAporte', width: 140 },
                { field: 'AportesDevengados', headerName: 'Devengados', width: 140 },
                { field: 'AportesEstimados', headerName: 'Estimados', width: 140 },
                { field: 'AportesTransferidos', headerName: 'Transferidos', width: 140 }
            ];
        } else {
            columnaAportePadron = [
                { field: 'contratoId', headerName: 'contratoId', width: 90 },
                { field: 'titular', headerName: 'Titular', width: 110 },
                { field: 'gf', headerName: 'GF', width: 70 },
                { field: 'periodo', headerName: 'Periodo', width: 90 },
                { field: 'valor', headerName: 'Valor', width: 140 },
                { field: 'personas', headerName: 'Personas', width: 140 },
            ];
        }
        setcolumnasArr(columnaAportePadron)
    }
 
    const AportesUtilizadosIndividuales = async (e) => {
        try {
            e.preventDefault()
            if (cuil.length === 11) {
                seterror(false)
                setLoading(true)
                let urlAportesUtilizados = `/calculos/aportesUtilizados?titular=${cuil}&periodo=${periodo}`
                let response = await consulta.post(urlAportesUtilizados)

                if(response.status !== 200) {
                    throw new Error(response.meta.msg)
                }

                setNombreUsuario(response.meta.nombre)
                setAportesUtilizados(response.data)
                setmostrarDataIndividual(true)
                setLoading(false)
            }else{
                seterror(true)
                setTipoError("Error con el Cuil")
                setLoading(false)
            }
        } catch (error) {
            seterror(true)
            setTipoError(error.message)
            console.error('Error al hacer la consulta ', error.message)
            setLoading(false)
        }
    }
    const AportesUtilizadosPadron = async () => {
        try {
            setLoading(true)
            settearColumnas(funcionEscogida)
            let urlAportesUtilizados = `/calculos/aportesUtilizados/padron?periodo=${periodo}`
            let response = await consulta.post(urlAportesUtilizados)

            if(response.status !== 200) {
                throw new Error(response.meta.msg)
            }

            setmostrarDataPadron(true)
            setAportesPadron(response.data)
            setmetaData(response.meta)
            setLoading(false)
        } catch (error) {
            seterror(true)
            setTipoError(error.message)
            console.error('Error al hacer la consulta ', error.message)
            setLoading(false)
        }
    }
    const AportesContratos = async (e) => {
        try {
            e.preventDefault()
            if (cuil.length === 11) {
                seterror(false)
                setLoading(true)
                console.log("<-- Se lanzo! -->");
                let urlAportesContrato = `/calculos/aportesContrato?titular=${cuil}&periodo=${periodo}`
                let response = await consulta.post(urlAportesContrato)
                if(response.status !== 200) {
                    throw new Error(response.meta.msg)
                }
                setNombreUsuario(response.meta.nombre)
                setPersonasArr(parsearPersonas(response.data.personas))
                delete response.data.personas
                console.log();
                setAportesUtilizados(response.data)
                setmostrarDataIndividual(true)
                setLoading(false)
            }else{
                seterror(true)
                setTipoError("Error con el Cuil")
                setLoading(false)
            }
        } catch (error) {
            seterror(true)
            setTipoError(error.message)
            console.error('Error al hacer la consulta ', error.message)
            setLoading(false)
        }
    }
    const AportesContratosPadron = async () => {
        try {
            setLoading(true)
            settearColumnas(funcionEscogida)
            let urlAportesUtilizados = `/calculos/aportesContrato/padron?periodo=${periodo}`
            let response = await consulta.post(urlAportesUtilizados)
            if(response.status !== 200) {
                throw new Error(response.meta.msg)
            }
            setmostrarDataPadron(true)
            setAportesPadron(response.data)
            setmetaData(response.meta)
            setLoading(false)
        } catch (error) {
            seterror(true)
            setTipoError(error.message)
            console.error('Error al hacer la consulta ', error.message)
            setLoading(false)
        }
    }

    if (loading) {
        return(
            <Loading texto={"Cargando Aportes ..."} />
        )
    }

    if (mostrarDataIndividual) {
        return(
            <div className='Funciones'>
                <div className='header-funciones'>
                    <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
                </div>
                <h2 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h2>
                <h3 className='titulo-seccion'>Usuario: {NombreUsuario}</h3>
                <div>
                    <DataDisplay data={aportesUtilizados} />
                    {personasArr.length > 0 &&
                        <div className='tablaPersonas'>
                            <AcordeonTable data={personasArr} />
                        </div>
                    }
                </div>
                <h4 className='subtitulo-seccion'>Función Finalizada cuil:{cuil} </h4>
            </div>
        )
    }

    if (mostrarDataPadron) {
        return(
            <div className='Funciones Tabla-Padron'>
                <div className='header-funciones'>
                    <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
                </div>
                <h2 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h2>
                <div>
                    <h4>Actualizados: <span>{metaData.actualizados}</span></h4>
                    <h4>Creados <span>{metaData.creados}</span></h4>
                    <h4>Iguales <span>{metaData.iguales}</span></h4>
                </div>
                <div>
                    {funcionEscogida === "AUPC" ?
                        <div>
                            {aportesPadron.length > 0 &&
                                <TableGenerator columns={columnasArr} data={aportesPadron} />
                            }
                            <div className='buttons'>
                                <ExcelButton data={aportesPadron} nombreHoja={funcionEscogida} nombreArchivo={`Aportes Utilizados`} />
                                <button className='submit-button'>Pasar datos a DB</button>
                            </div>
                        </div>
                        :
                        <div>
                            {aportesPadron.length > 0 &&
                                <TableGenerator columns={columnasArr} data={aportesPadron} />
                            }
                            <div className='buttons'>
                                <ExcelButton data={aportesPadron} nombreHoja={funcionEscogida} nombreArchivo={`Aportes Contratos`} />
                                <button className='submit-button'>Pasar datos a DB</button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }


    return (
        <div className='Funciones'>
            <div className='header-funciones'>
                <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
            </div>
            <h2 className='titulo-seccion'>Ejecuciones</h2>

            {funcionEscogida === "AUI" ? 
                <article className='formulario'>
                    <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                        <form className='formulario-content' onSubmit={AportesUtilizadosIndividuales}>
                            <div className='formData'>
                                <label htmlFor="cuil">Cuil Aflilado</label>
                                <input type="text" name="cuil" id="cuil" className={error ? "invalid" : "valid"} onChange={(e) => setcuil(e.target.value)}/>
                            </div>
                            <div className='formData'>
                                <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                                <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                    <option defaultValue="" hidden>Puede modificar el año</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                </select>
                            </div>
                            <div className='formData'>
                                <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                                <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                    <option defaultValue="" hidden>Puede modificar el mes</option>
                                    <option value="01">Enero</option>
                                    <option value="02">Febrero</option>
                                    <option value="03">Marzo</option>
                                    <option value="04">Abril</option>
                                    <option value="05">Mayo</option>
                                    <option value="06">Junio</option>
                                    <option value="07">Julio</option>
                                    <option value="08">Agosto</option>
                                    <option value="09">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>
                                </select>
                            </div>
                            <div className='periodo-seleccionado'>
                                <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                            </div>
                            {error &&
                                <div className='error-del-sistema'>
                                    <small>{tipoError}</small>
                                </div>
                            }
                            <div className='buttons'>
                                <button type="submit" className='submit-button'>Ejecutar</button>
                            </div>
                        </form>
                </article>
            :
            funcionEscogida === "AUPC" ? 
                <article className='formulario'>
                    <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                        <form className='formulario-content' onSubmit={AportesUtilizadosPadron}>
                            <div className='formData'>
                                <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                                <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                    <option defaultValue="" hidden>Puede modificar el año</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                </select>
                            </div>
                            <div className='formData'>
                                <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                                <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                    <option defaultValue="" hidden>Puede modificar el mes</option>
                                    <option value="01">Enero</option>
                                    <option value="02">Febrero</option>
                                    <option value="03">Marzo</option>
                                    <option value="04">Abril</option>
                                    <option value="05">Mayo</option>
                                    <option value="06">Junio</option>
                                    <option value="07">Julio</option>
                                    <option value="08">Agosto</option>
                                    <option value="09">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>
                                </select>
                            </div>
                            <div className='periodo-seleccionado'>
                                <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                            </div>
                            {error &&
                                <div className='error-del-sistema'>
                                    <small>{tipoError}</small>
                                </div>
                            }
                            <div className='buttons'>
                                <button className='cancel-button'>Cancelar</button>
                                <button type="submit" className='submit-button'>Ejecutar</button>
                            </div>
                        </form>
                </article>
            :
            funcionEscogida === "ACI" ? 
                <article className='formulario'>
                    <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                        <form className='formulario-content' onSubmit={AportesContratos}>
                            <div className='formData'>
                                <label htmlFor="cuil">Cuil Aflilado</label>
                                <input type="text" name="cuil" id="cuil" className={error ? "invalid" : "valid"} onChange={(e) => setcuil(e.target.value)}/>
                            </div>
                            <div className='formData'>
                                <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                                <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                    <option defaultValue="" hidden>Puede modificar el año</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                </select>
                            </div>
                            <div className='formData'>
                                <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                                <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                    <option defaultValue="" hidden>Puede modificar el mes</option>
                                    <option value="01">Enero</option>
                                    <option value="02">Febrero</option>
                                    <option value="03">Marzo</option>
                                    <option value="04">Abril</option>
                                    <option value="05">Mayo</option>
                                    <option value="06">Junio</option>
                                    <option value="07">Julio</option>
                                    <option value="08">Agosto</option>
                                    <option value="09">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>
                                </select>
                            </div>
                            <div className='periodo-seleccionado'>
                                <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                            </div>
                            {error &&
                                <div className='error-del-sistema'>
                                    <small>{tipoError}</small>
                                </div>
                            }
                            <div className='buttons'>
                                <button className='cancel-button'>Cancelar</button>
                                <button type="submit" className='submit-button'>Ejecutar</button>
                            </div>
                        </form>
                </article>
            :
            funcionEscogida === "ACPC" ? 
                <article className='formulario'>
                    <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                        <form className='formulario-content' onSubmit={AportesContratosPadron}>
                            <div className='formData'>
                                <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                                <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                    <option defaultValue="" hidden>Puede modificar el año</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                </select>
                            </div>
                            <div className='formData'>
                                <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                                <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                    <option defaultValue="" hidden>Puede modificar el mes</option>
                                    <option value="01">Enero</option>
                                    <option value="02">Febrero</option>
                                    <option value="03">Marzo</option>
                                    <option value="04">Abril</option>
                                    <option value="05">Mayo</option>
                                    <option value="06">Junio</option>
                                    <option value="07">Julio</option>
                                    <option value="08">Agosto</option>
                                    <option value="09">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>
                                </select>
                            </div>
                            <div className='periodo-seleccionado'>
                                <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                            </div>
                            {error &&
                                <div className='error-del-sistema'>
                                    <small>{tipoError}</small>
                                </div>
                            }
                            <div className='buttons'>
                                <button className='cancel-button'>Cancelar</button>
                                <button type="submit" className='submit-button'>Ejecutar</button>
                            </div>
                        </form>
                </article>
            :
                <article className='botones-Funciones'>
                    <button className='simulacion-button' onClick={(e) => setFuncionEscogida("AUI")}>Aportes Utilizados Individuales</button>
                    <button className='simulacion-button' onClick={(e) => setFuncionEscogida("AUPC")}>Aportes Utilizados Padrón Completo</button>
                    <button className='simulacion-button' onClick={(e) => setFuncionEscogida("ACI")}>Aportes Contrato Individual</button>
                    <button className='simulacion-button' onClick={(e) => setFuncionEscogida("ACPC")}>Aportes Contrato Padrón Completo</button>
                </article>
            }
        </div>
    )
}

export default Ejecucion