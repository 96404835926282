import React, { useState } from 'react'
import consulta from '../../../utils/consulta'
import Loading from '../../../components/partials/Loading'
import Deuda from './Componentes/Deuda'
import Prestacion from './Componentes/Prestacion'
import PlanFlex from './Componentes/PlanFlex'

function PlanesDePago() {

    const regex = /^[0-9]+(\.[0-9]{1,2})?$/
    const [isLoading, setIsLoading] = useState(false)
    const [funcion, setFuncion] = useState("")
    const [errores, setErrores] = useState("")
    const [cuil, setCuil] = useState("")
    const [titular, setTitular] = useState({})
    const [deuda, setDeuda] = useState([])

    const buscarTitular = async (e) => {
        try {
            setIsLoading(true)
            let urlBusquedaTitular = `/padrones/busqueda/titular?afiliado=${cuil}`
            let urlBusquedaDeuda = `/informacion/api/saldos/impagos?titular=${cuil}`
            let response = await consulta.get(urlBusquedaTitular)
            console.log(response);
            if (response.meta.total < 1 && response.status !== 200) {
                throw new Error(response.meta.msg)
            }
            setTitular(response.data)
            if (funcion === "Prestacion") {
                setFuncion("CreacionPrestaciones")
            }else if (funcion === "Flex") {
                let deudaTotal = await consulta.get(urlBusquedaDeuda)
                setDeuda(deudaTotal.data)
                setFuncion("CreacionFlex")
            }else{
                let deudaTotal = await consulta.get(urlBusquedaDeuda)
                setDeuda(deudaTotal.data)
                setFuncion("CreacionDeuda")
            }

            setIsLoading(false)

        } catch (error) {
            console.log(error)
            setErrores(error.message)
            setIsLoading(false)
        }
    }
    const volverAtras = () => {
        setIsLoading(false)
        setFuncion("")
        setErrores("")
        setCuil("")
    }
    if (isLoading) {
        return (
            <Loading texto={"Cargando Titular..."} />
        )
    }
    if (funcion === "CreacionDeuda" || funcion === "CreacionPrestaciones" || funcion === "CreacionFlex") {
        return (
            <section className='Plan-de-pago'>
                <div className="volver">
                    <button onClick={volverAtras}>Atras</button>
                </div>
                <h2>Constituir un nuevo Plan de pago: {funcion === "CreacionDeuda" ? "Deuda" : funcion === "CreacionFlex" ? "Flex" : "Prestacion"}</h2>
                <article className='titular'>
                    <h4>Titular {titular.CUILTitular}: <span className='texto-titular'>{titular.apellnombAfilado}</span>, Estado:{titular.estado}</h4>
                    <h4>Provincia: <span className='texto-titular'>{titular.provinciaDom}</span> Localidad: <span className='texto-titular'>{titular.localidadDom}</span></h4>
                    <h4>Plan Afiliado: <span className='texto-titular'>{titular.planAfiliado}</span> categoria: <span className='texto-titular'>{titular.categoria}</span></h4>
                    <h4>Obra social: <span className='texto-titular'>{titular.OSAndes}</span>  GF: {titular.GF} categoriaAf: <span className='texto-titular'>{titular.catAfiliado}</span></h4>
                </article>
                {funcion === "CreacionFlex" && 
                    <PlanFlex cuil={cuil} volverAtras={volverAtras} deuda={deuda} />
                }
                {funcion === "CreacionDeuda" && 
                    <Deuda cuil={cuil} volverAtras={volverAtras} deuda={deuda} />
                }
                {funcion === "CreacionPrestaciones" && 
                    <Prestacion cuil={cuil} volverAtras={volverAtras}/>
                }
            </section>
        )
    }
    if (funcion === "Prestacion" || funcion === "Deuda") {
        return (
            <section className='Plan-de-pago'>
                <div className="volver">
                    <button onClick={volverAtras}>Atras</button>
                </div>
                <h2>Constituir un nuevo Plan de pago: {funcion}</h2>
                <article className="buscar-cuil">
                    <form className='busqueda' onSubmit={buscarTitular}>
                        <input
                            id='search'
                            name='search'
                            onChange={(e) => {
                                if (!regex.test(e.target.value) || e.key === "Backspace") {
                                    e.preventDefault();
                                } else {
                                    setCuil((e.target.value).trim())
                                }
                            }}
                            className='form-control inputBuscar'
                            placeholder="Busqueda por cuil"
                            type="text" />
                        <button>Buscar</button>
                    </form>
                    {errores &&
                    <small className='errores'>¡Error!: {errores}</small>}
                </article>
            </section>
        )
    }
    if (funcion === "Prestacion" || funcion === "Flex") {
        return (
            <section className='Plan-de-pago'>
                <div className="volver">
                    <button onClick={volverAtras}>Atras</button>
                </div>
                <h2>Constituir un nuevo Plan de pago: {funcion}</h2>
                <article className="buscar-cuil">
                    <form className='busqueda' onSubmit={buscarTitular}>
                        <input
                            id='search'
                            name='search'
                            onChange={(e) => {
                                if (!regex.test(e.target.value) || e.key === "Backspace") {
                                    e.preventDefault();
                                } else {
                                    setCuil((e.target.value).trim())
                                }
                            }}
                            className='form-control inputBuscar'
                            placeholder="Busqueda por cuil"
                            type="text" />
                        <button>Buscar</button>
                    </form>
                    {errores &&
                    <small className='errores'>¡Error!: {errores}</small>}
                </article>
            </section>
        )
    }

    return (
        <section className='Plan-de-pago'>
            <h2>Constituir un nuevo Plan de pago</h2>
            <article className='contenedor-botones'>
                <button onClick={(e) => setFuncion("Flex")}>Flex</button>
                <button onClick={(e) => setFuncion("Deuda")}>Deuda</button>
                <button onClick={(e) => setFuncion("Prestacion")}>Prestacion</button>
            </article>
        </section>
    )
}

export default PlanesDePago