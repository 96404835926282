import React, { useState, useEffect } from 'react';
import Simulacion from './Components/Simulacion';
import Ejecucion from './Components/Ejecucion';

function Funciones() {

	const [func, setFunc] = useState("")

	return (
		<div className='ValoresPlan'>

			{func === "Ejecución" ?
				<Ejecucion setFunc={setFunc} />
				: func === "Simulación" ?
					<Simulacion setFunc={setFunc} />
					:
					<div className='Funciones Componente-Principal'>
						<section className='funcion'>
							<div className='boton-gigante' onClick={() => setFunc('Simulación')}>
								<h1 className={func === 'Simulación' ? 'active' : ''}>
									Simulaciónes con Saldos
								</h1>
							</div>
						</section>
						<section className='funcion'>
							<div className='boton-gigante' onClick={() => setFunc('Ejecución')}>
								<h1 className={func === 'Ejecución' ? 'active' : ''} >
									Ejecución de Saldos
								</h1>
							</div>
						</section>
					</div>
			}


		</div>
	)
}

export default Funciones