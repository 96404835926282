import React, { useState } from 'react'
import { fechaMes, parsearFecha } from '../../../utils/funciones';
import Loading from '../../../components/partials/Loading';

function CuentaModificable() {

    const [cuil, setCuil] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [errores, seterrores] = useState(false);
    const [mostrarDatos, setmostrarDatos] = useState(false);
    const [ValorPlan, setValorPlan] = useState([])
    const [Aportes, setAportes] = useState([])
    const [Saldos, setSaldos] = useState([])
    const [periodo, setPeriodo] = useState(fechaMes());

    const regex = /^[0-9]+(\.[0-9]{1,2})?$/

    const cambiarMes = (e) => {
        let mes = e.target.value;
        let nuevoPeriodo = String(periodo)
        let year = nuevoPeriodo.substring(0, 4)
        setPeriodo(`${year}${mes}`)
    }
    const cambiaryear = (e) => {
        console.log(e.target.value);
        let year = e.target.value
        let nuevoPeriodo = String(periodo)
        let mes = nuevoPeriodo.substring(4, 6)
        setPeriodo(`${year}${mes}`)
    }

    const volverAtras = (e) => {
        setCuil("")
        seterrores(false)
        setmostrarDatos(false)
        setValorPlan([])
        setAportes([])
        setSaldos([])
        setPeriodo(fechaMes())
    }

    const buscarCtaCte = async (e) => {
        try {
            let urlValorPlanContrato = ""
            let urlAporteContrato = "/informacion/api/aporte/individual?afiliado=20402731258"
            let urlSaldo = ""
        } catch (error) {

        }
    }

    if (isLoading) {
        return (
            <Loading texto={"Cargando Aportes ..."} />
        )
    }
    if (mostrarDatos) {
        return (
            <main className='CuentaCorriente'>

            </main>
        )
    }

    return (
        <div className='Funciones'>
            <div className='header-funciones'>
                <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
            </div>
            <article className='formulario'>
                <h3 className='titulo-seccion'>Buscar Contrato</h3>
                <form className='formulario-content' onSubmit={buscarCtaCte}>
                    <div className='formData'>
                        <label htmlFor="cuil">Cuil Aflilado</label>
                        <input
                            id='search'
                            name='search'
                            onChange={(e) => {
                                if (!regex.test(e.target.value) || e.key === "Backspace") {
                                    e.preventDefault();
                                } else {
                                    setCuil((e.target.value).trim())
                                }
                            }}
                            className='form-control inputBuscar'
                            placeholder="Busqueda por cuil"
                            type="text" />
                    </div>
                    <div className='formData'>
                        <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                        <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                            <option defaultValue="" hidden>Puede modificar el año</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                        </select>
                    </div>
                    <div className='formData'>
                        <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                        <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                            <option defaultValue="" hidden>Puede modificar el mes</option>
                            <option value="01">Enero</option>
                            <option value="02">Febrero</option>
                            <option value="03">Marzo</option>
                            <option value="04">Abril</option>
                            <option value="05">Mayo</option>
                            <option value="06">Junio</option>
                            <option value="07">Julio</option>
                            <option value="08">Agosto</option>
                            <option value="09">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                        </select>
                    </div>
                    <div className='periodo-seleccionado'>
                        <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                    </div>
                    {errores &&
                        <small className='errores'>¡Error!: {errores}</small>}
                    <div className='buttons'>
                        <button type="submit" className='submit-button'>Ejecutar</button>
                    </div>
                </form>
            </article>
        </div>
    )
}

export default CuentaModificable