import React, { useState, useEffect } from 'react'
import consulta from '../../utils/consulta'
import { useSelector } from 'react-redux';

/* Componentes */
import { Pagination, Stack } from '@mui/material';
import Loading from '../../components/partials/Loading'
import ModalCuentaCorriente from './Componentes/ModalCuentaCorriente';

/* Styles */
import './Styles/cuentaCorriente.css'
import Acordeones from './Componentes/Acordeones';

function CuentaCorriente() {
	const state = useSelector(state => state);
	const [rol, setRol] = useState(state.rol)

	useEffect(() => {
		setRol(state.rol)
	}, [state]);

	const [isLoading, setIsLoading] = useState(false);
	const [cargandoPagina, setCargandoPagina] = useState(false);
	const [verInfo, setVerInfo] = useState(false)
	const [informacionComplementaria, setInformacionComplementaria] = useState(false)
	const [errores, setErrores] = useState("")
	const [cuil, setCuil] = useState("")
	const [datos, setDatos] = useState({})
	const [Saldos, setSaldos] = useState([])
	const [count, setCount] = useState(1);
	const [page, setPage] = useState(1);
	const [modalVisible, setModalVisible] = useState(false);

	console.log(`Cuil titular --> ${cuil}`)

	const openModal = () => {
		setModalVisible(true);
	};

	const closeModal = () => {
		setModalVisible(false);
	};

	const regex = /^[0-9]+(\.[0-9]{1,2})?$/

	/* Cuenta Corriente */
	const buscarCtaCte = async (e) => {
		try {
			e.preventDefault()
			setIsLoading(true)
			const calcularNumeroDePaginas = (totalElementos, elementosPorPagina) => Math.ceil(totalElementos / elementosPorPagina);
			let datoslURL = `/contratos/datos/cuenta?afiliado=${cuil}`
			let finalURL = `/contratos/paginador/cuenta?afiliado=${cuil}&offset=1`
			let response = await consulta.post(datoslURL)
			if (response.status === 200) {
				setDatos(response.data)
				setVerInfo(true)
				let cantidad = calcularNumeroDePaginas(response.data.cantidadSaldos, 5)
				setCount(cantidad)
				let ctacte = await consulta.post(finalURL)
				if (ctacte.status === 200) {
					setSaldos(ctacte.data)
				} else {
					setErrores("Error con los datos")
				}
			} else {
				setErrores("Error con los datos")
			}
			setIsLoading(false)
		} catch (error) {
			console.log(error)
			setErrores(error.message)
			setIsLoading(false)
		}
	}
	const handleChange = async (event, value) => {
		try {
			event.preventDefault()
			setCargandoPagina(true)
			let finalURL = `/contratos/paginador/cuenta?afiliado=${cuil}&offset=${value}`
			let response = await consulta.post(finalURL)
			if (response.status === 200) {
				setPage(value)
				setSaldos(response.data)
				setCargandoPagina(false)
			} else {
				setErrores("Error con los datos")
			}
		} catch (error) {
			console.log(error);
			setCargandoPagina(false)
		}
	};
	const VolverAtras = (e) => {
		e.preventDefault()
		setIsLoading(true)
		setVerInfo(false)
		setInformacionComplementaria(false)
		setErrores("")
		setCuil("")
		setDatos({})
		setSaldos([])
		setCount(1)
		setPage(1)
		setIsLoading(false)
	}
	const mostrarData = () => {
		setInformacionComplementaria(!informacionComplementaria)
	}

	/* Cargando ... */
	if (isLoading) {
		return (
			< Loading texto={"Cargando Cuenta Corriente"} />
		)
	}

	if (verInfo) {
		return (
			<main className='CuentaCorriente'>
				<button onClick={VolverAtras} className="back">Atras</button>
				<button onClick={mostrarData} className="data">Ver Datos de Contrato</button>
				<h1>Cuenta Corriente</h1>
				<h2>Resultado de busqueda: {cuil}</h2>
				<h4>Titular: {datos.NombreTitular}</h4>
				<section
					className={informacionComplementaria ?
						'Datos-Contrato active' :
						'Datos-Contrato'}
				>
					<h2>Datos</h2>
					<article>
						<div className='header-ctacte'>
							<h3>CuilTitular: {datos.CuilTitular}</h3>
							<h3>N° Contrato: {datos.IdContrato}</h3>
							<h3>Grupo Familiar: {datos.gf}</h3>
							<h3>Total Valores Plan: {datos.totalValoresPlan}</h3>
						</div>
						<div className='header-ctacte end'>
							<h3>Descuentos:{datos.Descuento}</h3>
							<h3>Total Aportes: {datos.totalAportes}</h3>
							<h3>Adicionales:{datos.Adicionales}</h3>
							<h3>Total Saldos Facturados: {datos.totalSaldosFacturados}</h3>
						</div>
					</article>
				</section>
				<section className="tabla">
					{cargandoPagina ?
						< Loading texto={"Cargando Saldos Corriente"} />
						: (Saldos.length > 0) ?
							<ul className="accordion">

								{Saldos.map(saldo =>

									<Acordeones
										saldo={saldo}
										pagado={saldo.pagado}
									/>

								)}
							</ul>
							:
							<div>
								<h2>Este afiliado no tiene saldos</h2>
							</div>
					}
					<div className='table-bottom'>
						<h3 className='saldoTotal'>Saldo Total: ${datos.totalSaldos}</h3>
						{(rol === "Administrador" || rol === "Aportes" || rol === "Contratos" || rol === "Atencion al Cliente") &&
							<button className='boton-multiple' onClick={openModal}>Crear Link Multiple</button>
						}
					</div>

					{modalVisible && (
						<ModalCuentaCorriente onClose={closeModal} cuil={cuil} />
					)}
					{Saldos.length > 0 &&
						<Stack spacing={4} className='paginador'>
							<Pagination count={count} page={page} onChange={handleChange} />
						</Stack>
					}
				</section>
			</main>
		)
	}

	return (
		<main className='CuentaCorriente'>
			<h2>Cuenta Corriente</h2>
			<section className='seccion-busqueda'>
				<h2>Búsqueda</h2>
				<article className='tablaBusqueda'>
					<form className='busqueda' onSubmit={buscarCtaCte}>
						<input
							id='search'
							name='search'
							onChange={(e) => {
								if (!regex.test(e.target.value) || e.key === "Backspace") {
									e.preventDefault();
								} else {
									setCuil((e.target.value).trim())
								}
							}}
							className='form-control inputBuscar'
							placeholder="Busqueda por cuil"
							type="text" />
						<button>Buscar</button>
					</form>
					{errores &&
						<small className='errores'>¡Error!: {errores}</small>}
				</article>
			</section>
		</main>
	)
}

export default CuentaCorriente