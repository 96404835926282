import React from 'react'
import '../Styles/cliente.css'

function Cliente({cliente, setCliente}) {

    let provincias = [
        { nombre: 'guion', numero: 26 },
        { nombre: 'CABA', numero: 1 },
        { nombre: 'BUENOS AIRES', numero: 2 },
        { nombre: 'CATAMARCA', numero: 3 },
        { nombre: 'CHACO', numero: 4 },
        { nombre: 'CHUBUT', numero: 5 },
        { nombre: 'CORDOBA', numero: 6 },
        { nombre: 'CORRIENTES', numero: 7 },
        { nombre: 'ENTRE RIOS', numero: 8 },
        { nombre: 'FORMOSA', numero: 9 },
        { nombre: 'JUJUY', numero: 10 },
        { nombre: 'LA PAMPA', numero: 11 },
        { nombre: 'LA RIOJA', numero: 12 },
        { nombre: 'MENDOZA', numero: 13 },
        { nombre: 'MISIONES', numero: 14 },
        { nombre: 'NEUQUEN', numero: 15 },
        { nombre: 'RIO NEGRO', numero: 16 },
        { nombre: 'SALTA', numero: 17 },
        { nombre: 'SAN JUAN', numero: 18 },
        { nombre: 'SAN LUIS', numero: 19 },
        { nombre: 'SANTA CRUZ', numero: 20 },
        { nombre: 'SANTA FE', numero: 21 },
        { nombre: 'SANTIAGO DEL ESTERO', numero: 22 },
        { nombre: 'TIERRA DEL FUEGO', numero: 23 },
        { nombre: 'TUCUMAN', numero: 24 },
        { nombre: 'Otro', numero: 25 },
      ]
    let  obtenerProvincia = (numero) => {
        let provincias = [
            { nombre: 'guion', numero: 26 },
            { nombre: 'CABA', numero: 1 },
            { nombre: 'BUENOS AIRES', numero: 2 },
            { nombre: 'CATAMARCA', numero: 3 },
            { nombre: 'CHACO', numero: 4 },
            { nombre: 'CHUBUT', numero: 5 },
            { nombre: 'CORDOBA', numero: 6 },
            { nombre: 'CORRIENTES', numero: 7 },
            { nombre: 'ENTRE RIOS', numero: 8 },
            { nombre: 'FORMOSA', numero: 9 },
            { nombre: 'JUJUY', numero: 10 },
            { nombre: 'LA PAMPA', numero: 11 },
            { nombre: 'LA RIOJA', numero: 12 },
            { nombre: 'MENDOZA', numero: 13 },
            { nombre: 'MISIONES', numero: 14 },
            { nombre: 'NEUQUEN', numero: 15 },
            { nombre: 'RIO NEGRO', numero: 16 },
            { nombre: 'SALTA', numero: 17 },
            { nombre: 'SAN JUAN', numero: 18 },
            { nombre: 'SAN LUIS', numero: 19 },
            { nombre: 'SANTA CRUZ', numero: 20 },
            { nombre: 'SANTA FE', numero: 21 },
            { nombre: 'SANTIAGO DEL ESTERO', numero: 22 },
            { nombre: 'TIERRA DEL FUEGO', numero: 23 },
            { nombre: 'TUCUMAN', numero: 24 },
            { nombre: 'Otro', numero: 25 },
          ]
        numero = +numero
        const provincia = provincias.find(p => p.numero === numero);
        return provincia ? provincia.nombre : 'Provincia no encontrada';
    }
    let  obtenerCondicionFrenteAIVA = (valor) => {
        let condiciones = [
            { value : "CF", tipo : "Consumidor Final"},
            { value : "RI", tipo : "Responsable Inscripto"},
            { value : "M", tipo : "Monotributo"},
            { value : "E", tipo : "Excento"},
            { value : "CDEX", tipo : "Cliente Del Exterior"},
            { value : "IVNA", tipo : "IVA No Alcanzado"},
        ]
        const condicion = condiciones.find(CI => CI.value === valor);
        return condicion ? condicion.tipo : 'Condicion no seleccionada';
    }
    let  obtenerCondicionVenta = (valor) => {
        let condiciones = [
            { value:"213" ,cantidad: "5 dias "},
            { value:"206" ,cantidad: "10 dias "},
            { value:"207" ,cantidad: "15 dias "},
            { value:"209" ,cantidad: "20 dias "},
            { value:"202" ,cantidad: "30 dias "},
            { value:"208" ,cantidad: "45 dias "},
            { value:"203" ,cantidad: "60 dias "},
            { value:"204" ,cantidad: "90 dias "},
            { value:"201" ,cantidad: "Contado "},
            { value:"205" ,cantidad: "Cuenta Corriente "},
            { value:"210" ,cantidad: "Transferencia Bancaria "},
            { value:"211" ,cantidad: "Tarjeta De Credito "},
            { value:"212" ,cantidad: "Tarjeta De Debito "},
            { value:"214" ,cantidad: "Otra "},
        ]
        const condicion = condiciones.find(CI => CI.value === valor);
        return condicion ? condicion.cantidad : 'Condicion no seleccionada';
    }
    const handleClienteChange = event => {
        setCliente({
          ...cliente,
          [event.target.name]: event.target.value
        });
      };
    return (
        <article className='cliente'>
            <div className='form-manual'>
                <label htmlFor="documento_tipo">Tipo de Documento</label>
                <select name="documento_tipo" id="documento_tipo" onChange={handleClienteChange}>
                    {
                        cliente.documento_tipo !== "" ? 
                            <option defaultValue={cliente.documento_tipo} hidden>{cliente.documento_tipo}</option>
                        :
                            <option defaultValue={""} hidden>Seleccione una opcion</option>
                    }
                    <option value="DNI">DNI</option>
                    <option value="CUIL">CUIL</option>
                    <option value="CUIT">CUIT</option>
                    <option value="PASAPORTE">PASAPORTE</option>
                    <option value="CDI">CDI</option>
                    <option value="OTRO">OTRO (no requiere enviar el numero)</option>
                </select>
            </div>
            <div className='form-manual'>
                <label htmlFor="documento_nro">Número de documento</label>
                <input
                    type="text"
                    id="documento_nro"
                    name="documento_nro"
                    onChange={handleClienteChange}
                    placeholder='CUIL,DNI,Etc,Segun seleccion anterior'
                    value={cliente.documento_nro}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="condicionIva">Condicion Frente a IVA</label>
                <select name="condicionIva" id="condicionIva" onChange={handleClienteChange}>
                    {
                        cliente.condicionIva !== "" ? 
                            <option defaultValue={cliente.condicionIva} hidden>{obtenerCondicionFrenteAIVA(cliente.condicionIva)}</option>
                        :
                            <option defaultValue={""} hidden>Seleccione una condicion</option>
                    }
                    <option value="CF">Consumidor Final</option>
                    <option value="RI">Responsable Inscripto</option>
                    <option value="M">Monotributo</option>
                    <option value="E">Excento</option>
                    <option value="CDEX">Cliente Del Exterior</option>
                    <option value="IVNA">IVA No Alcanzado</option>
                </select>
            </div>
            <div className='form-manual'>
                <label htmlFor="razon_social">Razon Social</label>
                <input 
                    type="text" 
                    name="razon_social" 
                    id="razon_social" 
                    placeholder='Nombre y apellido o Razon Social' 
                    onChange={handleClienteChange}
                    value={cliente.razon_social}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="provincia">Provincia</label>
                <select name="provincia" id="provincia" onChange={handleClienteChange}>
                    {
                        cliente.provincia !== "" ? 
                            <option defaultValue={cliente.provincia} hidden>{obtenerProvincia(cliente.provincia)}</option>
                        :
                            <option defaultValue={""} hidden>Seleccione una opcion</option>
                    }
                    {provincias.map((provincia, index) =>
                        <option key={index} value={provincia.numero}>{provincia.nombre}</option>
                    )}
                </select>
            </div>
            <div className='form-manual'>
                <label htmlFor="email">Email</label>
                <input 
                    type="text" 
                    name="email" 
                    id="email" 
                    placeholder='Separar por comas los elementos'
                    onChange={handleClienteChange}
                    value={cliente.email}
                 />
            </div>
            <div className='form-manual'>
                <label htmlFor="condicion_pago">Condicion de Venta</label>
                <select name="condicion_pago" id="condicion_pago" onChange={handleClienteChange}>
                    {
                        cliente.condicion_pago !== "" ? 
                            <option defaultValue={cliente.condicion_pago} hidden>{obtenerCondicionVenta(cliente.condicion_pago)}</option>
                        :
                            <option defaultValue={""} hidden>Seleccione una opcion</option>
                    }
                    <option value="213">5 dias</option>
                    <option value="206">10 dias</option>
                    <option value="207">15 dias</option>
                    <option value="209">20 dias</option>
                    <option value="202">30 dias</option>
                    <option value="208">45 dias</option>
                    <option value="203">60 dias</option>
                    <option value="204">90 dias</option>
                    <option value="201">Contado</option>
                    <option value="205">Cuenta Corriente</option>
                    <option value="210">Transferencia Bancaria</option>
                    <option value="211">Tarjeta De Credito</option>
                    <option value="212">Tarjeta De Debito</option>
                    <option value="214">Otra</option>
                </select>
            </div>
            <div className='form-manual'>
                <label htmlFor="domicilio">Domicilio</label>
                <input 
                    type="text" 
                    name="domicilio" 
                    id="domicilio" 
                    onChange={handleClienteChange}
                    value={cliente.domicilio}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="condicion_pago_otra">Condicion de Pago</label>
                <input 
                    type="text" 
                    name="condicion_pago_otra" 
                    id="condicion_pago_otra" 
                    placeholder='Ej: Pago por ventanilla' 
                    onChange={handleClienteChange}
                    value={cliente.condicion_pago_otra}
                />
            </div>
            
        </article>
    )
}

export default Cliente