import React, { useState } from 'react'
import { parsearFecha } from '../../../utils/funciones'
import Modal from './Modal';


function SeleccionarSaldo({
    nombre,
    Gf,
    cuil,
    volverAtras,
    saldos,
    deuda
}) {

    console.log(saldos);

    const [modalAbierto, setModalAbierto] = useState(false);
    const [saldoEditando, setSaldoEditando] = useState(null);

    const abrirModal = (saldo) => {
        setSaldoEditando(saldo);
        setModalAbierto(true);
    };

    const cerrarModal = () => {
        setSaldoEditando(null);
        setModalAbierto(false);
    };

    return (
        <section className='SaldoParcial'>
            <button className='volver-atras' onClick={volverAtras}>Atras</button>
            <h2>Saldos Parciales: {cuil}<br />{nombre}</h2>
            <ul className='datos'>
                <li className='header-saldo-individual'>
                    <div className='datavalue'>

                    </div>
                    <h4>Periodo</h4>
                    <h4>Tipo Cobranza</h4>
                    <h4>Generar</h4>
                </li>
                {saldos.map((saldo, index) =>
                    <li className='saldo-individual' key={index + 1}>
                        <div className='datavalue'>
                            <h4>{parsearFecha(saldo.periodo)}</h4>
                        </div>
                        <div className='datavalue'>
                            <h4>{saldo.tipoDeCobranza}</h4>
                        </div>
                        <div className='datavalue'>
                            <h4>${saldo.valor}</h4>
                        </div>
                        <div className='datavalue'>
                            <button onClick={() => abrirModal(saldo)}>Modificar</button>
                        </div>
                    </li>
                )}
            </ul>

            {modalAbierto && (
                <Modal
                    saldoEditando={saldoEditando}
                    cerrarModal={cerrarModal}
                />
            )}

            <article className='calculadora'>

                <div></div>
                <div></div>
                <div></div>

                <div className='calculo'>
                    <h3>Deuda Total</h3>
                    <h3>${deuda}</h3>
                </div>
            </article>
        </section>
    )
}

export default SeleccionarSaldo