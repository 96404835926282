import React from 'react'
import { parsearFecha, pasarNumeroAMoneda } from '../../../utils/funciones'

function Pago({ saldo }) {

    if (saldo.pagos.total === 0) {
        return (
            <li className='cuenta-saldos pago impago'>
                <div className='cuenta-periodo'>
                    <h5>PAGO: {parsearFecha(saldo.periodo)}</h5>
                </div>
                <div className='cuenta-mediopago'>
                    <h5>Saldo Pagado</h5>
                </div>
                <div className='cuenta-mediopago'>
                    <h5>{saldo.pagos.msg}</h5>
                </div>
                <div className='cuenta-mediopago'>
                    <h5>Avise para revision</h5>
                </div>
                <div className='cuenta-mediopago'>
                    <h5>Monto: {pasarNumeroAMoneda(saldo.saldo)}</h5>
                </div>
            </li>
        )
    }

    return (
        <li className='cuenta-saldos pago'>
            <div className='cuenta-periodo'>
                <h5>PAGO: {parsearFecha(saldo.periodo)}</h5>
            </div>
            <div className='cuenta-mediopago'>
                <h5>{saldo.pagos.pago.medioDePago}</h5>
            </div>
            <div className='cuenta-plan-categoria'>
                <h5>{saldo.pagos.pago.idOperacion}</h5>
            </div>
            <div className='cuenta-valor-plan'>
                <h5>{saldo.pagos.pago.tipoPago}</h5>
            </div>
            <div className='cuenta-aporte'>
                <h5>{saldo.pagos.pago.fecha}</h5>
            </div>
            <div className='cuenta-saldo'>
                <h5>{saldo.pagos.pago.hora}</h5>
            </div>
            <div className='bloque-mes'>
                <h5>{pasarNumeroAMoneda(saldo.pagos.pago.valor)}</h5>
            </div>
        </li>
    )
}

export default Pago