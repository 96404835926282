import React from 'react'

function Ejemplos() {


  return (
    <main className='ejemplos'>
        <section className='ejemplos-planilla'>
            <h2>Manual de usuario para el uso del proyecto</h2>
            <article className='planilla-descargas'>
                <div>
                    <h3>Fiscalización</h3>
                    <a href={`https://fiscalizacion.createch.com.ar/documentacion/fiscalización.rar`} download="fiscalización.rar" className='descargas-button'>Descargar</a>
                </div>
                <div>
                    <h3>Listas</h3>
                    <a href={`https://fiscalizacion.createch.com.ar/documentacion/listas.rar`} download="listas.rar" className='descargas-button'>Descargar</a>
                </div>
                <div>
                    <h3>Padrones</h3>
                    <a href={`https://fiscalizacion.createch.com.ar/documentacion/padrones.rar`} download="padrones.rar" className='descargas-button'>Descargar</a>
                </div>
                <div>
                    <h3>Saldos</h3>
                    <a href={`https://fiscalizacion.createch.com.ar/documentacion/saldos.xlsx`} download="saldos.rar" className='descargas-button'>Descargar</a>
                </div>
            </article>
        </section>
        
    </main>
  )
}

export default Ejemplos