import React, { useState,useEffect } from 'react'
import axios from 'axios'
import Cotizando from './Componentes/Cotizando'
import loading from '../../../assets/Spin-1s-200px.gif'
import './Styles/cotizador.css'

function Cotizador() {

  const [cuil, setCuil] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [listas, setListas] = useState([])
  const [padrones, setPadrones] = useState([])

  console.log(padrones);

  useEffect(() => {
      setIsLoading(true)
      axios.get("https://fiscalizacion.createch.com.ar/listas")
      .then(response =>{
        setListas(response.data.data)
        setIsLoading(false)
      })
  }, []);

  const handleClick = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    let urlProduccion = `https://fiscalizacion.createch.com.ar/contratos/cotizar?cuil=${cuil}`
    axios.get(urlProduccion)
      .then(response => {
        console.log(response);
        setPadrones(response.data.data.padrones)
        setIsLoading(false)
      })
  }

  if (isLoading) {
    return (
      <main className='CuentaCorriente'>
        <h2>Cargando </h2>
        <img src={loading} alt="Cargando" />
      </main>
    )
  } else if (!isLoading && padrones.length > 0) {
    return (
      <main className='Cotizador'>
        <h2>Datos Contrato</h2>
        <Cotizando
          listas={listas}
          padrones={padrones}
        />
      </main>
    )
  } else {
    return (
      <main className='Cotizador'>
        <h1>Cotizador</h1>
        <section className='section-busqueda'>
          <h3>Ingrese un <span>CUIL</span> para continuar</h3>
          <form>
            <input type="text" name="cuil" id="cuil" onChange={({ target }) => setCuil(target.value)} placeholder='CUIL TITULAR'/>
            <button onClick={handleClick}>Buscar Cuil</button>
          </form>
        </section>
      </main>
    )
  }
}

export default Cotizador