import React from 'react'


/* Componentes */
import PlanDePago from './PlanDePago';
import PlanFlex from './PlanFlex';
import SaldoComun from './SaldoComun';
import Pago from './Pago';

/* 
    saldo.planDePago === 1 && saldo.tipoPlan === Flex ? "" : saldo.planDePago === 1 ? "" : SaldoComun
*/

function Acordeones({ saldo, pagado}) {
  return (
    <div>
        {
        saldo.planDePago === 1 && saldo.tipoSaldo === "Plan Flex" ?
            <PlanFlex saldo={saldo} pago={pagado}/>
        : saldo.planDePago === 1 && saldo.tipoSaldo === "Plan De Pago" ?
            <PlanDePago saldo={saldo} pago={pagado}/>
        :
            <SaldoComun saldo={saldo} pago={pagado} />
        }
        { (pagado && saldo.saldo > 0) && <Pago saldo={saldo}/> }
    </div>
  )
}

export default Acordeones