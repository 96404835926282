import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import FacturaManual from './FacturaManual/FacturaManual';
import Libro from './LibroIva/Libro';
import Facturas from './Facturas/Facturas';
import CuentaModificable from './CuentaModificable/CuentaModificable';
import Lotes from './Lotes/Lotes';

<script src="https://unpkg.com/react-tabs/dist/react-tabs.development.js" />

export default function Main() {

	const state = useSelector(state => state);
	const [rol, setRol] = useState(state.rol)

	useEffect(() => {
		setRol(state.rol)
	}, [state]);

	return (
		<main className='herramientas'>
			<section className='herramientas-section'>
				<h1>
					Facturacion
				</h1>
				<Tabs>
					<TabList>
						<Tab>Buscar Facturas</Tab>
						{(rol === "Administrador" || rol === "Facturacion") &&
							<Tab>Factura Manual</Tab>
						}
						{rol === "Administrador" &&
							<Tab>Descarga de Lotes</Tab>
						}
						{(rol === "Administrador" || rol === "Facturacion") &&
							<Tab>Libro Iva</Tab>
						}
						{rol === "Administrador" &&
							<Tab>Cuenta Modificable</Tab>
						}
					</TabList>

					<TabPanel>
						<Facturas />
					</TabPanel>
					{(rol === "Administrador" || rol === "Facturacion") &&
						<TabPanel>
							<FacturaManual />
						</TabPanel>}
					{rol === "Administrador" &&
						<TabPanel>
							<Lotes />
						</TabPanel>}
					{(rol === "Administrador" || rol === "Facturacion") &&
						<TabPanel>
							<Libro />
						</TabPanel>}
					{rol === "Administrador" &&
						<TabPanel>
							<CuentaModificable />
						</TabPanel>}
				</Tabs>
			</section>
		</main>
	)
}
