import React from 'react'
import '../Styles/comprobante.css'

function Comprobante({ comprobante, setComprobante }) {

    const handleComprobanteChange = event => {
        setComprobante({
            ...comprobante,
            [event.target.name]: event.target.value
        });
    };
    const fechaFactura = (fecha) => {
        const fechaOriginal = new Date(fecha);
        const dia = fechaOriginal.getDate() + 1;
        const mes = fechaOriginal.getMonth() + 1; // Sumar 1 al mes porque los meses en JavaScript empiezan en 0
        const anio = fechaOriginal.getFullYear();
        const fechaFormateada = `${dia.toString().padStart(2, "0")}/${mes
            .toString()
            .padStart(2, "0")}/${anio.toString()}`;
        setComprobante({
            ...comprobante,
            fecha: fechaFormateada
        });
    };
    const periodoDesde = (fecha) => {
        const fechaOriginal = new Date(fecha);
        const dia = fechaOriginal.getDate() + 1;
        const mes = fechaOriginal.getMonth() + 1; // Sumar 1 al mes porque los meses en JavaScript empiezan en 0
        const anio = fechaOriginal.getFullYear();
        const fechaFormateada = `${dia.toString().padStart(2, "0")}/${mes
            .toString()
            .padStart(2, "0")}/${anio.toString()}`;
        setComprobante({
            ...comprobante,
            periodo_facturado_desde: fechaFormateada
        });
    };
    const periodoHasta = (fecha) => {
        const fechaOriginal = new Date(fecha);
        const dia = fechaOriginal.getDate() + 1;
        const mes = fechaOriginal.getMonth() + 1; // Sumar 1 al mes porque los meses en JavaScript empiezan en 0
        const anio = fechaOriginal.getFullYear();
        const fechaFormateada = `${dia.toString().padStart(2, "0")}/${mes
            .toString()
            .padStart(2, "0")}/${anio.toString()}`;
        setComprobante({
            ...comprobante,
            periodo_facturado_hasta: fechaFormateada
        });
    };

    return (
        <article className='comprobante-factura-manual'>
            <div className='form-manual'>
                <label htmlFor="fecha">Fecha de emision</label>
                <input
                    type="date"
                    name="fecha"
                    id="fecha"
                    onChange={({ target }) => fechaFactura(target.value)}
                    /* value={comprobante.fecha} */
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="periodo_facturado_desde">Periodo facturado desde</label>
                <input
                    type="date"
                    name="periodo_facturado_desde"
                    id="periodo_facturado_desde"
                    onChange={({ target }) => periodoDesde(target.value)}
                    value={comprobante.periodo_facturado_desde}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="periodo_facturado_hasta">Periodo facturado_hasta</label>
                <input
                    type="date"
                    name="periodo_facturado_hasta"
                    id="periodo_facturado_hasta"
                    onChange={({ target }) => periodoHasta(target.value)}
                    value={comprobante.periodo_facturado_hasta}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="comentario">Comentario</label>
                <input
                    type="text"
                    name="comentario"
                    id="comentario"
                    onChange={handleComprobanteChange}
                    placeholder="Comentario de factura"
                    value={comprobante.comentario}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="punto_venta">Punto de venta</label>
                <input
                    type="text"
                    name="punto_venta"
                    id="punto_venta"
                    onChange={handleComprobanteChange}
                    placeholder="Por defecto 4"
                    value={comprobante.punto_venta}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="leyenda_gral">Leyenda general</label>
                <input
                    type="text"
                    name="leyenda_gral"
                    id="leyenda_gral"
                    onChange={handleComprobanteChange}
                    placeholder="Entre detalle y comprobante"
                    value={comprobante.leyenda_gral}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="percepciones_iibb">Percepciones iibb</label>
                <input
                    type="text"
                    name="percepciones_iibb"
                    id="percepciones_iibb"
                    onChange={handleComprobanteChange}
                    placeholder="Percepciones"
                    value={comprobante.percepciones_iibb}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="percepciones_iibb_base">Percepciones iibb base</label>
                <input
                    type="text"
                    name="percepciones_iibb_base"
                    id="percepciones_iibb_base"
                    onChange={handleComprobanteChange}
                    placeholder="Percepciones"
                    value={comprobante.percepciones_iibb_base}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="percepciones_iibb_alicuota">Percepciones iibb alicuota</label>
                <input
                    type="text"
                    name="percepciones_iibb_alicuota"
                    id="percepciones_iibb_alicuota"
                    onChange={handleComprobanteChange}
                    placeholder="Percepciones"
                    value={comprobante.percepciones_iibb_alicuota}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="percepciones_iva">Percepciones iva</label>
                <input
                    type="text"
                    name="percepciones_iva"
                    id="percepciones_iva"
                    onChange={handleComprobanteChange}
                    placeholder="Percepciones"
                    value={comprobante.percepciones_iva}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="percepciones_iva_base">Percepciones iva base</label>
                <input
                    type="text"
                    name="percepciones_iva_base"
                    id="percepciones_iva_base"
                    onChange={handleComprobanteChange}
                    placeholder="Percepciones"
                    value={comprobante.percepciones_iva_base}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="percepciones_iva_alicuota">Percepciones iva alicuota</label>
                <input
                    type="text"
                    name="percepciones_iva_alicuota"
                    id="percepciones_iva_alicuota"
                    onChange={handleComprobanteChange}
                    placeholder="Percepciones"
                    value={comprobante.percepciones_iva_alicuota}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="impuestos_internos">Impuestos internos</label>
                <input
                    type="text"
                    name="impuestos_internos"
                    id="impuestos_internos"
                    onChange={handleComprobanteChange}
                    placeholder="impuestos totales internos"
                    value={comprobante.impuestos_internos}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="impuestos_internos_base">Impuestos internos base</label>
                <input
                    type="text"
                    name="impuestos_internos_base"
                    id="impuestos_internos_base"
                    onChange={handleComprobanteChange}
                    placeholder="Impuestos Base"
                    value={comprobante.impuestos_internos_base}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="impuestos_internos_alicuota">Impuestos internos alicuota</label>
                <input
                    type="text"
                    name="impuestos_internos_alicuota"
                    id="impuestos_internos_alicuota"
                    onChange={handleComprobanteChange}
                    placeholder="Impuestos alicuota"
                    value={comprobante.impuestos_internos_alicuota}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="exentos">Exentos</label>
                <input
                    type="text"
                    name="exentos"
                    id="exentos"
                    onChange={handleComprobanteChange}
                    placeholder="Valores Excentos de IVA"
                    value={comprobante.exentos}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="total">Total</label>
                <input
                    type="text"
                    name="total"
                    id="total"
                    onChange={handleComprobanteChange}
                    placeholder="Total facturar"
                    value={comprobante.total}
                />
            </div>
            <div className='form-manual'>
                <label htmlFor="external_reference">External reference</label>
                <input
                    type="text"
                    name="external_reference"
                    id="external_reference"
                    onChange={handleComprobanteChange}
                    placeholder="Referencia para busqueda en el sistema"
                    value={comprobante.external_reference}
                />
            </div>

        </article>
    )
}

export default Comprobante