import React, { useState } from 'react'
import { fechaMes, parsearFecha, pasarNumeroAMoneda } from '../../../../utils/funciones'
import Periodo from '../../../../components/periodo/Periodo'
import consulta from '../../../../utils/consulta'
import Swal from 'sweetalert2'

function AportesManuales({ setFuncion }) {

    const [periodo, setPeriodo] = useState(fechaMes())
    const [valores, setValores] = useState({
        valor: 0,
        cuil: 0,
        obraSocial: 0
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setValores({ ...valores, [name]: value })
    }

    const comprobarErrores = () => {

        let errores = {
            valor: false,
            valorMsg: "Deber ingresar un monto mayor a 0",
            cuil: false,
            cuilMsg: "Debes ingresar un cuil",
            obraSocial: false,
            obraSocialMsg: "Debes ingresar una opcion"
        }
        let error = false

        if (valores.valor === 0) {
            errores.valor = true
            error = true
        }
        if (valores.cuil === 0) {
            errores.cuil = true
            error = true
        }
        if (valores.obraSocial === 0) {
            errores.obraSocial = true
            error = true
        }

        if (!error) {
            return {
                problema: false,
                problemaMsg: ""
            }
        }

        let mensaje = `${errores.valor ? errores.valorMsg : ""}\n${errores.cuil ? errores.cuilMsg : ""}\n${errores.obraSocial ? errores.obraSocialMsg : ""}`
        return {
            problema: true,
            problemaMsg: mensaje
        }
    }

    const enviarAporte = async (e) => {
        try {
            e.preventDefault()
            let data = {
                valor: valores.valor,
                cuil: valores.cuil,
                obraSocial: valores.obraSocial,
                periodo: periodo
            }
            let { problema, problemaMsg } = comprobarErrores()
            if (problema) {
                throw new Error(problemaMsg)
            }
            const urlRuta = `/modificaciones/aporte/manual`

            const result = await Swal.fire({
                title: "¿Estas seguro que deseas crear este aporte?",
                text: `Titular: ${valores.cuil}, Periodo: ${parsearFecha(periodo)}, Monto: ${pasarNumeroAMoneda(valores.valor)}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Crear",
                cancelButtonText: "Cancelar"
            })

            if (result.isConfirmed) {
                const response = await consulta.post(urlRuta, data)
                console.log("respuestas -->", response);
                Swal.fire({
                    position: "top-end",
                    title: response.meta.msg,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1000,
                });
            }
        } catch (error) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: error.message,
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }

    return (
        <section className='Creacion-Aportes'>
            <h1>Aportes Manuales</h1>
            <div className="atras">
                <button onClick={() => setFuncion("")}>Atras</button>
            </div>
            <p>Recuerde que toda creacion de los aportes manuales tiene prioridad sobre los aportes netos en la fecha seleccionada</p>
            <form onSubmit={enviarAporte}>
                <div className="formData">
                    <label htmlFor="valor">Valor</label>
                    <input type="text" id="valor" name="valor" onChange={handleChange} />
                </div>
                <div className="formData">
                    <label htmlFor="cuil">Cuil</label>
                    <input type="text" id="cuil" name="cuil" onChange={handleChange} />
                </div>
                <div className="formData">
                    <label htmlFor="obraSocial">Obra Social</label>
                    <select className='obraSocial' name="obraSocial" id="obraSocial" onChange={handleChange}>
                        <option defaultValue="" hidden>Seleccione una opcion</option>
                        <option value="OSCEARA">OSCEARA</option>
                        <option value="OSMEDICA">OSMEDICA</option>
                        <option value="OSPOCE">OSPOCE</option>
                        <option value="OSCICA">OSCICA</option>
                        <option value="MONOTRIBUTO">MONOTRIBUTO</option>
                        <option value="OTRO">OTRO</option>
                    </select>
                </div>
                <Periodo periodo={periodo} setPeriodo={setPeriodo} />
                <button type='submit' className='boton-aporte'>Crear Aporte</button>
            </form>

        </section>
    )
}

export default AportesManuales