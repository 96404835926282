import React, { useRef } from "react";
import SelectList from "./SelectList";

import '../Styles/acordeon.css'

const AcordeonItem = ({ valor, active, onToggle, referencia, titulo }) => {

    const contentEl = useRef();

    return (
        <li className={`accordion_item ${active ? "active" : ""}`}>
            <button className="button" onClick={onToggle}>
                {titulo}
                <span className="control">{active ? "—" : "+"} </span>
            </button>
            <SelectList
                contentEl={contentEl}
                active={active}
                planes={valor}
                titulo={titulo}
            />
        </li>
    );
};

export default AcordeonItem;