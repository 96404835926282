import React, { useState } from 'react'
import consulta from '../../../../utils/consulta'
import { fechaMes, parsearFecha, escribirFuncion } from '../../../../utils/funciones'
import Loading from '../../../../components/partials/Loading'
import TableGenerator from '../../../../components/TableGenerator/TableGenerator'
import ExcelButton from '../../../../components/partials/Excel/ExcelButton'

function Ejecucion({ setFunc }) {

    const [error, seterror] = useState(false)
    const [loading, setLoading] = useState(false)
    const [mostrarDataIndividual, setmostrarDataIndividual] = useState(false)
    const [mostrarDataPadron, setmostrarDataPadron] = useState(false)
    const [periodo, setperiodo] = useState(fechaMes())
    const [cuil, setcuil] = useState("")
    const [funcionEscogida, setFuncionEscogida] = useState("")
    const [NombreUsuario, setNombreUsuario] = useState("")
    const [tipoError, setTipoError] = useState("")
    const [SaldoPadron, setSaldoPadron] = useState([])
    const [columnasArr, setcolumnasArr] = useState([])

    const cambiarMes = (e) => {
        let mes = e.target.value;
        let nuevoPeriodo = String(periodo)
        let year = nuevoPeriodo.substring(0, 4)
        setperiodo(`${year}${mes}`)
    }
    const cambiaryear = (e) => {
        console.log(e.target.value);
        let year = e.target.value
        let nuevoPeriodo = String(periodo)
        let mes = nuevoPeriodo.substring(4, 6)
        setperiodo(`${year}${mes}`)
    }
    const volverAtras = (e) => {
        e.preventDefault()
        seterror(false)
        setTipoError("")
        setmostrarDataIndividual(false)
        setmostrarDataPadron(false)
        setperiodo(fechaMes())
        setcuil("")
        setFuncionEscogida("")
        setNombreUsuario("")
        setFunc("")
    }
    const settearColumnas = (funcion) => {
        let columnaSaldoPadron
        if (funcion === 'SIC') {
            columnaSaldoPadron = [
                { field: 'id', headerName: 'ID', width: 70 },
                { field: 'titular', headerName: 'CUIL', width: 110 },
                { field: 'gf', headerName: 'GF', width: 90 },
                { field: 'valor', headerName: 'Valor', width: 120 },
                { field: 'periodo', headerName: 'periodo', width: 100 },
                { field: 'tipoDeCobranza', headerName: 'Tipo De Cobranza', width: 140 },
                { field: 'pagados', headerName: 'Pagado', width: 140 },
                { field: 'contratoId', headerName: 'Contrato Id', width: 100 }
            ];
        } else {
            columnaSaldoPadron = [
                { field: 'contratoId', headerName: 'contratoId', width: 90 },
                { field: 'titular', headerName: 'Titular', width: 110 },
                { field: 'valor', headerName: 'Saldo', width: 140 },
                { field: 'periodo', headerName: 'Periodo', width: 90 },
                { field: 'tipoDeCobranza', headerName: 'tipoDeCobranza', width: 170 },
                { field: 'linkMp', headerName: 'linkMp', width: 140 },
                { field: 'revision', headerName: 'revision', width: 140 },
                { field: 'tipoSaldo', headerName: 'tipoSaldo', width: 140 },
            ];
        }
        setcolumnasArr(columnaSaldoPadron)
    }

    const SaldoIndividual = async (e) => {
        try {
            e.preventDefault()
            if (cuil.length === 11) {
                seterror(false)
                setLoading(true)
                let urlSaldo = `/calculos/saldo/individual?titular=${cuil}&periodo=${periodo}`
                let response = await consulta.post(urlSaldo)
                setNombreUsuario(response.meta.nombre);
                setmostrarDataIndividual(true)
                setLoading(false)
                return
            } else {
                seterror(true)
            }
        } catch (error) {
            seterror(true)
            console.log(error);
            setTipoError(error.message)
            console.error('Error al hacer la consulta ', error.message)
            setLoading(false)
        }
    }
    const SaldoIndividualParcial = async (e) => {
        try {
            e.preventDefault()
            if (cuil.length === 11) {
                seterror(false)
                setLoading(true)
                let urlSaldo = `/calculos/saldo/parcial?titular=${cuil}&periodo=${periodo}`
                let response = await consulta.post(urlSaldo)
                setNombreUsuario(response.meta.nombre);
                setmostrarDataIndividual(true)
                setLoading(false)
                return
            } else {
                seterror(true)
            }
        } catch (error) {
            seterror(true)
            console.log(error);
            setTipoError(error.message)
            console.error('Error al hacer la consulta ', error.message)
            setLoading(false)
        }
    }
    const SaldoContrato = async () => {
        try {
            setLoading(true)
            settearColumnas(funcionEscogida)
            let urlSaldoPadron = `/calculos/saldo/padron?periodo=${periodo}`
            let response = await consulta.post(urlSaldoPadron)
            setSaldoPadron(response.data)
            setmostrarDataPadron(true)
            setLoading(false)
            return
        } catch (error) {
            seterror(true)
            setTipoError(error.meta.msg)
            console.error('Error al hacer la consulta ', error.meta.msg)
            setLoading(false)
        }
    }

    if (loading) {
        return (
            <Loading texto={"Cargando Saldos ..."} />
        )
    }

    if (mostrarDataIndividual) {
        return (
            <div className='Funciones'>
                <div className='header-funciones'>
                    <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
                </div>
                <h2 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h2>
                <h3 className='titulo-seccion'>Usuario: {NombreUsuario}</h3>
                <div>
                    <h3>¡Saldo Cargado!</h3>
                </div>
                <h4 className='subtitulo-seccion'>Ejecucion Finalizada cuil:{cuil} </h4>
                <button className='submit-button'>Pasar datos a DB</button>
            </div>
        )
    }

    if (mostrarDataPadron) {
        return (
            <div className='Funciones Tabla-Padron'>
                <div className='header-funciones'>
                    <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
                </div>
                <h2 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h2>
                <h3 className='titulo-seccion'>Usuario: {NombreUsuario}</h3>
                <div>
                    <div>
                        {SaldoPadron.length > 0 &&
                            <TableGenerator columns={columnasArr} data={SaldoPadron} />
                        }
                        <div className='buttons'>
                            <ExcelButton data={SaldoPadron} nombreHoja={funcionEscogida} nombreArchivo={`ValoresPlan`} />
                            <button className='submit-button'>Pasar datos a DB</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='Funciones'>
            <div className='header-funciones'>
                <button className='back-button' onClick={(e) => volverAtras(e)}>Atras</button>
            </div>
            <h2 className='titulo-seccion'>Ejecuciones</h2>
            {funcionEscogida === "SIC" ?
                <article className='formulario'>
                    <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                    <form className='formulario-content' onSubmit={SaldoIndividual}>
                        <div className='formData'>
                            <label htmlFor="cuil">Cuil Aflilado</label>
                            <input type="text" name="cuil" id="cuil" className={error ? "invalid" : "valid"} onChange={(e) => setcuil(e.target.value)} />
                        </div>
                        <div className='formData'>
                            <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                            <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                <option defaultValue="" hidden>Puede modificar el año</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>
                        <div className='formData'>
                            <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                            <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                <option defaultValue="" hidden>Puede modificar el mes</option>
                                <option value="01">Enero</option>
                                <option value="02">Febrero</option>
                                <option value="03">Marzo</option>
                                <option value="04">Abril</option>
                                <option value="05">Mayo</option>
                                <option value="06">Junio</option>
                                <option value="07">Julio</option>
                                <option value="08">Agosto</option>
                                <option value="09">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </div>
                        <div className='periodo-seleccionado'>
                            <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                        </div>
                        {error &&
                            <div className='error-del-sistema'>
                                <small>{tipoError}</small>
                            </div>
                        }
                        <div className='buttons'>
                            <button type="submit" className='submit-button'>Ejecutar</button>
                        </div>
                    </form>
                </article>
            :
            funcionEscogida === "SPC" ?
                <article className='formulario'>
                    <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                    <form className='formulario-content' onSubmit={SaldoContrato}>
                        <div className='formData'>
                            <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                            <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                <option defaultValue="" hidden>Puede modificar el año</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>
                        <div className='formData'>
                            <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                            <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                <option defaultValue="" hidden>Puede modificar el mes</option>
                                <option value="01">Enero</option>
                                <option value="02">Febrero</option>
                                <option value="03">Marzo</option>
                                <option value="04">Abril</option>
                                <option value="05">Mayo</option>
                                <option value="06">Junio</option>
                                <option value="07">Julio</option>
                                <option value="08">Agosto</option>
                                <option value="09">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </div>
                        <div className='periodo-seleccionado'>
                            <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                        </div>
                        {error &&
                            <div className='error-del-sistema'>
                                <small>{tipoError}</small>
                            </div>
                        }
                        <div className='buttons'>
                            <button className='cancel-button'>Cancelar</button>
                            <button type="submit" className='submit-button'>Ejecutar</button>
                        </div>
                    </form>
                </article>
            :
            funcionEscogida === "SIP" ?
                <article className='formulario'>
                    <h3 className='titulo-seccion'>{escribirFuncion(funcionEscogida)}</h3>
                    <form className='formulario-content' onSubmit={SaldoIndividualParcial}>
                        <div className='formData'>
                            <label htmlFor="cuil">Cuil Aflilado</label>
                            <input type="text" name="cuil" id="cuil" className={error ? "invalid" : "valid"} onChange={(e) => setcuil(e.target.value)} />
                        </div>
                        <div className='formData'>
                            <label htmlFor="year">Periodo años <span>{"(No obligatorio)"}</span></label>
                            <select name="year" id="year" className='select-input' onChange={cambiaryear}>
                                <option defaultValue="" hidden>Puede modificar el año</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                        </div>
                        <div className='formData'>
                            <label htmlFor="mes">Periodo Mes <span>{"(No obligatorio)"}</span></label>
                            <select name="mes" id="mes" className='select-input' onChange={cambiarMes}>
                                <option defaultValue="" hidden>Puede modificar el mes</option>
                                <option value="01">Enero</option>
                                <option value="02">Febrero</option>
                                <option value="03">Marzo</option>
                                <option value="04">Abril</option>
                                <option value="05">Mayo</option>
                                <option value="06">Junio</option>
                                <option value="07">Julio</option>
                                <option value="08">Agosto</option>
                                <option value="09">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </div>
                        <div className='periodo-seleccionado'>
                            <small>Periodo seleccionado: {parsearFecha(periodo)}</small>
                        </div>
                        {error &&
                            <div className='error-del-sistema'>
                                <small>{tipoError}</small>
                            </div>
                        }
                        <div className='buttons'>
                            <button type="submit" className='submit-button'>Ejecutar</button>
                        </div>
                    </form>
                </article>
            :
            <article className='botones-Funciones'>
                <button className='simulacion-button' onClick={(e) => setFuncionEscogida("SIC")}>Saldo Individual</button>
                <button className='simulacion-button' onClick={(e) => setFuncionEscogida("SPC")}>Saldos del Padrón Completo</button>
                <button className='simulacion-button' onClick={(e) => setFuncionEscogida("SIP")}>Saldo Individual Parcial</button>
            </article>
            }
        </div>
    )
}

export default Ejecucion