import React, { useState } from 'react'

import { parsearFecha, pasarNumeroAMoneda, unsecuredCopyToClipboard } from '../../../utils/funciones'
function SaldoComun({ saldo, pago }) {

    const [mostrarSaldos, setMostrarSaldos] = useState(false);
    const [MostrarValorPlan, setMostrarValorPlan] = useState(false);
    const [seccionAbierta, setSeccionAbierta] = useState(false);
    const [maxHeight, setMaxHeight] = useState(0);

    console.log("Saldo Comun -->", saldo);

    const toggleSeccion = () => {
        setSeccionAbierta(!seccionAbierta);
        setMaxHeight(maxHeight === 0 ? calcularAlturaMaxima() : 0);
    };
    const calcularAlturaMaxima = () => {
        // Aquí puedes ajustar la altura según tus necesidades
        // Por ejemplo, si quieres que haya 50px por cada elemento, puedes hacer algo como:
        const alturaPorElemento = 100;
        return alturaPorElemento * saldo.padrones.length;
    };

    const handleButtonClick = (event) => {
        // Detener la propagación del evento para evitar que llegue al acordeon
        event.stopPropagation();

        // Lógica específica del botón
        console.log('Clic en el botón');
        unsecuredCopyToClipboard(saldo.linkDePago);
    };


    return (
        <li className={`cuenta-saldos saldo-comun ${pago ? "pagado" : ""}`}>
            <div className='cuenta-corriente-contenedor' onClick={() => toggleSeccion()}>
                <div className='cuenta-periodo'>
                    <h5>Saldo: {parsearFecha(saldo.periodo)}</h5>
                </div>
                <div className='cuenta-mediopago'>
                    <h5>{saldo.medioPago}</h5>
                </div>
                <div className='cuenta-plan-categoria'>
                    <h5 className='gf'>GF: {saldo.gf}</h5>
                    <h5 className='barra'></h5>
                    <h5 className='plan'>{saldo.padrones[0].plan}</h5>
                </div>
                <div className='cuenta-valor-plan' onMouseEnter={() => setMostrarValorPlan(true)} onMouseLeave={() => setMostrarValorPlan(false)}>
                    <h5 className='titulo'>Valor Plan</h5>
                    {(MostrarValorPlan && (saldo.sumaValorPlanIndividual !== saldo.valorPlan)) && (
                        <div className='valor-plan-oculto'>
                            <h5 className='valor-plan-inicial'>{pasarNumeroAMoneda(saldo.sumaValorPlanIndividual)}</h5>
                            <h5 className='valor-plan-adicional'>+ {pasarNumeroAMoneda(saldo.adicionalMontoVP)}</h5>
                            <h5 className='valor-plan-descuento'>- {pasarNumeroAMoneda(saldo.descuentoComercialMonto + saldo.descuentoMontoVP)}</h5>
                            <h5 className='valor-plan-barra'></h5>
                        </div>
                    )}
                    <h5 className='valor-plan-inicial'>{pasarNumeroAMoneda(saldo.valorPlan)}</h5>
                </div>
                <div className='cuenta-aporte'>
                    <h5>Aporte</h5>
                    <h5>{saldo.valorPlan >= saldo.aportes ? pasarNumeroAMoneda(saldo.aportes) : pasarNumeroAMoneda(saldo.valorPlan)}</h5>
                </div>
                <div className='cuenta-saldo' onMouseEnter={() => setMostrarSaldos(true)} onMouseLeave={() => setMostrarSaldos(false)}>
                    <h5 className='titulo'>Saldo</h5>
                    {(mostrarSaldos && (saldo.saldoBase !== saldo.saldo)) && (
                        <div className='saldos-ocultos'>
                            <h5 className='saldo-inicial'>{pasarNumeroAMoneda(saldo.saldoBase)}</h5>
                            <h5 className='saldo-adicionales'>+ {pasarNumeroAMoneda(saldo.adicionalesMonto)}</h5>
                            <h5 className='saldo-descuentos'>- {pasarNumeroAMoneda(saldo.descuentosMonto)}</h5>
                            <h5 className='saldo-barra'></h5>
                        </div>)}
                    <h5 className='saldo-final'>{pasarNumeroAMoneda(saldo.saldo)}</h5>
                </div>
                {(((saldo.pagos.total === 0) && (pago)) || (!pago) && (saldo.saldo !== 0)) &&
                    <div className='bloque-mes'>
                        <h6 className='linkDePago'>
                            <div
                                className='link'
                                onClick={() => unsecuredCopyToClipboard(saldo.linkDePago)}
                            >
                                Link de pago
                            </div>
                        </h6>
                    </div>}
            </div>
            <div className='cuenta-corriente-acordeon' >
                <div style={{
                    maxHeight: `${maxHeight}px`,
                    overflow: 'hidden', // Para ocultar el contenido que excede maxHeight
                    transition: 'max-height 0.3s ease-in-out',
                }} className={`contenido ${seccionAbierta ? 'visible' : ''}`}>
                    <table role="table" className='table-block'>
                        <thead>
                            <tr role="row">
                                <th role="columnheader">Cuil</th>
                                <th role="columnheader">Nombre</th>
                                <th role="columnheader">Estado</th>
                                <th role="columnheader">Lista</th>
                                <th role="columnheader">Plan</th>
                                <th role="columnheader">Categoría</th>
                                <th role="columnheader">Obra Social</th>
                                <th role="columnheader">Valor Plan</th>
                                <th role="columnheader">Aportes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {saldo.padrones.map((padron, index) =>
                                <tr role="row" key={index}>
                                    <td>{padron.cuil}</td>
                                    <td>{padron.nombre}</td>
                                    <td>{padron.tipoEstado}</td>
                                    <td>{padron.lista}</td>
                                    <td>{padron.plan}</td>
                                    <td>{padron.categoria}</td>
                                    <td>{padron.obraSocial}</td>
                                    <td>{pasarNumeroAMoneda(padron.valorplan)}</td>
                                    <td>{pasarNumeroAMoneda(padron.aporte)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </li>
    )
}

export default SaldoComun