import React, { useState } from 'react'
import laFamosaX from '../../../assets/xmark-solid.svg'
import Swal from 'sweetalert2';
import consulta from '../../../utils/consulta';

function Modal({ saldoEditando, cerrarModal }) {

    const [monto, setMonto] = useState(saldoEditando.valor)
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            const urlParcial = `/modificaciones/saldos/parcial`
            let obj = {
                monto,
                saldo:saldoEditando
            }

            await Swal.fire({
                title: `Esta seguro que desea generar saldos parciales por un valor total de ${monto} y otro de ${saldoEditando.valor - monto}`,
                showCancelButton: true,
                confirmButtonText: 'Generar',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let response = await consulta.post(urlParcial, obj)
                    if (response.status === 200) {
                        console.log(response);
                        Swal.fire(`¡Link Generado! \n ${response.meta.linkFinal}`, '', 'success')
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: "Error al generar " + response.status,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    }
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className='modal-fondo'>
            <div className='modal-contenido'>
                <button className='cerrar-modal' onClick={cerrarModal}>
                    <img src={laFamosaX} alt="Cerrar Modal" />
                </button>
                <h2>Ingrese un monto menor a {saldoEditando.valor} para tomar en cuenta </h2>
                {saldoEditando && (
                    <form onSubmit={handleSubmit}>
                        {/* Aquí debes mostrar el formulario de edición con los campos necesarios */}
                        <div className='formData'>
                            <label>Valor:</label>
                            <input type='number' value={monto} onChange={({ target }) => {
                                if (parseFloat(target.value) <= saldoEditando.valor) {
                                    setMonto(target.value);
                                    setError(null); // Limpiar el mensaje de error al cambiar el valor
                                }
                            }} />
                        </div>
                        {error && <p className="error-message">{error}</p>}
                        <button type='submit'>Crear</button>
                    </form>
                )}
            </div>
        </div>
    )
}

export default Modal