import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Logo from '../../../assets/logocompleto.png'


const HeaderSinPermisos = ({User}) => {



    /*  const [loading,setLoading] = useState(false)
 
     useEffect(() => {
         let shouldComponentUpdate = () => {
             return window.matchMedia('(min-width: 768px)').matches
         }
         console.log(shouldComponentUpdate());
         setLoading(window.matchMedia('(min-width: 768px)').matches)
         console.log('Cambio');
     }, [loading]); */

    let handleClick = (e) => {
        let input = document.querySelector('#menu')
        let menu = document.querySelector('.m-menu__overlay')
        if (input.checked) {
            menu.click()
        }
    }

    return (
        <header className='headerSinPermisos'>
            <nav className='desktop'>
                <div id="blurry-filter"></div>
                <div className='image-container'>
                    <NavLink to="/" >
                        <img src={Logo} alt="logo" />
                    </NavLink>
                </div>
                <div className="l-menu">
                    <ul className='menu-xl'>
                        <li>
                            <NavLink to="/" onClick={handleClick} className="session"> Iniciar Sesion <i className="fa-solid fa-user-large"></i></NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
            <nav className='mobile'>
                <div className='image-container'>
                    <NavLink to="/" >
                        <img src={Logo} alt="logo" />
                    </NavLink>
                </div>
                <input type="checkbox" id="menu" name="menu" className="m-menu__checkbox" />
                <label className="m-menu__toggle" htmlFor="menu">
                    {/* <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" strokeLinecap="butt" strokeLinejoin="arcs"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg> */}
                    <img src="https://cdn-icons-png.flaticon.com/512/56/56763.png" alt="" />
                </label>
                <label className="m-menu__overlay" htmlFor="menu"></label>

                <div className="m-menu">
                    <div className="m-menu__header">
                        <label className="m-menu__toggle" htmlFor="menu">
                            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="butt" strokeLinejoin="arcs">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </label>
                        <span>Andes Salud</span>
                    </div>
                    <ul>

                        {/* <li>
                                    <label className="a-label__chevron" htmlFor="item-2">Herramientas</label>
                                    <input type="checkbox" id="item-2" name="item-2" className="m-menu__checkbox" />
                                    <div className="m-menu">
                                        <div className="m-menu__header">
                                            <label className="m-menu__toggle" htmlFor="item-2">
                                                <svg width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" strokeLinecap="butt" strokeLinejoin="arcs">
                                                    <path d="M19 12H6M12 5l-7 7 7 7" />
                                                </svg>
                                            </label>
                                            <span><NavLink to="/herramientas">Herramientas</NavLink></span>
                                        </div>
                                        <ul>
                                            <li><label>Crear nueva lista de precios</label></li>
                                            <li><label>Crear un nuevo contrato</label></li>
                                            <li><label>Crear una nueva Bonificacion</label></li>
                                        </ul>
                                    </div>
                                </li> */}
                        <li>
                            <NavLink to="/" onClick={handleClick}> Saldos <i className="fa-regular fa-credit-card"></i></NavLink>
                        </li>
                        <li>
                            <NavLink to="/" onClick={handleClick}> Aportes <i className="fa-regular fa-handshake"></i></NavLink>
                        </li>
                        <li>
                            <NavLink to="/" onClick={handleClick}> Valores Plan <i className="fa-solid fa-users"></i></NavLink>
                        </li>
                        <li>
                            <NavLink to="/" onClick={handleClick}> Pagos <i className="fa-solid fa-money-bill"></i></NavLink>
                        </li>
                        <li>
                            <NavLink to="/" onClick={handleClick}> Cuenta Corriente <i className="fa-solid fa-clipboard-check"></i></NavLink>
                        </li>
                        <li>
                            <NavLink to="/" onClick={handleClick}>   Herramientas <i className="fa-solid fa-wrench"></i></NavLink>
                        </li>
                        <li>
                            <NavLink to="/" onClick={handleClick} className="session"> Iniciar Sesion <i className="fa-solid fa-user-large"></i></NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )

}

export default HeaderSinPermisos